import moment from 'moment';

import { Department } from 'types/Common/Companies';
import { ChangesLogFieldsItem } from 'types/Common/History';

import { municipalitiesMap, JobStatus, JobType } from 'Constants/job';
import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { ImageWithAuthorHelper } from 'Containers/Components/ImageViewer/helpers';
import UserSmallComponent from 'Containers/Components/UserSmallComponent/UserSmallComponent';
import { ZONES } from 'Containers/Scheduling/constants/zones';
import { FORMATS } from 'Utils/Date';
import { SignatureIcon } from 'Utils/Icon';
import { getWorketStatusString } from 'Utils/worker';

import styles from './components/FieldChangesLine/FieldChangesLine.module.scss';

enum FieldNames {
  password = 'password',
  section = 'section',
  feeder = 'feeder',
  receipt_number = 'receipt_number',
  receipt = 'receipt',
  po = 'po',
  requisition = 'requisition',
  companies = 'companies',
  wr = 'wr',
  comment = 'comment',
  avatar = 'avatar',
  avatar_url = 'avatar_url',
  email = 'email',
  phoneNumber = 'phoneNumber',
  beginTime = 'beginTime',
  endTime = 'endTime',
  firstName = 'firstName',
  lastName = 'lastName',
  isCesSupervisor = 'isCesSupervisor',
  requestTime = 'requestTime',
  finishDate = 'finishDate',
  startDate = 'startDate',
  municipality = 'municipality',
  status = 'status',
  jobType = 'jobType',
  jobTypes = 'jobTypes',
  zipcode = 'zipcode',
  company = 'company',
  department = 'department',
  workers = 'workers',
  locations = 'locations',
  workerStatus = 'workerStatus',
  requestor = 'requestor',
  supervisor = 'supervisor',
  timesheetPdfs = 'timesheetPdfs',
  images = 'images',
  photo = 'photo',
  rejected_photos = 'rejected_photos',
  cba_signature = 'cba_signature',
  field_supervisor_signature = 'field_supervisor_signature',
  department_supervisor_signature = 'department_supervisor_signature',
  ce_supervisor_signature = 'ce_supervisor_signature',
  sign = 'sign',
  workerPaid = 'workerPaid',
  invoiced = 'invoiced',
  isVerified = 'isVerified',
  is_verified = 'is_verified',
  verified = 'verified',
  worker_paid = 'worker_paid',
  paid = 'paid',
  isStandby = 'isStandby',
  pending = 'pending',
  pendingReason = 'pendingReason',
  alertAt = 'alertAt',
  alertMessage = 'alertMessage',
  TwoFactorAuth = 'TwoFactorAuth',
  signatureName = 'signatureName',
  employeeNumber = 'employeeNumber',
  override_min_flagging_hours = 'override_min_flagging_hours',
  ce_verified = 'ce_verified',
  no_crew_email_at = 'no_crew_email_at',
  emergency = 'emergency',
  started_at = 'started_at',
  finished_at = 'finished_at',
  scheduled_start_at = 'scheduled_start_at',
  scheduled_finish_at = 'scheduled_finish_at',
  checked = 'checked',
  picture_url = 'picture_url',
  picture_timestamp = 'picture_timestamp',
  stay_zone_id = 'stay_zone_id',
  files = 'files',
  Files = 'Files',
  verification_signed_files = 'verification_signed_files',
}

const fieldNameLabelsMap = {
  [FieldNames.password]: 'Password',
  [FieldNames.section]: 'Section',
  [FieldNames.feeder]: 'Feeder',
  [FieldNames.receipt_number]: 'Receipt Number',
  [FieldNames.receipt]: 'Receipt Number',
  [FieldNames.po]: 'PO Number',
  [FieldNames.requisition]: 'Requisition Number',
  [FieldNames.companies]: 'Companies',
  [FieldNames.wr]: 'WR Number',
  [FieldNames.comment]: 'Comment',
  [FieldNames.avatar]: 'Profile Photo',
  [FieldNames.avatar_url]: 'Profile Photo',
  [FieldNames.email]: 'Email',
  [FieldNames.phoneNumber]: 'Phone Number',
  [FieldNames.beginTime]: 'Begin Time',
  [FieldNames.endTime]: 'End Time',
  [FieldNames.firstName]: 'First Name',
  [FieldNames.lastName]: 'Last Name',
  [FieldNames.isCesSupervisor]: 'UFS Supervisor',
  [FieldNames.requestTime]: 'Request Time',
  [FieldNames.finishDate]: 'Finish Date',
  [FieldNames.startDate]: 'Start Date',
  [FieldNames.municipality]: 'Municipality',
  [FieldNames.status]: 'Status',
  [FieldNames.jobType]: 'Job Type',
  [FieldNames.jobTypes]: 'Job Types',
  [FieldNames.zipcode]: 'ZIP Code',
  [FieldNames.company]: 'Company',
  [FieldNames.department]: 'Department',
  [FieldNames.workers]: 'Workers',
  [FieldNames.locations]: 'Locations',
  [FieldNames.workerStatus]: 'Worker Status',
  [FieldNames.requestor]: 'Requestor',
  [FieldNames.supervisor]: 'Supervisor',
  [FieldNames.timesheetPdfs]: 'Timesheet PDFs',
  [FieldNames.images]: 'Images',
  [FieldNames.photo]: 'Photo',
  [FieldNames.sign]: 'Sign',
  [FieldNames.workerPaid]: 'Worker Paid',
  [FieldNames.invoiced]: 'Invoiced',
  [FieldNames.isVerified]: 'CES Verified',
  [FieldNames.is_verified]: 'CES Verified',
  [FieldNames.verified]: 'Verified',
  [FieldNames.worker_paid]: 'Worker Paid',
  [FieldNames.paid]: 'Paid',
  [FieldNames.isStandby]: 'Standby',
  [FieldNames.pending]: 'Pending',
  [FieldNames.pendingReason]: 'Pending Reason',
  [FieldNames.alertAt]: 'Alert Time',
  [FieldNames.alertMessage]: 'Alert Message',
  [FieldNames.TwoFactorAuth]: 'Two Factor Auth',
  [FieldNames.signatureName]: 'Signature Name',
  [FieldNames.employeeNumber]: 'Employee Number',
  [FieldNames.override_min_flagging_hours]: 'Override min flagging hours',
  [FieldNames.ce_verified]: 'CE Verified',
  [FieldNames.no_crew_email_at]: 'No crew email at',
  [FieldNames.department_supervisor_signature]: 'Department Supervisor Signature',
  [FieldNames.cba_signature]: 'CBA Signature',
  [FieldNames.field_supervisor_signature]: 'Field Supervisor Signature',
  [FieldNames.ce_supervisor_signature]: 'CE Supervisor Signature',
  [FieldNames.rejected_photos]: 'Rejected Photos',
  [FieldNames.emergency]: 'Emergency',
  [FieldNames.started_at]: 'Started At',
  [FieldNames.finished_at]: 'Finished At',
  [FieldNames.scheduled_start_at]: 'Scheduled Start At',
  [FieldNames.scheduled_finish_at]: 'Scheduled Finish At',
  [FieldNames.checked]: 'Checked',
  [FieldNames.picture_url]: 'Picture',
  [FieldNames.picture_timestamp]: 'Picture Timestamp',
  [FieldNames.stay_zone_id]: 'Stay Zone ID',
  [FieldNames.files]: 'Files',
  [FieldNames.Files]: 'Files',
  [FieldNames.verification_signed_files]: 'Verification Signed Files',
} as const;

export const normalizeFieldName = (field: string): string => {
  return fieldNameLabelsMap[field] || field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()) || field;
};

const locationImagesChangedRegex = /location_#(\d+)_images_(added|removed|restored)/;

class ChangesHistoryHelper {
  #field: ChangesLogFieldsItem = {} as ChangesLogFieldsItem;
  #departmentsMap: Record<string, Department> = {};
  #jobType: string | number = '';

  constructor(
    field: ChangesLogFieldsItem,
    departmentsMap: Record<string, Department> = {},
    jobType: string | number = ''
  ) {
    this.#field = field;
    this.#departmentsMap = departmentsMap;
    this.#jobType = jobType;
  }

  #transformValue = (value: any): string | JSX.Element => {
    const noData = '–––';

    if (locationImagesChangedRegex.test(this.#field.fieldName)) {
      return Array.isArray(value) && value.length ? (
        <ImageViewer images={value.map((image) => new ImageWithAuthorHelper(image).toObject())} showImagesRow />
      ) : (
        noData
      );
    }

    switch (this.#field.fieldName) {
      case FieldNames.requestTime:
      case FieldNames.finishDate:
      case FieldNames.startDate:
      case FieldNames.endTime:
      case FieldNames.alertAt:
      case FieldNames.no_crew_email_at:
      case FieldNames.scheduled_start_at:
      case FieldNames.scheduled_finish_at:
      case FieldNames.started_at:
      case FieldNames.finished_at:
      case FieldNames.picture_timestamp:
        return moment(value).isValid() ? moment(value).format(FORMATS.datetime) : value || noData;
      case FieldNames.municipality:
        return municipalitiesMap[value]?.label ?? noData;
      case FieldNames.status:
        return value === JobStatus.parsed(value) ? normalizeFieldName(value) : JobStatus.parsed(value);
      case FieldNames.jobType:
        return JobType[value];
      case FieldNames.department:
        return this.#departmentsMap[value]?.name ?? noData;
      case FieldNames.workers:
        return (
          Array.isArray(value) &&
          value.map((item) => (item.worker ? `${item.worker.firstName} ${item.worker.lastName}` : '')).join(', ')
        );
      case FieldNames.locations:
        return Array.isArray(value) && value.join('; ');
      case FieldNames.workerStatus:
        return getWorketStatusString(value, this.#jobType) || noData;
      case FieldNames.requestor:
      case FieldNames.supervisor:
        return <UserSmallComponent id={value} />;
      case FieldNames.avatar:
      case FieldNames.avatar_url:
      case FieldNames.picture_url:
        if (!value || value === 'null') return noData;
        return <ImageViewer images={[new ImageWithAuthorHelper(value).toObject()]} showImagesRow />;
      case FieldNames.images:
      case FieldNames.rejected_photos:
      case FieldNames.photo:
      case FieldNames.files:
      case FieldNames.Files:
      case FieldNames.timesheetPdfs:
      case FieldNames.verification_signed_files:
        const images = value.filter((image) => Boolean(image));
        if (!images.length) return noData;
        return (
          <ImageViewer images={images.map((image) => new ImageWithAuthorHelper(image).toObject())} showImagesRow />
        );
      case FieldNames.cba_signature:
      case FieldNames.field_supervisor_signature:
      case FieldNames.department_supervisor_signature:
      case FieldNames.ce_supervisor_signature:
      case FieldNames.sign:
        const img: HTMLImageElement = new Image();
        const parsedValue = typeof value === 'object' ? value : JSON.parse(value);
        if (parsedValue) {
          img.src = `data:image/jpeg;base64,${parsedValue.data}`;
          return <img src={img.src} alt="Log Sign" className={styles.fieldSign} />;
        }
        return <SignatureIcon />;

      case FieldNames.workerPaid:
      case FieldNames.invoiced:
      case FieldNames.isVerified:
      case FieldNames.worker_paid:
      case FieldNames.paid:
      case FieldNames.is_verified:
      case FieldNames.isCesSupervisor:
      case FieldNames.isStandby:
      case FieldNames.pending:
        return value ? 'True' : 'False';
      case FieldNames.ce_verified:
      case FieldNames.verified:
      case FieldNames.emergency:
        return value ? 'Yes' : 'No';
      case FieldNames.checked:
        return typeof value === 'number' ? (value ? 'Yes' : 'No') : noData;
      case FieldNames.override_min_flagging_hours:
      case FieldNames.TwoFactorAuth:
        return value ? 'Enabled' : 'Disabled';
      case FieldNames.stay_zone_id:
        return ZONES.find(({ id }) => id === value)?.name || value || noData;
      default:
        return value ?? noData;
    }
  };

  get fieldName(): string {
    return normalizeFieldName(this.#field.fieldName);
  }

  get oldValue(): string | JSX.Element {
    return this.#transformValue(this.#field.oldValue);
  }

  get newValue(): string | JSX.Element {
    return this.#transformValue(this.#field.newValue);
  }
}

export default ChangesHistoryHelper;
