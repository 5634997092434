import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import mapboxgl from 'mapbox-gl';
import { FaPause, FaPlay } from 'react-icons/fa';

import { Tooltip } from '@mui/material';

import workerImage from 'Assets/map/worker.png';

import { setGeoJsonDataType, WorkerHistoryItem } from '..';
import { sources } from '../constants';
import { coordinateTransformer } from '../utils';
import s from './MapPlayer.module.scss';

type Props = {
  workerHistory: WorkerHistoryItem[];
  shiftId?: number;
  setGeoJsonData: setGeoJsonDataType;
  mapLoaded: boolean;
  map: mapboxgl.Map;
  onMove?: (lat: number, lon: number) => void;
};

const baseRect = { width: 1, height: 0, left: 0 };

const getGradient = (percent: number) =>
  `linear-gradient(to right, #30adf2, #30adf2 ${percent || 0}%, #fff ${percent || 0}%, #fff)`;

const MapPlayer = ({
  shiftId = 0,
  workerHistory = [],
  mapLoaded = false,
  setGeoJsonData = () => {},
  map,
  onMove,
}: Props) => {
  const [playbackData, setPlaybackData] = useState<WorkerHistoryItem[]>([]);
  const [playbackValue, setPlaybackValue] = useState(0);
  const [playStarted, setPlayStarted] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(1);
  const [rangeRef, setRangeRef] = useState<HTMLSpanElement>();

  const intervalId = useRef<NodeJS.Timeout>(null);
  const playbackPosition = useRef(0);
  const tooltipRef = useRef<HTMLSpanElement>();
  const playTimeRef = useRef<HTMLDivElement>();
  const mousePressedRef = useRef(false);

  useEffect(() => {
    if (!mapLoaded || !map) return;

    if (map.getSource(sources.workerMovedPoint)) return;

    map
      .addSource(sources.workerMovedPoint, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [0, 0],
          },
          properties: {},
        },
      })
      .addLayer({
        id: sources.workerMovedPoint,
        source: sources.workerMovedPoint,
        type: 'circle',
        paint: {
          'circle-color': '#fff',
          'circle-radius': 14,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000',
        },
      });

    map.loadImage(workerImage, (er, image) => {
      if (er) return;
      map.addImage('worker-avatar', image);
      map.addLayer({
        id: sources.workerMovedPoint + '_image',
        source: sources.workerMovedPoint,
        type: 'symbol',
        layout: {
          'icon-image': 'worker-avatar',
          'icon-size': 0.04,
        },
      });
    });
  }, [mapLoaded, map]);

  useEffect(() => {
    const listenerFunction = (e: KeyboardEvent) => {
      if (e.code === 'ArrowRight') {
        setPlaybackValue((prev) => {
          if (prev + 1 >= playbackData.length) return prev;
          playbackPosition.current = prev + 1;
          updateRangeBackground(prev + 1, playbackData.length);
          return prev + 1;
        });
      }
      if (e.code === 'ArrowLeft') {
        setPlaybackValue((prev) => {
          if (prev <= 0) return 0;
          playbackPosition.current = prev - 1;
          updateRangeBackground(prev - 1, playbackData.length);
          return prev - 1;
        });
      }
    };
    if (mapLoaded && map && playbackData.length) {
      window.addEventListener('keydown', listenerFunction);
    } else {
      window.removeEventListener('keydown', listenerFunction);
    }
    return () => {
      window.removeEventListener('keydown', listenerFunction);
    };
  }, [mapLoaded, map, playbackData]);

  useEffect(() => {
    if (!shiftId) {
      clearInterval(intervalId.current);
      setPlayStarted(false);
      setPlaybackValue(0);
      setPlaybackData([]);
      return;
    }
  }, [shiftId]);

  useEffect(() => {
    updateRangeBackground(0, 0);
    clearInterval(intervalId.current);
    setPlayStarted(false);
    setPlaybackValue(0);
    playbackPosition.current = 0;
    setPlaybackData([]);

    if (!workerHistory.length || !mapLoaded) return;

    setPlaybackData(coordinateTransformer(workerHistory));
  }, [workerHistory, mapLoaded]);

  useEffect(() => {
    if (!playbackData.length || !mapLoaded) return;
    const positionData = playbackData[playbackValue];
    if (!positionData) return;
    setGeoJsonData(sources.workerMovedPoint, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [positionData.lon, positionData.lat],
      },
      properties: { datetime: positionData.datetime },
    });
    onMove?.(positionData.lat, positionData.lon);
  }, [playbackValue, playbackData, mapLoaded]);

  useEffect(() => {
    if (playStarted) {
      playWorkerGPS();
    }
  }, [playSpeed, playStarted]);

  const playWorkerGPS = useCallback(() => {
    setPlayStarted(true);
    if (playbackPosition.current === playbackData.length) {
      playbackPosition.current = 0;
    }
    clearInterval(intervalId.current);
    if (!playbackData.length) {
      setPlayStarted(false);
      return;
    }
    let lastGPS = {
      lat: playbackData[playbackPosition.current]?.lat,
      lon: playbackData[playbackPosition.current]?.lon,
    };
    intervalId.current = setInterval(() => {
      if (playbackPosition.current === playbackData.length) {
        clearInterval(intervalId.current);
        setPlayStarted(false);
        return;
      }
      updateRangeBackground(playbackPosition.current, playbackData.length);
      const { datetime, lat, lon } = playbackData[playbackPosition.current] || { datetime: '', lat: 0, lon: 0 };
      if (datetime) {
        if (!playTimeRef.current) {
          clearInterval(intervalId.current);
          return;
        }
        playTimeRef.current.innerText = datetime;
      }
      if (lat !== lastGPS.lat || lon !== lastGPS.lon) {
        setGeoJsonData(sources.workerMovedPoint, {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lon, lat],
          },
          properties: {},
        });
        lastGPS = { lon, lat };
      }
      onMove?.(lat, lon);
      playbackPosition.current++;
    }, 1000 / playSpeed);
  }, [playbackData, playStarted, playSpeed, map]);

  const updateRangeBackground = useCallback(
    (currentPosition: number = 0, length: number = 0) => {
      if (!rangeRef) return;
      rangeRef.style.background = getGradient((currentPosition / length) * 100);
    },
    [rangeRef]
  );

  const pausePlay = useCallback(() => {
    setPlayStarted(false);
    clearInterval(intervalId.current);
    setPlaybackValue(playbackPosition.current);
    updateRangeBackground(playbackPosition.current, playbackData.length);
  }, [playbackData]);

  const boundingRect = useMemo(() => {
    return rangeRef?.getBoundingClientRect() || baseRect;
  }, [rangeRef]);

  const calculatePlayPosition = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      const lengthX = Math.floor(boundingRect.width);
      const calculatedPositionX = e.clientX - Math.round(boundingRect.left);
      const valuesAmount = playbackData.length + 1;
      const positionX = calculatedPositionX < 0 ? 0 : calculatedPositionX > lengthX ? lengthX : calculatedPositionX;
      const index = Math.ceil((positionX / lengthX) * valuesAmount);
      return { index, positionX };
    },
    [playbackData, boundingRect]
  );

  const mouseLeave = useCallback(() => {
    tooltipRef.current.style.display = 'none';
    mousePressedRef.current = false;
  }, []);

  const changePlaybackValue = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!playbackData.length) return;
      const { index } = calculatePlayPosition(e);
      setPlaybackValue(index);
      playbackPosition.current = index;
      updateRangeBackground(playbackPosition.current, playbackData.length);
    },
    [playbackData, boundingRect]
  );

  const moveWithUpdate = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      tooltipRef.current.style.display = 'inline';
      const { index, positionX } = calculatePlayPosition(e);
      tooltipRef.current.innerText = playbackData[index]?.datetime || playbackData[0].datetime || '00:00:00';
      tooltipRef.current.style.left = `${positionX}px`;
      setPlaybackValue(index);
      playbackPosition.current = index;
      updateRangeBackground(playbackPosition.current, playbackData.length);
    },

    [playbackData, boundingRect]
  );

  const mouseMove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!playbackData.length) return;
      if (mousePressedRef.current) {
        moveWithUpdate(e);
        return;
      }
      tooltipRef.current.style.display = 'inline';
      const { index, positionX } = calculatePlayPosition(e);
      tooltipRef.current.innerText = playbackData[index]?.datetime || playbackData[0].datetime || '00:00:00';
      tooltipRef.current.style.left = `${positionX}px`;
    },
    [playbackData, boundingRect]
  );

  const changePlaySpeed = useCallback(() => {
    setPlaySpeed((prev) => {
      switch (prev) {
        case 1:
          return 2;
        case 2:
          return 5;
        case 5:
          return 10;
        case 10:
          return 1;
        default:
          return prev;
      }
    });
  }, []);

  const mousePressed = useCallback((e) => {
    mousePressedRef.current = true;
  }, []);

  const mouseUnPressed = useCallback(() => {
    mousePressedRef.current = false;
  }, []);

  const player = useMemo(
    () => (
      <span
        ref={setRangeRef}
        onMouseMove={mouseMove}
        onMouseLeave={mouseLeave}
        onClick={changePlaybackValue}
        style={{ background: getGradient((playbackValue / playbackData.length) * 100) }}
        className={s.playerLine}
        onMouseDown={mousePressed}
        onMouseUp={mouseUnPressed}
      />
    ),
    [playbackData, boundingRect]
  );
  const playerInfo = useMemo(
    () => (
      <span ref={tooltipRef} className={s.playerInfo}>
        {playbackData[playbackValue]?.datetime}
      </span>
    ),
    [playbackData]
  );

  return (
    <div className={s.playerContainer}>
      {playStarted ? (
        <div className={s.playButton} onClick={pausePlay}>
          <FaPause color="#fff" />
        </div>
      ) : (
        <div className={s.playButton} onClick={playWorkerGPS}>
          <FaPlay color="#fff" />
        </div>
      )}
      <Tooltip disableInteractive placement="top" title="Play speed. 1x mean 1 recorded minute per 1 real second">
        <div className={s.playSpeed} onClick={changePlaySpeed}>
          {playSpeed}x
        </div>
      </Tooltip>
      <Tooltip
        placement="top"
        title={
          <span style={{ fontSize: 14 }}>
            {playbackData.length
              ? 'Select a position with a mouse click or use the left and right keys to move the position'
              : 'No data'}
          </span>
        }
      >
        <div className={s.playerLineContainer}>
          {player}
          {playerInfo}
        </div>
      </Tooltip>
      <div ref={playTimeRef} className={s.time}>
        {playbackData[playbackValue]?.datetime}
      </div>
    </div>
  );
};

export default memo(MapPlayer);
