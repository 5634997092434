import { FC, useEffect, useMemo } from 'react';

import moment, { Moment } from 'moment';

import { Box, Collapse } from '@mui/material';

import { MasterTimesheetItem } from 'types/Timesheet';

import { TimesheetsAPI } from 'Services/API';
import { FORMATS } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import useProcessing from 'Utils/hooks/useProcessing';
import AppDateTimePicker from 'components/AppDateTimePicker/AppDateTimePicker';
import AppInputField from 'components/AppInputField/AppInputField';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

import MasterTimesheetSignature from '../../components/MasterTimesheetSignature/MasterTimesheetSignature';
import MasterTimesheetSignatures from '../../components/MasterTimesheetSignatures/MasterTimesheetSignatures';
import { MasterTimesheetContentBox } from '../../styled';
import TimesheetsDateChangesTable, { Dates } from './components/TimesheetsDateChangesTable';

export type NewMasterTimesheetDatetime =
  | { key: 'start_at'; start_at: string }
  | { key: 'finish_at'; finish_at: string };
export type GetChangedTimesheetsParams = {
  timesheets: MasterTimesheetItem[];
  maxWorkers: number;
} & NewMasterTimesheetDatetime;

const MAX_JOB_SHIFT_DURATION_IN_HOURS = 16;

export const getTimesheetsThatWillChange = ({
  timesheets,
  maxWorkers,
  ...newDate
}: GetChangedTimesheetsParams): MasterTimesheetItem[] => {
  if (!timesheets.length) {
    return [];
  }

  switch (newDate.key) {
    case 'start_at':
      const sortedTimesheetsByStartDate = [...timesheets].sort((a, b) =>
        moment(a.start_at).isBefore(b.start_at) ? -1 : 1
      );

      const timesheetsBeforeNewStartDate = sortedTimesheetsByStartDate.filter(({ start_at }) =>
        moment(start_at).isBefore(newDate.start_at)
      );

      const timesheetsThreadsCount = maxWorkers - timesheetsBeforeNewStartDate.length;

      if (timesheetsThreadsCount <= 0) {
        return timesheetsBeforeNewStartDate;
      }

      const timesheetsAfterNewStartDate = sortedTimesheetsByStartDate
        .filter(({ start_at }) => moment(start_at).isSameOrAfter(newDate.start_at))
        .slice(0, timesheetsThreadsCount);

      return [...timesheetsBeforeNewStartDate, ...timesheetsAfterNewStartDate].filter(
        ({ finish_at }) => moment(newDate.start_at).diff(finish_at, 'hours') < MAX_JOB_SHIFT_DURATION_IN_HOURS
      );

    case 'finish_at':
      const sortedTimesheetsByFinishDate = [...timesheets].sort((a, b) =>
        moment(a.finish_at).isAfter(b.finish_at) ? -1 : 1
      );

      const timesheetsAfterNewFinishDate = sortedTimesheetsByFinishDate.filter(({ finish_at }) =>
        moment(finish_at).isAfter(newDate.finish_at)
      );

      const timesheetsThreadsCountBeforeNewFinishDate = maxWorkers - timesheetsAfterNewFinishDate.length;

      if (timesheetsThreadsCountBeforeNewFinishDate <= 0) {
        return timesheetsAfterNewFinishDate;
      }

      const timesheetsBeforeNewFinishDate = sortedTimesheetsByFinishDate
        .filter(({ finish_at }) => moment(finish_at).isSameOrBefore(newDate.finish_at))
        .slice(0, timesheetsThreadsCountBeforeNewFinishDate);

      return [...timesheetsBeforeNewFinishDate, ...timesheetsAfterNewFinishDate].filter(
        ({ start_at }) => moment(start_at).diff(newDate.finish_at, 'hours') < MAX_JOB_SHIFT_DURATION_IN_HOURS
      );
    default:
      return [];
  }
};

const DateTimePickers = [
  { key: 'start_at', label: 'Start Date' },
  { key: 'finish_at', label: 'Finish Date' },
] as const;

const MasterTimesheetInfo: FC = () => {
  const { inProcess: totalHoursCalculating, promiseWrapper } = useProcessing();
  const userCanEditTimesheets = UserPermissions.has.edit_timesheets;

  const { values, errors, setFieldValue, setValues } = useMasterTimesheetContext();

  const showStartDateChanges = useMemo(
    () =>
      moment(values.start_at).isValid() &&
      values.timesheetsThatWillBeChanged.some(({ start_date_changed }) => start_date_changed),
    [values.timesheetsThatWillBeChanged, values.start_at]
  );

  const showFinishDateChanges = useMemo(
    () =>
      moment(values.finish_at).isValid() &&
      values.timesheetsThatWillBeChanged.some(({ finish_date_changed }) => finish_date_changed),
    [values.timesheetsThatWillBeChanged, values.finish_at]
  );

  const dateTimePickersEnabled = useMemo(
    () => values.timesheets?.every(({ paid, invoiced }) => !paid && !invoiced) && userCanEditTimesheets,
    [values.timesheets]
  );

  useEffect(() => {
    if (
      !values.start_at ||
      !values.finish_at ||
      !moment(values.start_at).isValid() ||
      !moment(values.finish_at).isValid()
    )
      return;
    promiseWrapper(
      TimesheetsAPI.getMasterTimesheetForUpdatesData(values.confirmation_number, {
        startDate: values.start_at,
        finishDate: values.finish_at,
      })
    ).then((response) => {
      setValues((prev) => ({
        ...prev,
        total_hours: response.new_total_hours,
        total_billable_hours: response.new_total_billable_hours,
        timesheetsThatWillBeChanged: response.timesheets,
      }));
    });
  }, [values.start_at, values.finish_at]);

  return (
    <>
      <MasterTimesheetContentBox>
        <Box display="flex" gap="14px">
          {DateTimePickers.map(({ key, label }) => (
            <AppDateTimePicker
              key={key}
              label={label}
              value={values[key] ? moment(values[key]) : moment()}
              format={FORMATS.masterTimesheetDateTimePicker}
              onChange={(date: Moment | null) => {
                setFieldValue(key, date?.format(FORMATS.masterTimesheetDateValue) || null);
              }}
              error={errors[key]}
              helperText={
                !dateTimePickersEnabled ? 'At least one timesheet has already been invoiced or paid.' : errors[key]
              }
              disabled={!dateTimePickersEnabled}
              fullWidth
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          ))}
        </Box>

        {dateTimePickersEnabled && (showStartDateChanges || showFinishDateChanges) && (
          <Box display="flex" gap="14px">
            <Box flex={1} overflow={'auto'} maxHeight={300}>
              <Collapse in={showStartDateChanges}>
                <TimesheetsDateChangesTable datesType={Dates.start} />
              </Collapse>
            </Box>

            <Box flex={1} overflow={'auto'} maxHeight={300}>
              <Collapse in={showFinishDateChanges}>
                <TimesheetsDateChangesTable datesType={Dates.finish} />
              </Collapse>
            </Box>
          </Box>
        )}

        <Box display="flex" gap="14px">
          <AppInputField
            size="small"
            fullWidth
            label="Total hours"
            value={totalHoursCalculating ? 'Calculating...' : values.total_hours || '---'}
            disabled
          />
          <AppInputField
            size="small"
            fullWidth
            label="Billable hours"
            value={totalHoursCalculating ? 'Calculating...' : values.total_billable_hours || '---'}
            disabled
          />
        </Box>
      </MasterTimesheetContentBox>
      <MasterTimesheetContentBox>
        {values?.signatures?.length ? <MasterTimesheetSignatures /> : <MasterTimesheetSignature />}
      </MasterTimesheetContentBox>
    </>
  );
};

export default MasterTimesheetInfo;
