import React, { PureComponent, Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { WorkersPage } from 'pages';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthComponent from 'Containers/Auth/AuthComponent';
import { AppFullPageLoaderComponent } from 'Containers/Components/LoadAppComponent/AppFullPageLoader';
import Profile from 'Containers/Profile/Profile';
import ProjectSettings from 'Containers/ProjectSettings/ProjectSettings';
import MasterTimesheet from 'Containers/Timesheets/MasterTimesheet/MasterTimesheet';
import { ReduxState } from 'createStore';

import './App.scss';
import Billing from './Containers/Billing/Billing';
import PrivateRoute from './Containers/Components/PrivateRoute';
import PublicRoute from './Containers/Components/PublicRoute';
import InvoicesTable from './Containers/Invoices/InvoiceTable/InvoicesTable';
import Invoices from './Containers/Invoices/Invoices';
import TimesheetsTable from './Containers/Invoices/TimesheetsTable/TimesheetsTable';
import Job from './Containers/Job/Job';
import JobCreateComponent from './Containers/Job/JobCreate';
import JobDetailsComponent from './Containers/Job/JobDetails';
import JobEdit from './Containers/Job/JobEdit';
import CEMap from './Containers/Maps/Map';
import MenuCE from './Containers/Menus/MenuCE';
import Notifications from './Containers/Notifications/Notifications';
import PageNotFound from './Containers/PageNotFound/PageNotFound';
import Reports from './Containers/Reports/Reports';
import RolesComponent from './Containers/Roles/Roles';
import Subcontractors from './Containers/Subcontractors/Subcontractors';
import TimesheetCreate from './Containers/Timesheets/TimesheetCreate';
import TimesheetEdit from './Containers/Timesheets/TimesheetEdit/TimesheetEdit';
import Timesheets from './Containers/Timesheets/TimesheetsPage';
import WorkerCreateComponent from './Containers/Workers/WorkerCreate';
import WorkerDetailsComponent from './Containers/Workers/WorkerDetails';
import './Tables.scss';

const Scheduling = React.lazy(() => import('./Containers/Scheduling/Scheduling' /* webpackChunkName: "scheduling" */));
const JobGrid = React.lazy(() => import('./Containers/Job/JobGrid' /* webpackChunkName: "job-grid" */));
const Dispatch = React.lazy(() => import('./Containers/Dispatch/Dispatch' /* webpackChunkName: "dispatch" */));

class App extends PureComponent<PropsFromRedux> {
  componentDidMount() {
    if (!import.meta.env.PROD) return;
    window.addEventListener('beforeunload', (ev) => {
      if (window.location.href.includes('login')) {
        return;
      }
      ev.preventDefault();
      return (ev.returnValue = '');
    });
  }
  render() {
    const canSeePage = this.props.canSeePage;
    return (
      <div className="App">
        <MenuCE />
        <Suspense fallback={<AppFullPageLoaderComponent />}>
          <Switch>
            <PrivateRoute exact path="/" component={() => <Redirect to="/job-grid" push={false} />} />
            <PublicRoute exact path="/login" component={AuthComponent} />
            <PublicRoute exact path="/activate" component={AuthComponent} />
            <PublicRoute exact path="/restore/:token" component={AuthComponent} />
            <PrivateRoute exact path="/map" component={CEMap} />
            <PrivateRoute exact path="/map/:id" component={CEMap} />
            <PrivateRoute path="/dispatch" component={Dispatch} canSeePage={canSeePage.dispatch} />
            <PrivateRoute path="/billing" component={Billing} canSeePage={canSeePage.billing} />
            <PrivateRoute exact path="/invoices" component={Invoices} canSeePage={canSeePage.invoicing} />
            <PrivateRoute path="/subcontractors" component={Subcontractors} canSeePage={canSeePage.subcontractors} />
            <PrivateRoute exact path="/timesheets" component={Timesheets} />
            <PrivateRoute exact path="/workers" component={WorkersPage} canSeePage={canSeePage.workers} />
            <PrivateRoute exact path="/job-grid" component={JobGrid} />
            <PrivateRoute exact path="/job" component={Job} />
            <PrivateRoute path="/job/create" component={JobCreateComponent} />
            <PrivateRoute exact path="/job/:id/copy" component={JobCreateComponent} />
            <PrivateRoute exact path="/job/:id/edit" component={JobEdit} />
            <PrivateRoute exact path="/job/:id" component={JobDetailsComponent} />
            <PrivateRoute exact path="/job/:id/master-timesheet" component={JobDetailsComponent} />
            <PrivateRoute path="/workers/create" component={WorkerCreateComponent} canSeePage={canSeePage.workers} />
            <PrivateRoute path="/workers/:id" component={WorkerDetailsComponent} canSeePage={canSeePage.workers} />
            <PrivateRoute path="/timesheets/:id/edit" component={TimesheetEdit} />
            <PrivateRoute exact path="/timesheets/create" component={TimesheetCreate} />
            <PrivateRoute exact path="/timesheets/master" component={MasterTimesheet} />
            <PrivateRoute path="/notifications" component={Notifications} />
            <PrivateRoute exact path="/roles" component={RolesComponent} canSeePage={canSeePage.roles} />
            <PrivateRoute exact path="/roles/:id" component={RolesComponent} canSeePage={canSeePage.roles} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/invoices/:id" component={InvoicesTable} canSeePage={canSeePage.invoicing} />
            <PrivateRoute
              exact
              path="/invoices/:id/:invoiceId"
              component={TimesheetsTable}
              canSeePage={canSeePage.invoicing}
            />
            <PrivateRoute path="/reports" component={Reports} canSeePage={canSeePage.reports} />
            <PrivateRoute path="/scheduling" exact component={Scheduling} canSeePage={canSeePage.scheduling} />
            <PrivateRoute
              path="/project-settings"
              component={ProjectSettings}
              canSeePage={canSeePage.projectSettings}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={20000} />
        </Suspense>
      </div>
    );
  }
}

const connector = connect(
  (state: ReduxState) => ({
    canSeePage: state.userPermissions.canSeePage,
  }),
  {}
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
