import { CESWorker } from 'types/Common/User';

import { WorkerHistoryItem } from 'Containers/Dispatch/dialog/WorkerGPSPlayback';
import { WORKER_TYPE } from 'Containers/Workers/Constants';
import { ComplaintNote, Complaints } from 'Models/APITypes';
import ApiService, { API } from 'Services/HttpService';

class WorkersAPI {
  getAll = (params?: API.RequestBody) => {
    return ApiService.GET('/workers', params);
  };

  create = (data: API.Body<'json'>) => {
    return ApiService.POST_JSON('/workers/create', data);
  };

  update = (id: string | number, data: API.Body<'json'>) => {
    return ApiService.POST_JSON<{ status_code: number; worker: CESWorker }>(`/workers/${id}`, data);
  };

  delete = (id: string | number) => {
    return ApiService.DELETE(`/workers/${id}`);
  };

  importFromExcel = (excelFile: File) => {
    return ApiService.POST_FDATA('/workers/import-excel', { excel: excelFile });
  };

  getPushGroups = ({ hours = 24, jobTypes = [] }) => {
    return ApiService.GET(`/workers/push-groups`, { hours, jobTypes });
  };

  sendNotificationToWorkers = ({ message = '', workerIds = [] }) => {
    const body = new FormData();
    body.append('message', message);
    workerIds.forEach((id) => body.append('worker_ids[]', id));
    return ApiService.POST_FDATA(`/workers/push-notification`, body);
  };

  getJobsComplaints = (id: string | number) => {
    return ApiService.GET<{ result: Complaints[] }>(`/workers/${id}/complaint-jobs`);
  };

  getWorkerComplaints = (id: string | number) => {
    return ApiService.GET<{ notes: ComplaintNote[] }>(`/workers/${id}/complaint-worker`);
  };

  getWorkerGPSHistory = (id: string | number, params: GetWorkerGPSHistoryParams) => {
    return ApiService.GET<{ gps_history: WorkerHistoryItem[] }>(`/workers/${id}/gps-history`, params);
  };

  sendComplaint = ({ workerId = 0, shiftId = 0, message = '', jobId = 0 }) => {
    const url = jobId ? `/workers/${workerId}/complaint` : `/workers/${workerId}/complaint-worker`;
    const data = jobId ? { job_id: jobId.toString(), job_worker_id: shiftId.toString(), message } : { message };

    return ApiService.POST_FDATA(url, data);
  };

  exportToCsv = (query?: ExportWorkersToCSVOptions) => {
    const fileName = query
      ? `Workers_${Object.entries(query)
          .map(
            ([key, value]) =>
              `${key}=${key === 'workerType' ? WORKER_TYPE.find((type) => type.value === value).label : value}`
          )
          .join('&')}`
      : undefined;
    return ApiService.DOWNLOAD_FILE('/workers/export', query, { fileName });
  };
}

export default new WorkersAPI();

export type ExportWorkersToCSVOptions = {
  status?: string;
  query?: string;
  subcontractorId?: number;
  workerType?: number;
};

/** Dates in format: Y-m-d */
export type GetWorkerGPSHistoryParams = {
  start_at: string;
  finish_at: string;
};
