import { GetWorkersRequest } from '../api';
import { WorkersSearchParams } from '../constants/initialSearchParams';

const prepareQueryParams = (params: WorkersSearchParams): GetWorkersRequest => {
  return {
    page: Number(params.page),
    per_page: Number(params.per_page),
    workerTypes: params.workerTypes || undefined,
    statuses: params.statuses || undefined,
    subcontractorId: params.subcontractorId ? Number(params.subcontractorId) : undefined,
    search: params.search || undefined,
    order_by: params.order_by,
    order_by_type: Boolean(Number(params.order_by_type)),
  };
};

export default prepareQueryParams;
