import { Table, TableBody, TableCell, TableHead, TableProps, TableRow, TableSortLabel, styled } from '@mui/material';

import { SortTableIcon } from 'Utils/Icon';

export enum TableSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type AppTableColumn<T> = {
  key: string;
  label: string;
  sorted?: boolean;
  width?: string | number;
  renderCell?: (row: T) => JSX.Element | String;
};

type StyledAppTableProps<T> = {
  data: T[];
  columns: AppTableColumn<T>[];
  order_by?: string;
  order?: TableSortOrder;
  onSort?: (property: string) => void;
  onTableRowClick?: (row: T) => void;
} & TableProps;

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableHead-root': {
    '& .MuiTableRow-head': {
      '& .MuiTableCell-root': {
        backgroundColor: theme.palette.secondary.light,
        border: 'none',

        '&:first-of-type': {
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        },

        '&:last-of-type': {
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },

        '& .MuiTableSortLabel-root': {
          '&.Mui-active': {
            color: theme.palette.primary.main,
            '& .MuiTableSortLabel-icon': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },

  '& .MuiTableCell-root': {
    padding: '10px 15px',
    fontSize: 13,
  },
}));

const StyledAppTable = <T,>({
  data,
  columns,
  order_by,
  order,
  onSort,
  onTableRowClick,
  ...tableProps
}: StyledAppTableProps<T>) => {
  return (
    <StyledTable {...tableProps}>
      <TableHead>
        <TableRow>
          {columns.map(({ key, label, sorted, width }) => (
            <TableCell key={String(key)} width={width} sx={{ maxWidth: width }}>
              {sorted ? (
                <TableSortLabel
                  active={order_by === String(key)}
                  direction={order_by === String(key) ? order : TableSortOrder.ASC}
                  onClick={() => onSort(String(key))}
                  IconComponent={SortTableIcon}
                >
                  {label}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index} onClick={() => onTableRowClick?.(row)}>
            {columns.map(({ key, renderCell, width }) => (
              <TableCell key={String(key)} width={width} sx={{ maxWidth: width }}>
                {renderCell ? renderCell(row) : row[String(key)]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default StyledAppTable;
