import { ConedUser } from 'types/Common/User';

const userDefaultState: ConedUser = {
  activateToken: '',
  avatar: '',
  company_id: 0,
  departmentGroups: [],
  departments: [],
  black_list_department_groups: [],
  black_list_departments: [],
  email: '',
  firstName: '',
  format: 'old_format',
  id: 0,
  isActive: 0,
  isApproved: 1,
  isOnline: false,
  lastName: '',
  location: {},
  name: '',
  notification: {},
  phoneNumber: '',
  roles: [],
  status: '',
  subcontractor_id: 0,
  subcontractorName: '',
  uid: 0,
  workerTypes: [],
  co_employee_id: null,
  notes: [],
  two_factor_auth_enabled: 0,
  auth_sessions: [],
};

export default { user: userDefaultState };
