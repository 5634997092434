import { Box, Typography } from '@mui/material';

import { TimesheetNames } from 'Constants/timesheet';
import { UserRoles } from 'Constants/user';
import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import { generateTimesheetPhotoName } from 'Containers/Timesheets/utils';
import { TimesheetsAPI } from 'Services/API';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import picture from 'Utils/picture';
import { showSuccessMessage } from 'Utils/successMessage';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';
import { useAppDispatch } from 'createStore';

import styles from './UploadFiles.module.scss';
import SignFileButtons from './components/SignFileButtons';

const UploadFiles = () => {
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const timesheetIsCancelled = timesheet.status === 'cancelled';
  const canEditTimesheetFiles = UserPermissions.has.can_edit_timesheet_files as boolean;
  const isUserSubcontractor = UserRoles.has.SubcontractorRole;
  const onChangeFiles = (field, values) => {
    formikBag.setFieldValue(field, values);
  };

  const onSignImageChange = async (url: string, signed: 0 | 1) => {
    try {
      const updatedImages = timesheet.images.map((img) => {
        if (typeof img === 'string' || img instanceof File || picture.removeDate(img.url) !== picture.removeDate(url)) {
          return img;
        }

        TimesheetsAPI.signTimesheetFile(timesheet.id, {
          file_url: img.url,
          signed,
        })
          .then(({ message }) => {
            showSuccessMessage(message);
          })
          .catch((error) => {
            showErrorMessage(error);
          });

        return {
          ...img,
          signed,
        };
      });
      await formikBag.setFieldValue(TimesheetNames.images, updatedImages);
    } catch (error) {
      showErrorMessage('Failed to sign the image');
    }
  };

  return (
    <TimesheetBox>
      <Box className={styles.files}>
        <Box>
          <Typography variant="h6" gutterBottom component="div">
            Photo of Paper Timesheet
          </Typography>
          <ImageUpload
            key={Date.now()}
            dropzoneInnerStyle={{
              padding: '50px 0',
            }}
            onChangeImage={(values) => onChangeFiles(TimesheetNames.images, values)}
            defaultImages={timesheet?.images ?? []}
            canRemove={canEditTimesheetFiles}
            disabled={timesheetIsCancelled || !canEditTimesheetFiles}
            allowMultipleTypes
            getDownloadedImageName={({ is_rejected }) =>
              generateTimesheetPhotoName({
                shift_id: timesheet.shiftId,
                is_rejected,
              })
            }
            renderExtraImageActions={(image) => (
              <SignFileButtons
                image={image}
                onSignImage={(url, signed) => {
                  onSignImageChange(url, signed);
                }}
                visible={!UserRoles.has.ConEdRole && canEditTimesheetFiles}
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom component="div">
            Attach PDFs
          </Typography>
          <ImageUpload
            dropzoneInnerStyle={{
              padding: '50px 0',
            }}
            onChangeImage={(values) => onChangeFiles(TimesheetNames.timesheetPdfs, values)}
            defaultImages={timesheet?.timesheetPdfs ?? []}
            filesMode
            canRemove={canEditTimesheetFiles && !isUserSubcontractor}
            disabled={timesheetIsCancelled || !canEditTimesheetFiles || isUserSubcontractor}
            getDownloadedImageName={({ is_rejected }) =>
              generateTimesheetPhotoName({
                shift_id: timesheet.shiftId,
                is_rejected,
                isPDF: true,
              })
            }
          />
        </Box>
      </Box>
    </TimesheetBox>
  );
};

export default UploadFiles;
