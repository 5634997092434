export const TestKey = 'data-cy';

export const TestIDs = {
  navBar: {
    dashboard: 'dashboardNavLink',
    scheduling: 'schedulingNavLink',
    map: 'mapNavLink',
    jobList: 'jobListNavLink',
    jobGrid: 'jobGridNavLink',
    workers: 'workersNavLink',
    subcontractors: 'subcontractorsNavLink',
    timesheets: 'timesheetsNavLink',
    billing: 'billingNavLink',
    reports: 'reportsNavLink',
    profileMenu: 'profileMenu',
  },
  pages: {
    login: {
      fields: {
        email: 'loginEmailField',
        password: 'loginPasswordField',
        forgotPassword: 'forgotPasswordEmailField',
        code: 'twoFactorAuthCodeField',
      },
      radio: {
        twoFactorAuthByEmail: 'twoFactorAuthByEmail',
        twoFactorAuthBySms: 'twoFactorAuthBySms',
      },
      links: {
        forgotPassword: 'forgotPasswordLink',
      },
      buttons: {
        backToLogin: 'backToLoginBtn',
        signIn: 'signInBtn',
        forgotPassword: 'forgotPasswordBtn',
      },
      areas: {
        login: 'mainLoginContainer',
      },
    },
    workers: {
      buttons: {
        addWorker: 'addWorkerBtn',
      },
      fields: {
        workerSearchInput: 'workerSearchInput',
      },
    },
    workerProfile: {
      tabs: {
        appointedJobs: 'appointedJobsTab',
        profile: 'profileTab',
        history: 'historyTab',
        complaints: 'complaintsTab',
        gpsHistory: 'gpsHistoryTab',
      },
      buttons: {
        removeWorker: 'removeWorker',
        confirmRemoveWorker: 'confirmRemoveWorker',
      },
    },
    subcontractors: {
      buttons: {
        addNewSubcontractor: 'addNewSubcontractor',
        sort: {
          id: 'sortSubcontractorsById',
        },
        removeSubcontractor: 'removeSubcontractor',
        changeStatus: 'changeStatus',
      },
      table: {
        colorCell: 'subcontractorColorCell',
        firstNameCell: 'subcontractorFirstNameCell',
        lastNameCell: 'subcontractorLastNameCell',
        emailCell: 'subcontractorEmailCell',
        phoneNumberCell: 'subcontractorPhoneNumberCell',
        companyNameCell: 'subcontractorCompanyNameCell',
        workersCell: 'subcontractorWorkersCell',
        statusCell: 'subcontractorStatusCell',
      },
    },
    manageRoles: {
      fields: {
        searchInput: 'pagesManageRolesSearchInput',
      },
    },
  },
  components: {
    subcontractorForm: {
      fields: {
        firstName: 'subcontractorFirstNameField',
        lastName: 'subcontractorLastNameField',
        phoneNumber: 'subcontractorPhoneNumberField',
        email: 'subcontractorEmailField',
        companyName: 'subcontractorCompanyName',
        workers: 'subcontractorWorkersSelect',
        companyColor: 'subcontractorCompanyColor',
        workersSelectOption: 'subcontractorWorkersSelectOption',
        editColor: 'editColor',
        addedWorkers: 'addedWorkers',
      },

      buttons: {
        saveSubcontractor: 'saveSubcontractor',
      },
    },
    select: {
      state: {
        optionPrefix: 'state-select-option-',
      },
      plateCompany: {
        optionPrefix: 'plate-company-select-option-',
      },
    },
    coreTable: 'coreTable',
    pagination: {
      nextPage: 'nextPageBtn',
      previousPage: 'previousPageBtn',
    },
    profileForm: {
      tabs: {
        information: 'profileFormInformationTab',
        password: 'profileFormPasswordTab',
        notifications: 'profileFormNotificationsTab',
      },
      profileInfoTab: {
        fields: {
          avatar: 'avatarField',
          firstName: 'profileFirstNameField',
          lastName: 'profileLastNameField',
          email: 'profileEmailField',
          phoneNumber: 'profilePhoneNumberField',
          departments: 'profileDepartmentsField',
          roles: 'profileRolesField',
          employeeNumber: 'profileEmployeeNumberField',
        },
        buttons: {
          saveProfile: 'saveProfileBtn',
          cropImageBtn: 'cropImageBtn',
          deleteImageBtn: 'deleteImageBtn',
          uploadImageBtn: 'uploadImageBtn',
        },
      },
      profilePasswordTab: {
        fields: {
          createPasswordField: 'profileCreatePasswordField',
          repeatNewPasswordField: 'profileRepeatNewPasswordField',
          currentPasswordField: 'profileCurrentPasswordField',
        },
        buttons: {
          twoFactorAuthentication: '2FAtoggle',
          showPassword: 'showPassword',
        },
      },
      profileNotificationsTab: {
        checkboxes: {
          allEmailCheckbox: 'allEmailCheckbox',
          allWebCheckbox: 'allWebCheckbox',
          emailCheckbox: 'emailCheckbox',
          webCheckbox: 'webCheckbox',
        },
      },
      select: {
        country: 'selectCountryPhoneNumber',
      },
      options: {
        countryOption: 'countryOptionsList',
        departments: 'departmentsOptionsList',
      },
    },
    profileMenu: {
      buttons: {
        manageRoles: 'profileMenuButtonsManageRoles',
        profileSettings: 'profileMenuButtonsProfileSettings',
        editNotifications: 'profileMenuButtonsEditNotifications',
        projectSettings: 'profileMenuButtonsProjectSettings',
        logout: 'profileMenuButtonsLogout',
      },
    },
    workerForm: {
      options: {
        workerTypes: 'workerTypesOptionsList',
        subcontractor: 'subcontractorOptionsList',
      },
      fields: {
        avatar: 'avatarField',
        firstName: 'firstNameField',
        lastName: 'lastNameField',
        phoneNumber: 'phoneNumberField',
        email: 'emailFieldInCreateAccount',
        password: 'passwordFieldInCreateAccount',
        workerTypes: 'workerTypesSelect',
        subcontractor: 'subcontractorSelect',
        isSupervisor: 'isSupervisorCheckbox',
        beginTime: 'beginTime',
        endTime: 'endTime',
        zipCode: 'zipCode',
      },
      buttons: {
        submit: 'submit',
      },
    },
    appPaperModal: {
      buttons: {
        cancel: 'appPaperModalCancelBtn',
        confirm: 'appPaperModalConfirmBtn',
      },
    },
  },
} as const;
