import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReduxState } from 'createStore';

import { API_BASE_ENDPOINT } from './endpoint';
import apiTags from './tags';

const Api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [apiTags.WORKERS],
});

export default Api;
