import moment, { Moment } from 'moment';

import { Department } from 'types/Common/Companies';
import { ImageWithAuthor } from 'types/Location';

import { JobType, JobTypeHelper } from 'Constants/job';
import picture from 'Utils/picture';

export const deptRequiresFinishDate = ({
  jobType = JobType.Flagging,
  department = null,
  isStandby = false,
}: {
  jobType?: JobType;
  department?: Department;
  isStandby?: boolean;
}) => {
  if (!department) {
    return false;
  }

  const { create_integration_jobs, enforce_standby_end_time, enforce_flagging_end_time, enforce_parking_end_time } =
    department;

  if (create_integration_jobs) {
    return true;
  }

  const { isFlagging, isParking } = new JobTypeHelper(jobType);

  const canSeeFinishDate =
    (isStandby && enforce_standby_end_time) ||
    (isFlagging && enforce_flagging_end_time) ||
    (isParking && enforce_parking_end_time);

  return Boolean(canSeeFinishDate);
};

export const getCanEditEndDate = ({
  jobType = JobType.Flagging,
  department = null,
  isStandby = false,
  isEdit = false,
}: {
  jobType?: JobType;
  department?: Department;
  isStandby?: boolean;
  isEdit?: boolean;
}) => {
  const { isSignage } = new JobTypeHelper(jobType);
  const canEditInSignageJobs = isSignage && !isEdit;

  const canSeeFinishDate = deptRequiresFinishDate({ jobType, department, isStandby });
  return Boolean(canEditInSignageJobs || canSeeFinishDate);
};

export const roundHoursUp = (time: string | Date | Moment = '') => {
  const momentTime = moment(time);
  const formattedForRound = Number(momentTime.format('H.mmss'));
  return momentTime.set({
    hour: Math.ceil(formattedForRound),
    minute: 0,
    second: 0,
  });
};

export const generateLocationImageName = ({
  jobId = 0 as string | number,
  address = '',
  structure = '',
  updated_at = '',
} = {}) =>
  picture.generateName(
    [
      [jobId, jobId, 'New Job'],
      [structure, `structure ${structure?.replace(/[.]/g, '')}`],
      [address, `address ${address?.replace(/[.]/g, '')}`],
      [updated_at, moment(updated_at).format('MM-DD-YY hh mm A')],
    ],
    ' - '
  );

export const generateJobPDFFileName = ({ file, job_id }: { file: ImageWithAuthor; job_id: number | string }) => {
  const isValidDate = moment(file.created_at).isValid();
  const formattedDateSuffix = isValidDate ? `_(${moment(file.created_at).format('MM-DD-YYYY')})` : '';
  return `job_${job_id + formattedDateSuffix}`;
};
