import React from 'react';

import { Opacity } from '@mui/icons-material';
import { Box, Chip, FormControl, Select, Stack, styled } from '@mui/material';

import { Poppins } from 'Utils/Fonts';

export const WorkersPageContainer = styled(Stack)(({ theme }) => ({
  maxWidth: 1880,
  width: '100%',
  padding: '0 20px 14px 20px',
  margin: '0 auto',
  flex: 1,
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    padding: '10px 16px 10px 16px',
  },
}));

export const WorkersFiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
}));

export const WorkersTableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  flex: 1,
  flexShrink: 1,
  marginTop: 12,
  overflow: 'auto',
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: 12,
    fontFamily: Poppins[500],
    lineHeight: '14px',
    backgroundColor: '#FFFFFF',
    padding: '0 10px',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(20px, -5px) scale(0.75)',
    },

    '& + .Mui-expanded': {
      color: 'red',
    },
  },

  '& .MuiTypography-root': {
    fontFamily: Poppins[500],
    fontSize: 13,
    opacity: 0.4,
    lineHeight: '30px',
  },

  '& .MuiOutlinedInput-root': {
    maxHeight: 48,
  },

  '& .MuiAutocomplete-root': {
    '& .MuiOutlinedInput-root': {
      paddingLeft: '16px',
    },
    '& .MuiAutocomplete-input': {
      fontFamily: Poppins[500],
      fontSize: 13,
      lineHeight: '30px',
      height: 28,
    },
    '& .MuiAutocomplete-inputRoot': {
      height: 48,
    },

    '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: '0',
    },
  },

  '& .MuiOutlinedInput-input': {
    padding: '10px 16px 10px 0',
    fontFamily: Poppins[500],
    fontSize: 13,
    lineHeight: '30px',
    height: 28,
  },

  '& .MuiSelect-select': {
    fontSize: 13,
    padding: '10px 16px',
    lineHeight: '30px',
    height: 28,
  },

  '& .MuiChip-root': {
    fontSize: 12,
    height: 28,
    fontFamily: Poppins[600],
    padding: '5px 10px',
    borderRadius: 99999,
    backgroundColor: '#F2F3F7',

    '& .MuiChip-label': {
      padding: 0,
      color: '#667085',
    },
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  fontSize: 12,
}));
