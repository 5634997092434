import React, { FC } from 'react';

import { parsePhoneNumber } from 'react-phone-number-input';

import { MoreVert } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';

import { AppVersionObject, ConEdWorkerForTable } from 'types/Common/User';

import { JobType } from 'Constants/job';
import { Poppins } from 'Utils/Fonts';
import { convertWorkerAppVersionsToString } from 'Utils/PrepareData';
import app_history from 'app_history';

import WorkerUserStatusSelect from './WorkerUserStatusSelect';
import { StyledChip } from './styled';

type Props = {
  worker: ConEdWorkerForTable;
  onChangeWorkerStatus: (worker: ConEdWorkerForTable, status: string) => void;
};

const WorkerDetailsRow = ({ title, value }: { title: string; value: string | React.ReactNode }) =>
  value && (
    <Box
      display="flex"
      gap={1}
      flexWrap="wrap"
      px="20px"
      py="10px"
      justifyContent={'space-between'}
      borderTop={'1px solid rgb(238, 238, 238)'}
      alignItems={'center'}
      fontFamily={Poppins[500]}
      fontSize={12}
    >
      <Typography fontSize="inherit" fontFamily="inherit">
        {title}
      </Typography>
      <Box>{value}</Box>
    </Box>
  );

const WorkersTableMobileCard: FC<Props> = ({ worker, onChangeWorkerStatus }) => {
  return (
    <Stack key={worker.id} borderRadius={'10px'} bgcolor={'white'} pt={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 20px 16px 20px">
        <WorkerUserStatusSelect
          size="small"
          value={worker.status}
          onChange={(e) => {
            onChangeWorkerStatus(worker, e.target.value);
          }}
          onOpen={(e) => {
            e.stopPropagation();
          }}
          onClose={(e) => {
            e.stopPropagation();
          }}
        />

        <IconButton
          sx={{
            width: '30px',
            height: '30px',
            bgcolor: 'secondary.light',
            borderRadius: '10px',
          }}
          onClick={() => {
            app_history.push(`/workers/${worker.id}`);
          }}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <WorkerDetailsRow title="ID" value={worker.id} />
      <WorkerDetailsRow title="Name" value={worker.name} />
      <WorkerDetailsRow
        title="Phone Number"
        value={parsePhoneNumber(worker.phone_number)?.formatInternational() || worker.phone_number}
      />
      <WorkerDetailsRow title="Email" value={worker.email} />
      <WorkerDetailsRow
        title="Subcontractor"
        value={worker.subcontractorName && <StyledChip label={worker.subcontractorName} />}
      />
      <WorkerDetailsRow
        title="Worker Types"
        value={
          <Box gap={1} display="flex" flexWrap="wrap">
            {worker.workerTypes.map((type) => (
              <StyledChip key={type} label={JobType[type] || 'Unavailable'} />
            ))}
          </Box>
        }
      />
      <WorkerDetailsRow
        title="App Version"
        value={convertWorkerAppVersionsToString(worker.app_versions as unknown as AppVersionObject[]) || 'Unavailable'}
      />
    </Stack>
  );
};

export default WorkersTableMobileCard;
