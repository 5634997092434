import picture from './picture';

type UploadMultipleParams = {
  /**  Instances of FileWorker */
  files: FileWorker[];
  /** File name in form data(like 'images_', 'pdfs_' etc.) */
  fileKey: string;
  /** API callback which return array of file urls. If it doesn't - use responseParser */
  uploadCallback: (files: FormData) => Promise<unknown>;
  /** A Callback which returns array of file urls from response body. By default returns response. Example usage for axios: (response) => response.data */
  responseParser?: (response: any) => string[];
};

type FileWorkerFile = File | string | { url: string; created_at?: string };

class FileWorker {
  #originalFile: FileWorkerFile;
  #isFileLink = false;
  #isFile = false;
  #isURL = false;
  #isSignedFile = false;
  #fileUrl = '';
  #isPDF = false;
  constructor(file: FileWorkerFile) {
    this.#originalFile = file;
    if (file instanceof File) {
      this.#isFile = true;
      this.#fileUrl = URL.createObjectURL(file);
      return;
    }
    if (typeof file === 'string') {
      if (!file.includes('blob') && file.includes('http')) {
        this.#isURL = true;
      }
      this.#fileUrl = file;
      return;
    }
    if (typeof file?.url === 'string' && file?.created_at) {
      this.#isSignedFile = true;
      this.#fileUrl = file?.url;
    }
    if (typeof file?.url === 'string' && file?.url.includes('blob')) {
      this.#fileUrl = file?.url;
      this.#isFileLink = true;
      this.#isPDF = 'upload_type' in file && file?.upload_type === 'application/pdf';
    }
    if (typeof file?.url === 'string' && !file?.url.includes('blob')) {
      this.#fileUrl = file?.url;
    }
  }

  get url() {
    return this.#fileUrl;
  }

  get uploaded() {
    if ((this.#isSignedFile || this.#isURL) && !this.#isFile) {
      return true;
    }
    return false;
  }

  get isUploadedAsPDF() {
    return this.#isPDF || picture.removeDate(this.url).endsWith('.pdf');
  }

  get data() {
    return this.#originalFile;
  }
  /**
   *
   * @param params.files Instances of FileWorker
   * @param params.fileKey File name in form data(like 'images_', 'pdfs_' etc.)
   * @param params.uploadCallback API callback which return array of file urls. If it doesn't - use responseParser
   * @param params.[responseParser] A Callback which returns array of file urls from response body. By default returns response. Example usage for axios: (response) => response.data
   */
  static uploadMultiple = async ({
    files = [],
    fileKey = 'images_',
    uploadCallback,
    responseParser = (res) => res,
  }: UploadMultipleParams) => {
    if (!uploadCallback) {
      return [];
    }
    const body = new FormData();
    files.forEach((file, i) => {
      if (file.uploaded) return;
      if (file.data instanceof File) {
        body.append(fileKey + i, file.data);
      }
    });
    if (Array.from(body.values()).length === 0) return [];
    const response = await uploadCallback(body);
    return responseParser(response);
  };

  static getUploadedURLs(files: FileWorker[] = []): string[] {
    return files.reduce((acc, file) => (file.uploaded ? [...acc, file.url] : acc), []);
  }

  get isFile() {
    return this.#isFile;
  }

  get isUrl() {
    return this.#isURL;
  }

  get isSignedFile() {
    return this.#isSignedFile;
  }

  get isFileLink() {
    return this.#isFileLink;
  }
}

export default FileWorker;
