import { AppThunkAction } from 'types';
import { CESWorker } from 'types/Common/User';

import { UsersAPI, WorkersAPI } from 'Services/API';

import {
  GET_WORKERS_ERROR,
  GET_WORKERS_REQUEST,
  GET_WORKERS_SUCCESS,
  UPDATE_FILTER_OPTIONS,
  UPDATE_PAGINATION,
  UPDATE_WORKER_ERROR,
  UPDATE_WORKER_REQUEST,
  UPDATE_WORKER_STATUS_ERROR,
  UPDATE_WORKER_STATUS_REQUEST,
  UPDATE_WORKER_STATUS_SUCCESS,
  UPDATE_WORKER_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
} from './actionTypes';

export function retrieve(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_WORKERS_REQUEST });
      const response = await WorkersAPI.getAll();
      dispatch({ type: GET_WORKERS_SUCCESS, workers: response.results });
    } catch (error) {
      dispatch({ type: GET_WORKERS_ERROR });
    }
  };
}

export function updateFilters(search_options): any {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_FILTER_OPTIONS,
      filters: search_options,
    });
  };
}

export function updatePagination(pagination): any {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_PAGINATION,
      pagination,
    });
  };
}

export function updateWorkerStatus(worker_id, data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_WORKER_STATUS_REQUEST });
      const response = await WorkersAPI.update(worker_id, data);
      dispatch({
        type: UPDATE_WORKER_STATUS_SUCCESS,
        worker: response.worker,
      });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_WORKER_STATUS_ERROR });
      throw error;
    }
  };
}

export function updateWorker(id, data): AppThunkAction<Promise<{ status_code: number; worker: CESWorker }>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_WORKER_REQUEST });
      const response = await WorkersAPI.update(id, data);
      dispatch({
        type: UPDATE_WORKER_SUCCESS,
        worker: response.worker,
      });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_WORKER_ERROR });
      throw error;
    }
  };
}

// works
export function uploadImages(images): AppThunkAction<Promise<{ avatar_url: string; status_code: number }>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPLOAD_IMAGE_REQUEST });
      const response = await UsersAPI.uploadAvatar(images);
      dispatch({ type: UPLOAD_IMAGE_SUCCESS, img_data: response });
      return response;
    } catch (error) {
      dispatch({ type: UPLOAD_IMAGE_ERROR });
      throw error;
    }
  };
}
