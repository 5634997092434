import { ImageWithAuthor } from 'types/Location';

export class ImageWithAuthorHelper implements ImageWithAuthor {
  url: string = '';
  created_at: string = '';
  lat: number = 0;
  lon: number = 0;
  is_rejected: number = 0;
  user?: { id: number; name: string } = null;
  file_type: { id: number; name: string; description: string } = { id: 0, name: '', description: '' };
  title: string = '';
  upload_type: string = '';

  constructor(image: ImageWithAuthor | string | { url: string } | File) {
    if (typeof image === 'string') {
      this.url = image;
      return;
    }

    if (image instanceof File) {
      this.url = URL.createObjectURL(image);
      this.upload_type = image.type;
      return;
    }

    for (const key of Object.keys(image)) {
      this[key] = image[key];
    }
  }

  toObject = (): ImageWithAuthor => {
    return {
      url: this.url,
      created_at: this.created_at,
      lat: this.lat,
      lon: this.lon,
      user: this.user,
      is_rejected: this.is_rejected,
      file_type: this.file_type,
      title: this.title,
      upload_type: this.upload_type,
    };
  };
}
