import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { Stack, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { JobShift, JobShiftForReroute } from 'types/Common/JobData';
import { Job } from 'types/Job';

import { ReleaseReason } from 'Constants/job';
import { UserRoles } from 'Constants/user';
import { WorkerStatus, getWorkerNextStatus } from 'Constants/worker';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { getWorketStatusString } from 'Utils/worker';
import AppPaperModal from 'components/AppPaperModal';

import { ReleaseWorkersReasonSelect, ReleaseWorkersReasonValidation } from '../components/ReleaseWorkersReasonSelect';

type Props = {
  jobId: string | number;
  selectedWorkers: JobShiftForReroute[];
  open: boolean;
  onClose: () => void;
  jobDetail: Job;
  onChangeWorkers: (workers: JobShiftForReroute[]) => void;
  onChangeWorkerStatusUpdateId: (id: number) => void;
  onUpdated: () => void;
};

type WorkerUpdate = {
  workerId: number;
  status: number;
  shiftId: number;
};

const UpdateWorkersStatusesModal: FC<Props> = ({
  jobId,
  open,
  selectedWorkers,
  onClose,
  jobDetail,
  onChangeWorkers,
  onChangeWorkerStatusUpdateId,
  onUpdated,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const updateJobWorkers = (jobId: string | number, worker: WorkerUpdate) => {
    return dispatch(actions.JobsActions.updateJobWorkers(jobId, worker));
  };

  const getWorkerStatusName = useCallback(
    (status: string | number) => getWorketStatusString(status, jobDetail.jobType),
    [jobDetail?.jobType]
  );

  const isConedUser = UserRoles.has.ConEdRole;

  const workersToUpdate = useMemo(
    () => selectedWorkers?.filter(({ status }) => status !== WorkerStatus.Completed),
    [selectedWorkers]
  );
  const isReleaseSelectionRequired = useMemo(
    () => !isConedUser && workersToUpdate.some((worker) => getWorkerNextStatus(worker.status) === WorkerStatus.Review),
    [isConedUser, workersToUpdate]
  );

  async function updateStatusByQueue(workersQueueList: JobShift[], release_reason?: ReleaseReason) {
    const list = [...workersQueueList];
    const firstWorker = list[0];
    onChangeWorkerStatusUpdateId(firstWorker.id);
    const isReleaseReasonRequired = getWorkerNextStatus(firstWorker.status) === WorkerStatus.Review;

    try {
      await updateJobWorkers(jobId, {
        workerId: firstWorker.id,
        status: getWorkerNextStatus(firstWorker.status),
        shiftId: firstWorker.shift_id,
        ...(isReleaseReasonRequired && release_reason && { release_reason }),
      });
      list.shift();
      if (list.length) {
        await updateStatusByQueue(list, release_reason);
        onChangeWorkers([...list]);
        return;
      }
      onChangeWorkerStatusUpdateId(0);
      onChangeWorkers([]);
    } catch (error) {
      showErrorMessage(error);
      list.shift();
      if (list.length) {
        await updateStatusByQueue(list, release_reason);
        onChangeWorkers([...list]);
        return;
      }
      onChangeWorkerStatusUpdateId(0);
      onChangeWorkers([]);
    }
  }

  const updateWorkersStatuses = async (releaseReason = null as ReleaseReason) => {
    if (workersToUpdate) {
      const selectedWorkersQueueList: JobShift[] = jobDetail.workers.filter((worker) =>
        workersToUpdate.some((selectedWorker) => selectedWorker.shift_id === worker.shift_id)
      );

      await updateStatusByQueue(selectedWorkersQueueList, releaseReason);
      onUpdated();
    }
    onClose();
  };

  const {
    values: { release_reason },
    errors: { release_reason: releaseReasonError },
    touched,
    setFieldValue,
    isSubmitting,
    submitForm,
  } = useFormik<{
    release_reason: ReleaseReason;
  }>({
    initialValues: {
      release_reason: null,
    },
    onSubmit: ({ release_reason }) => {
      updateWorkersStatuses(release_reason);
    },
    validationSchema: isReleaseSelectionRequired ? ReleaseWorkersReasonValidation : undefined,
  });

  const updateWorkersStatusesMessage = useMemo(() => {
    return (
      <Stack>
        {workersToUpdate?.map((worker) => (
          <p key={worker.id}>
            <b>{worker.worker.name}</b>{' '}
            <span>
              status will change from <b>{getWorkerStatusName(worker.status)}</b> to{' '}
              <b>{getWorkerStatusName(getWorkerNextStatus(worker.status))}</b>
            </span>
          </p>
        ))}
        {isReleaseSelectionRequired && (
          <>
            <Typography variant="body2" color="textSecondary">
              Some workers require a reason for release. Please select a reason.
            </Typography>
            <ReleaseWorkersReasonSelect
              value={release_reason}
              onChange={(value) => setFieldValue('release_reason', value)}
              error={touched && !!releaseReasonError}
              helperText={touched && releaseReasonError}
            />
          </>
        )}
      </Stack>
    );
  }, [
    workersToUpdate,
    getWorkerStatusName,
    isReleaseSelectionRequired,
    release_reason,
    setFieldValue,
    touched,
    releaseReasonError,
  ]);

  return (
    <AppPaperModal
      title="Update Worker Status"
      subtitle={`Do you want to change ${selectedWorkers.length > 1 ? 'workers statuses?' : 'worker status?'}`}
      modalId="update-worker-status-modal"
      onClose={onClose}
      submitButton={{
        title: 'Yes',
        loading: isSubmitting,
        onClick: submitForm,
        disabled: touched && !!releaseReasonError,
      }}
      cancelButton={{
        title: 'No',
        onClick: onClose,
      }}
      open={open}
    >
      {updateWorkersStatusesMessage}
    </AppPaperModal>
  );
};

export default UpdateWorkersStatusesModal;
