import React from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { WorkerUserStatusLabelMap } from 'Constants/worker';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { useAppDispatch } from 'createStore';

import WorkersApi from '../api/workers-api';

type Props = {
  open: boolean;
  workerId?: number;
  workerName?: string;
  newStatus?: string;
  onSubmit?: () => void;
  onClose: () => void;
};

const ChangeWorkerStatusModal = ({
  open,
  workerId = 0,
  workerName = '',
  newStatus = '',
  onClose = () => {},
}: Props) => {
  const [updateWorker, { isLoading }] = WorkersApi.useUpdateWorkerMutation();

  const changeStatus = async () => {
    if (!workerId) return;
    try {
      await updateWorker({ id: workerId, data: { status: newStatus } }).unwrap();
      showSuccessMessage('Worker status changed successfully');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      onClose();
    }
  };

  const newStatusLabel = WorkerUserStatusLabelMap[newStatus] || newStatus;

  if (!workerId) return null;

  return (
    <AppPaperModal
      open={open}
      onClose={onClose}
      submitButton={{
        title: 'Yes',
        onClick: changeStatus,
        loading: isLoading,
      }}
      cancelButton={{
        color: 'error',
      }}
      containerStyle={{
        width: '100%',
        maxWidth: 500,
      }}
      title={'Confirm status changing'}
    >
      <Typography>
        {`Are you sure to change `}
        <b>{workerName}</b>
        {` to `} <b>{newStatusLabel}</b>?
      </Typography>
    </AppPaperModal>
  );
};

export default React.memo(ChangeWorkerStatusModal);
