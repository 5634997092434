import { StylesConfig } from 'react-select';

const paginatedSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '12px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: state.isFocused ? '0 0 0 2px #3699FF' : null,
    minHeight: '42px',
    '&:hover': {
      borderColor: state.isFocused ? '#3699FF' : 'rgba(0, 0, 0, 0.73)',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '9999px',
    color: 'white',
    padding: '0 6px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '9999px',
    color: 'hsl(0, 0%, 90%)',
    height: '100%',
    width: 'max-content',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'hsl(0, 0%, 60%)',
    '&:hover': {
      color: 'hsl(0, 0%, 90%)',
      backgroundColor: 'hsl(0, 0%, 50%)',
    },
    svg: {
      width: '14px',
      height: '14px',
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
    cursor: 'text',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.23)',
    fontWeight: 500,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
} as StylesConfig;

export default paginatedSelectStyles;
