import React, { FC } from 'react';

import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import AppPagination from 'components/AppPagination/AppPagination';

import { WorkersSearchParams } from '../constants/initialSearchParams';

type Props = {
  totalPages: number;
  totalCount: number;
};

const WorkersTablePagination: FC<Props> = ({ totalPages, totalCount }) => {
  const { isDesktop } = useAppMediaQuery();
  const { params, setSearchParam } = useSearchParams<WorkersSearchParams>();

  return (
    <AppPagination
      wrapperProps={{ p: 2, bgcolor: 'white' }}
      page={Number(params.page)}
      perPage={Number(params.per_page)}
      totalPages={totalPages}
      onChangePage={(page) => {
        if (!page) return;
        setSearchParam('page', String(page));
      }}
      withPageInput
      count={totalCount}
      onChangePerPage={(perPage) => {
        setSearchParam('per_page', String(perPage));
        setSearchParam('page', '1');
      }}
      perPageOptions={[5, 10, 25, 50, 75, 100]}
      boundaryCount={1}
      simplePagination={!isDesktop}
      nextPage={() => {
        setSearchParam('page', String(Number(params.page) + 1));
      }}
      nextPageDisabled={totalPages === Number(params.page)}
      prevPage={() => {
        setSearchParam('page', String(Number(params.page) - 1));
      }}
      prevPageDisabled={Number(params.page) === 1}
      siblingCount={1}
    />
  );
};

export default WorkersTablePagination;
