import { useMemo } from 'react';

import PageTitle from 'Containers/Components/Typography/PageTitle';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { useSearchParams } from 'Utils/hooks/useSearchParams';

import WorkersApi from '../api/workers-api';
import { WorkersSearchParams } from '../constants/initialSearchParams';
import prepareQueryParams from '../utils/prepareQueryParams';
import WorkersFilters from './WorkersFilters';
import WorkersTable from './WorkersTable';
import WorkersTableMobile from './WorkersTableMobile';
import WorkersTablePagination from './WorkersTablePagination';
import { WorkersPageContainer } from './styled';

const WorkersPage = () => {
  const { isDesktop } = useAppMediaQuery();

  const { params } = useSearchParams<WorkersSearchParams>();
  const preparedParams = useMemo(() => prepareQueryParams(params), [params]);
  const { data, isLoading } = WorkersApi.useGetWorkersQuery(preparedParams, {
    pollingInterval: 10000,
  });

  const workers = data?.workers.data || [];

  return (
    <WorkersPageContainer>
      <PageTitle hidden={!isDesktop}>Workers</PageTitle>
      <WorkersFilters />

      {isDesktop ? (
        <WorkersTable workers={workers} isLoading={isLoading} />
      ) : (
        <WorkersTableMobile workers={workers} isLoading={isLoading} />
      )}

      <WorkersTablePagination totalCount={data?.workers.total || 0} totalPages={data?.workers.last_page || 0} />
    </WorkersPageContainer>
  );
};

export default WorkersPage;
