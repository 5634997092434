import { FC } from 'react';

import mapboxgl from 'mapbox-gl';
import Map, { MapProps } from 'react-map-gl';

import { NewYorkCenter } from 'Containers/Dispatch/components/Map/MapBase';

const AppMap: FC<MapProps> = (props) => {
  return (
    <Map
      mapLib={mapboxgl}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...props.style,
      }}
      mapStyle={'mapbox://styles/mapbox/navigation-day-v1'}
      initialViewState={{
        longitude: NewYorkCenter[0],
        latitude: NewYorkCenter[1],
        zoom: 14,
      }}
      {...props}
    >
      {props.children}
    </Map>
  );
};

export default AppMap;
