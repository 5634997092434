import { useCallback, useEffect, useReducer, useState } from 'react';

import { GetWorkersRequest } from 'pages/workers/api';
import WorkersApi from 'pages/workers/api/workers-api';
import { GroupBase, MultiValueProps, OptionsOrGroups, StylesConfig } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import { Box, BoxProps, Chip, Typography } from '@mui/material';

import { ConEdWorkerForTable } from 'types/Common/User';

import { Poppins } from 'Utils/Fonts';
import { useAppDispatch } from 'createStore';

import WorkerChip from './WorkerChip';
import paginatedSelectStyles from './styled';

export type WorkerAsyncPaginatedSelectOption = {
  label: string;
  value: {
    id: number;
    name: string;
  };
};

const parseUsersOptions = (workers: ConEdWorkerForTable[]): WorkerAsyncPaginatedSelectOption[] => {
  return workers.map((worker) => ({
    label: worker.name,
    value: {
      id: worker.id,
      name: worker.name,
    },
  }));
};

type PaginateType = {
  page: number;
};

type Props = {
  value: WorkerAsyncPaginatedSelectOption[];
  onChange: (options: WorkerAsyncPaginatedSelectOption[]) => void;
  placeholder?: string;
  queryParams?: Partial<GetWorkersRequest>;
  styles?: StylesConfig;
  showSelectedFirst?: boolean;
  maxVisibleOptions?: number;
  menuPosition?: 'absolute' | 'fixed';
  single?: boolean;
  error?: boolean;
  helperText?: string;
  wrapperProps?: BoxProps;
  noOptionsMessage?: () => string;
  loadingMessage?: () => string;
};

const WorkersPaginatedAsyncSelect = ({
  value,
  onChange,
  placeholder = 'Search workers',
  queryParams,
  styles,
  showSelectedFirst = false,
  maxVisibleOptions,
  menuPosition = 'absolute',
  single = false,
  error = false,
  helperText = '',
  wrapperProps,
  noOptionsMessage = () => 'No workers found',
  loadingMessage = () => 'Loading workers...',
}: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [key, forceUpdate] = useReducer((x) => x + 1, 0);

  const isOptionSelected = useCallback(
    (option: WorkerAsyncPaginatedSelectOption, selectedOptions: WorkerAsyncPaginatedSelectOption[]) =>
      selectedOptions.some((selectedOption) => selectedOption.value.id === option.value.id),
    []
  );

  const loadOptions = useCallback(
    async (search: string, prevOptions: unknown, { page }: PaginateType) => {
      const {
        workers: { data: workers, last_page },
      } = await dispatch(
        WorkersApi.endpoints.getWorkers.initiate({
          per_page: 15,
          statuses: 'active',
          order_by: 'id',
          order_by_type: true,
          ...queryParams,
          search,
          page,
        })
      ).unwrap();
      const hasMore = last_page > page;
      const options = parseUsersOptions(workers);

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    },
    [queryParams]
  );

  const mapOptionsForMenu = useCallback(
    (options: OptionsOrGroups<WorkerAsyncPaginatedSelectOption, GroupBase<WorkerAsyncPaginatedSelectOption>>) => {
      if (!value || !showSelectedFirst) {
        return options;
      }

      if (Array.isArray(value)) {
        if (value.length === 0) {
          return options;
        }

        const valueSet = new Set(value.map((option) => option.value));

        return [
          ...value,
          ...options.filter((option) => !valueSet.has((option as WorkerAsyncPaginatedSelectOption).value)),
        ];
      }

      return [
        value,
        ...options.filter(
          (option) =>
            (option as WorkerAsyncPaginatedSelectOption).value !== (value as WorkerAsyncPaginatedSelectOption).value
        ),
      ];
    },
    [value, showSelectedFirst]
  );

  const CustomMultiValue = (props: MultiValueProps<WorkerAsyncPaginatedSelectOption>) => {
    const { data, index, selectProps } = props;
    const { value } = selectProps;

    if (open || !maxVisibleOptions) {
      return single ? (
        <>{data.label}</>
      ) : (
        <WorkerChip
          worker={data}
          onDelete={() => {
            const newValue = Array.isArray(value) ? value.filter((item) => item.value.id !== data.value.id) : [];
            onChange(newValue);
          }}
        />
      );
    }

    if (index >= maxVisibleOptions) {
      if (index === maxVisibleOptions) {
        return Array.isArray(value) ? (
          <Typography fontSize={12} fontFamily={Poppins[500]}>
            +{value.length - maxVisibleOptions} more
          </Typography>
        ) : (
          <></>
        );
      }
      return <></>;
    }

    return single ? (
      <>{data.label}</>
    ) : (
      <WorkerChip
        worker={data}
        onDelete={() => {
          const newValue = Array.isArray(value) ? value.filter((item) => item.value.id !== data.value.id) : [];
          onChange(newValue);
        }}
      />
    );
  };

  const handleChange = useCallback((newValue: WorkerAsyncPaginatedSelectOption[]) => {
    if (single) {
      onChange(newValue.slice(-1));
    } else {
      onChange(newValue);
    }
  }, []);

  useEffect(() => {
    if (queryParams?.workerTypes) {
      forceUpdate();
      onChange([]);
    }
  }, [queryParams?.workerTypes]);

  return (
    <Box {...wrapperProps}>
      <AsyncPaginate
        key={key}
        debounceTimeout={300}
        openMenuOnClick
        isOptionSelected={isOptionSelected}
        placeholder={placeholder}
        value={value}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onMenuOpen={() => {
          setOpen(true);
        }}
        onMenuClose={() => {
          setOpen(false);
        }}
        hideSelectedOptions={false}
        loadOptions={loadOptions}
        // loadOptionsOnMenuOpen={false}
        isMulti
        // closeMenuOnSelect={false}
        onChange={handleChange}
        menuPosition={menuPosition}
        additional={{
          page: 1,
        }}
        components={{
          MultiValue: CustomMultiValue,
        }}
        styles={
          {
            ...paginatedSelectStyles,
            ...styles,
            control: (base, props) => ({
              ...base,
              ...styles?.control?.(base, props),
              ...(error && {
                borderColor: 'red',
              }),
            }),
          } as StylesConfig
        }
        mapOptionsForMenu={mapOptionsForMenu}
      />
      {error && (
        <Typography variant="caption" color="error" ml={1}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default WorkersPaginatedAsyncSelect;
