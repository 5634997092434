import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';

import EditSharpIcon from '@mui/icons-material/EditSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  Chip,
  styled,
  Button,
  Box,
} from '@mui/material';

import { billingTimesheetsTableHead, SUBCONTRACTOR_CELL_STYLES, BillingStatus } from 'Constants/billing';
import { TimesheetStatus } from 'Constants/timesheet';
import { EditDateTime } from 'Containers/Components';
import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { ImageWithAuthorHelper } from 'Containers/Components/ImageViewer/helpers';
import { generateTimesheetPhotoName } from 'Containers/Timesheets/utils';
import { actions } from 'Services';
import FileWorker from 'Utils/FileWorker';
import picture from 'Utils/picture';

import DisputeModal from '../dialog/DisputeModal';

const StyledChip = styled(Chip)(() => ({
  width: 'max-content',
  color: '#fff',
  height: 26,
  padding: 0,
  fontSize: 12,
  fontWeight: 500,

  '&.MuiChip-colorSuccess': {
    backgroundColor: '#27AE60',
  },

  '&.MuiChip-colorError': {
    backgroundColor: '#EB5757',
  },

  '&.MuiChip-colorInfo': {
    backgroundColor: '#b3b3b3',
  },
}));

class BillingTimesheetsTable extends PureComponent {
  viewerRef = React.createRef();

  state = {
    page: this.props.page,
    isVerified: false,
    open: 0,
    disputeOpen: 0,
    previewImagesStartIndex: -1,
    previewImagesRowId: null,
  };

  verify = (id) => {
    const data = { isVerified: 1 };
    this.props.updateTimesheet(id, data);
  };

  openEdit = (id) => {
    this.setState({ open: id });
  };

  closeEdit = () => {
    this.setState({ open: 0 });
  };

  openDisputeDialog = (id) => {
    this.setState({ disputeOpen: id });
  };

  closeDisputeDialog = () => {
    this.setState({ disputeOpen: 0 });
  };

  updateTimesheetTime = (timesheetId) => (startDate, finishDate) => {
    const timeFormat = 'YYYY-MM-DDTHH:mm';
    this.props.updateTimesheet(timesheetId, {
      startDate: startDate.format(timeFormat),
      finishDate: finishDate.format(timeFormat),
    });
  };

  openPreviewImages = (previewImagesStartIndex = 0, rowId = 0) => {
    this.setState({ previewImagesStartIndex, previewImagesRowId: rowId });
  };

  closePreviewImages = () => {
    this.setState({ previewImagesStartIndex: -1, previewImagesRowId: null });
  };

  getDisputeTooltipTitle = ({ disputed, dispute_reason, paid, invoiced, worker_paid, status }) => {
    if (worker_paid) {
      return 'Worker already paid';
    }
    if (disputed) {
      return dispute_reason;
    }
    if (paid) {
      return 'The timesheet is Paid';
    }
    if (invoiced) {
      return 'The timesheet is Invoiced';
    }
    if (status !== 'clocked_out') {
      return 'The timesheet is not clocked out';
    }
    return '';
  };

  render() {
    const { timesheets, jobStatus, jobRequestDate } = this.props;
    const isJobInvoiced = jobStatus === BillingStatus.invoiced;
    const tableHead = isJobInvoiced
      ? billingTimesheetsTableHead
      : billingTimesheetsTableHead.filter(({ value }) => value !== 'billing.invoice_paid');

    const renderTableCellLabel = (label) => {
      if (label.length > 15) {
        return (
          <Tooltip title={label} placement="top">
            <div>{label.substr(0, 15)}...</div>
          </Tooltip>
        );
      } else {
        return label;
      }
    };

    return (
      <div className="billing-table-container" style={{ overflowY: 'auto' }}>
        <Fragment>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead style={{ background: '#F2F2F2' }}>
                  <TableRow>
                    {tableHead.map((headCell) => (
                      <TableCell key={headCell.label}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timesheets &&
                    timesheets.map((row) => {
                      const enableDisputeBtn =
                        row.status === 'clocked_out' && !row.invoiced && !row.paid && !row.worker_paid && !row.disputed;
                      const disputeTooltipTitle = this.getDisputeTooltipTitle(row);

                      return (
                        <TableRow key={row.id} className={row.disputed ? 'billing-timesheet-table-row-pending' : ''}>
                          <TableCell>{moment(row.start_at).format('MM/DD/YY [•] HH:mm')}</TableCell>
                          <TableCell>{moment(row.finish_at).format('MM/DD/YY [•] HH:mm')}</TableCell>
                          <TableCell>
                            <Link to={`/workers/${row.worker_id}`}>{row.worker_name}</Link>
                          </TableCell>
                          <TableCell style={SUBCONTRACTOR_CELL_STYLES}>
                            {row.subcontractorName && renderTableCellLabel(row.subcontractorName)}
                          </TableCell>
                          <TableCell>{row.total_billable_hours}</TableCell>
                          <TableCell>{TimesheetStatus.toUserString(row.status)}</TableCell>
                          <TableCell>
                            <StyledChip
                              label={row.verified ? 'CES Verified' : 'CES Unverified'}
                              color={row.verified ? 'success' : 'error'}
                            />
                          </TableCell>
                          {isJobInvoiced && (
                            <TableCell>
                              <StyledChip
                                label={row.invoice_paid ? 'Invoice Paid' : 'Invoice Not Paid'}
                                color={row.invoice_paid ? 'success' : 'info'}
                              />
                            </TableCell>
                          )}
                          <TableCell>{row.total_billable_amount}</TableCell>
                          <TableCell>
                            <Tooltip disableInteractive title={disputeTooltipTitle} arrow>
                              <div>
                                {row.disputed ? (
                                  <StyledChip
                                    color="error"
                                    label={
                                      <Box display="flex" alignItems="center" gap={'5px'}>
                                        <ErrorOutlineIcon fontSize={'small'} />
                                        <span>Timesheet is Pending</span>
                                      </Box>
                                    }
                                  />
                                ) : (
                                  <Button
                                    sx={{
                                      color: '#000',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                      fontSize: 12,
                                    }}
                                    onClick={() => this.openDisputeDialog(row.id)}
                                    disabled={!enableDisputeBtn}
                                  >
                                    <PauseCircleOutlineIcon />
                                    Mark as Pending
                                  </Button>
                                )}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <div className="actions-row">
                              <Tooltip
                                title={
                                  row.verified
                                    ? 'Verified'
                                    : row.status !== 'clocked_out'
                                    ? 'The timesheet is not clocked out'
                                    : 'Verify'
                                }
                                disableInteractive
                                aria-label="Verify"
                                arrow
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <div className={'open-real-timesheet'}>
                                      <button
                                        onClick={() => this.verify(row.id)}
                                        disabled={row.verified || row.status !== 'clocked_out'}
                                        style={{ display: 'contents', cursor: 'pointer' }}
                                      >
                                        <VerifiedUserIcon style={{ color: 'black' }} />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                              {row.images.length ? (
                                <Tooltip
                                  disableInteractive
                                  title={`View File${row.images.length > 1 ? 's' : ''}`}
                                  aria-label="photo"
                                  arrow
                                >
                                  <div
                                    className="img-wrapper"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.openPreviewImages(0, row.id)}
                                  >
                                    <div className={'open-real-timesheet'}>
                                      <PanoramaOutlinedIcon style={{ color: 'black' }} />
                                    </div>
                                  </div>
                                </Tooltip>
                              ) : null}
                              <Tooltip
                                disableInteractive
                                title={
                                  this.props.cancelledJob
                                    ? 'Cancelled job'
                                    : row.verified || row.invoiced || row.paid || row.worker_paid
                                    ? 'You cannot change date/time for timesheets which are verified or paid or invoiced or invoice paid'
                                    : 'Edit'
                                }
                                aria-label="edit"
                                arrow
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div className={'open-real-timesheet'}>
                                    <button
                                      onClick={() => this.openEdit(row.id)}
                                      disabled={
                                        this.props.cancelledJob ||
                                        row.verified ||
                                        row.invoiced ||
                                        row.paid ||
                                        row.worker_paid
                                      }
                                      style={{ display: 'contents', cursor: 'pointer' }}
                                    >
                                      <EditSharpIcon style={{ color: 'black' }} />
                                    </button>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                          <EditDateTime
                            subTitle="You can edit Timesheet Date & Time"
                            open={this.state.open === row.id}
                            defaultValue={{ start: row.start_at, finish: row.finish_at }}
                            onSuccess={this.updateTimesheetTime(row.id)}
                            labels={{ start: 'Start Date & Time', finish: 'Finish Date & Time' }}
                            inputType="datetime-local"
                            onClose={this.closeEdit}
                            jobRequestDate={jobRequestDate}
                          />
                          <DisputeModal
                            timesheet={row}
                            open={this.state.disputeOpen === row.id}
                            onClose={this.closeDisputeDialog}
                          />

                          <ImageViewer
                            imageIndex={
                              this.state.previewImagesRowId === row.id ? this.state.previewImagesStartIndex : -1
                            }
                            images={row.images.map((image) => new ImageWithAuthorHelper(image).toObject())}
                            onClose={this.closePreviewImages}
                            canSaveRotate={false}
                            getDownloadedImageName={({ is_rejected, created_at }) =>
                              picture.generateName(
                                [
                                  'timesheet_file',
                                  [row.job_worker_id, `shift_#${row.job_worker_id}`, ''],
                                  [created_at, moment(created_at).format('MM-DD-YYYY'), ''],
                                  [is_rejected, 'rejected', ''],
                                ],
                                '_'
                              )
                            }
                          />
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Fragment>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateTimesheet: (id, data) => dispatch(actions.TimesheetsActions.update(id, data)),
  };
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTimesheetsTable);
