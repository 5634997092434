import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { withFormik } from 'formik';
import { toast } from 'react-toastify';

import { baseToastConfig } from 'Constants/app';
import AppPermissions from 'Constants/permissions';
import { EROLES } from 'Constants/user';
import { WorkersAPI } from 'Services/API';
import { selectUserIsSubcontractor } from 'Services/app/selectors';
import { ReduxState } from 'createStore';

import { WorkerCreateValidation } from './WorkerValidation';
import WorkerForm, { CreateEditWorkerForm } from './components/WorkerForm';

type Props = ReduxProps & RouteComponentProps;

const mapStateToProps = (state: ReduxState) => ({
  canSeeSubcontractors: state.app.permissions.includes(AppPermissions.can_see_subcontractors),
  userIsSubcontractor: selectUserIsSubcontractor(state),
  user: state.app.user,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const WorkerCreate = connector(
  withFormik<Props, CreateEditWorkerForm>({
    mapPropsToValues: () => ({
      avatar: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      subcontractorId: 0,
      workerTypes: [],
      password: '',
      address_zipcode: '',
      ces_supervisor: 0,
      begin_time: '',
      end_time: '',
      two_factor_auth_enabled: 0,
      black_list_departments: [],
      black_list_department_groups: [],
    }),
    validationSchema: WorkerCreateValidation,
    handleSubmit: async (values, { props }) => {
      // add current user company id if user is subcontractor
      const inserUserSubcontractorId = !props.canSeeSubcontractors && props.user.roles.includes(EROLES.subcontractor);
      const [begin_hour, begin_minute] = values.begin_time ? values.begin_time.split(':').map(Number) : [0, 0];
      const [end_hour, end_minute] = values.end_time ? values.end_time.split(':').map(Number) : [0, 0];
      const form = {
        ...values,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        phoneNumber: values.phoneNumber.trim(),
        subcontractor: undefined,
        subcontractorId: inserUserSubcontractorId ? props.user.company_id : values.subcontractorId,
        begin_hour,
        begin_minute,
        end_hour,
        end_minute,
        begin_time: undefined,
        end_time: undefined,
      };
      await WorkersAPI.create(form)
        .then((res) => {
          props.history.push(`/workers/${res.worker.id}`);
          toast.success(' Worker created!', baseToastConfig);
        })
        .catch((error) => {
          toast.error(error.error, baseToastConfig);
        });
    },
  })(WorkerForm)
);

export default WorkerCreate;
