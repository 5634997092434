import { StylesConfig } from 'react-select';

import { Poppins } from 'Utils/Fonts';

export const workersSelectStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
    border: '1px solid #E8EDF1',
    boxShadow: '0px 4px 3.2px 0px #00000010',
    minHeight: '38px',

    '&:hover': {
      borderColor: state.isFocused ? '#2F83FF' : 'rgba(0, 0, 0, 0.73)',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F2F3F7',
    color: '#667085',
    borderRadius: '9999px',
    padding: '4px 8px',
    fontFamily: Poppins[500],
    fontSize: '12px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '9999px',
    color: 'hsl(0, 0%, 90%)',
    height: '100%',
    width: 'max-content',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#2F83FF',
    '&:hover': {
      color: 'hsl(0, 0%, 90%)',
      backgroundColor: '#2F83DD',
    },
    svg: {
      width: '14px',
      height: '14px',
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    cursor: 'text',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.2)',
    fontFamily: Poppins[500],
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
} as StylesConfig;
