import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import moment from 'moment';

import { Box, CircularProgress, LinearProgress } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { TimesheetStatus } from 'Constants/timesheet';
import { actions } from 'Services';
import FilesUpload from 'Utils/FilesUpload';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import ChangesHistory from 'components/ChangesHistory';
import { usePopoverContext } from 'context/PopoversContext';
import TimesheetFormContext from 'context/TimesheetEditContext';
import { useAppSelector } from 'createStore';

import AddCommentModal from '../Dialog/AddCommentModal/AddCommentModal';
import timesheetStyles from '../Timesheet.module.scss';
import {
  TimesheetComments,
  ConEdisonInfo,
  DateTimeInfo,
  FormSubmitButtons,
  InvoiceInfo,
  JobLocationInfo,
  Signature,
  TimesheetBillingInfo,
  TimesheetEditHeader,
  TimesheetJobInfo,
  UploadFiles,
} from '../containers';
import Signatures from '../containers/Signatures/Signatures';
import { TimesheetWithValidationProps } from '../types';
import { getIsTimesheetApproved, hasAnyChanges } from '../utils';
import styles from './TimesheetEdit.module.scss';
import { TimesheetValidationSchema } from './TimesheetEditValidation';

const TimesheetEdit = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const user = useAppSelector((state) => state.app.user);
  const timesheetLoading = useAppSelector((state) => state.timesheets.timesheetLoading);
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  const [historyPage, setHistoryPage] = useState<boolean>(false);
  const [isLockedByConEdVerified, setLockedByConEdVerified] = useState<boolean>(null);

  const isFetchingNewTimesheetData = timesheet.id !== +id;

  const userIsSubcontractor = useAppSelector((state) => state.app.userIsSubcontractor);
  const savingTimesheet = useProcessing();
  const { openPopover, closeAll } = usePopoverContext();

  const signatureRequired = useMemo(() => {
    if (userIsSubcontractor) return false;
    return !UserPermissions.has.can_do_timesheet_action && timesheet.can_be_signed;
  }, [timesheet.can_be_signed, userIsSubcontractor]);

  const historyPageToggle = () => setHistoryPage((prev) => !prev);

  const getTimesheet = () => dispatch(actions.TimesheetsActions.getTimesheet(id));

  const getUploadedFiles = async (files, fileKey) => {
    try {
      const filesUpload = new FilesUpload(files, fileKey);
      if (!filesUpload.isAllFilesUploaded) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await filesUpload.uploadNew((images) => dispatch(actions.TimesheetsActions.uploadImages(images)));
      }
      return filesUpload.allUploaded;
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const turnBackToTimesheets = () => {
    history.push('/timesheets');
  };

  const buildUpdatedTimesheet = useCallback(
    async (formValues: TimesheetWithValidationProps) => {
      const values = { ...formValues };
      delete values.initialStartDate;
      delete values.initialFinishDate;
      delete values.initialSignatureName;
      delete values.initialSign;
      delete values.signatureRequired;
      const images = values?.images?.length ? await getUploadedFiles(values.images, 'image_') : [];
      const timesheetPdfs = values?.timesheetPdfs?.length
        ? await getUploadedFiles(values.timesheetPdfs, 'timesheetPdfs-')
        : [];
      const comments = values?.comments?.length ? values.comments : null;

      const signatures = formValues.signatures.reduce(
        (acc, signature) => {
          if (!isLockedByConEdVerified) {
            acc.push(
              signature.signature_data
                ? signature
                : {
                    employee_number: null,
                    signature_data: null,
                    signature_type_id: signature.signature_type_id,
                  }
            );
            return acc;
          }
          if (signature.can_be_signed) {
            const { signee, signed_at, can_be_signed, name, ...rest } = signature;
            acc.push({
              ...rest,
              ...(!rest.signature_data && {
                signature_data: null,
                employee_number: null,
              }),
            });
          }
          return acc;
        },
        [] as {
          signature_type_id: number;
          employee_number: string;
          signature_data: string;
        }[]
      );

      return { ...values, comments, images, timesheetPdfs, signatures };
    },
    [isLockedByConEdVerified]
  );

  const saveTimesheet = async (formValues: TimesheetWithValidationProps) => {
    const isDatesChanged = !(
      moment(formValues.startDate).isSame(formValues.initialStartDate) &&
      moment(formValues.finishDate).isSame(formValues.initialFinishDate)
    );

    const isCommentRequired = signatureRequired && isDatesChanged;

    if (isCommentRequired) {
      try {
        await new Promise<void>((resolve, reject) => {
          openPopover(
            'timesheet-date-changed-comment-modal',
            <AddCommentModal
              open
              onClose={() => {
                closeAll();
                reject();
              }}
              entity_id={formValues.id}
              title="Timesheet hours changed"
              subtitle="Please add a comment to explain the changes"
              onSubmit={() => resolve()}
            />
          );
        });
      } catch (error) {
        return;
      }
    }

    try {
      if (
        !user.co_employee_id &&
        UserPermissions.has.sign_timesheet &&
        signatureRequired &&
        formValues.employeeNumber
      ) {
        await dispatch(actions.AppActions.updateUserProfile({ co_employee_id: formValues.employeeNumber }));
      }
      const updatedTimesheet = await buildUpdatedTimesheet(formValues);
      await dispatch(actions.TimesheetsActions.update(id, updatedTimesheet));
      showSuccessMessage('Timesheet successfully updated');
      turnBackToTimesheets();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const unlockFields = () => setLockedByConEdVerified(false);

  useEffect(() => {
    getTimesheet()
      .then((timesheet) => setLockedByConEdVerified(getIsTimesheetApproved(timesheet)))
      .catch(showErrorMessage);
  }, []);

  const initialValues: TimesheetWithValidationProps = useMemo(
    () => ({
      ...timesheet.asMutable({ deep: true }),
      initialStartDate: timesheet.startDate,
      initialFinishDate: timesheet.finishDate,
      initialSignatureName: timesheet.signatureName,
      initialSign: timesheet.sign,
      signatureRequired,
    }),
    [timesheet, signatureRequired]
  );

  if (!timesheet?.id || isFetchingNewTimesheetData) {
    return (
      <Box className={timesheetStyles.timesheetLoader}>
        <CircularProgress size={24} className={'circular-progress'} />
      </Box>
    );
  }

  return (
    <Box className={styles.timesheetEdit}>
      <p className={styles.title}>Timesheet</p>
      <Box className={styles.form}>
        {timesheetLoading && <LinearProgress sx={{ position: 'sticky', top: 0, zIndex: 1 }} />}

        <TimesheetEditHeader
          historyPage={historyPage}
          historyPageToggle={historyPageToggle}
          isLockedByConEdVerified={isLockedByConEdVerified}
          unlockFields={unlockFields}
        />
        {!historyPage && (
          <Formik<TimesheetWithValidationProps>
            initialValues={initialValues}
            onSubmit={(values) => savingTimesheet.promiseWrapper(saveTimesheet(values))}
            validationSchema={TimesheetValidationSchema}
            enableReinitialize
            validateOnBlur
          >
            {(formikBag) => {
              const hasChanges = hasAnyChanges(formikBag.values, timesheet);
              return (
                <TimesheetFormContext.Provider value={{ ...formikBag, isLockedByConEdVerified }}>
                  <DateTimeInfo />
                  <TimesheetJobInfo />
                  <JobLocationInfo />
                  <ConEdisonInfo />
                  <TimesheetBillingInfo />
                  <InvoiceInfo />
                  {formikBag.values.signatures.length ? (
                    <Signatures />
                  ) : (
                    formikBag.values.status === TimesheetStatus.clockedOut && <Signature />
                  )}
                  <UploadFiles />
                  <TimesheetComments />
                  <FormSubmitButtons
                    saving={savingTimesheet.inProcess}
                    saveEnabled={hasChanges}
                    onCancel={turnBackToTimesheets}
                  />
                </TimesheetFormContext.Provider>
              );
            }}
          </Formik>
        )}
        {historyPage && <ChangesHistory changesLog={timesheet?.changesLog} jobType={timesheet.job_type} />}
      </Box>
    </Box>
  );
};

export default TimesheetEdit;
