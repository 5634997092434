import { ImmutableArray } from 'seamless-immutable';

import CheckIcon from '@mui/icons-material/Check';
import PanToolIcon from '@mui/icons-material/PanTool';

import { JobShift } from 'types/Common/JobData';
import { DispatchShift } from 'types/Dispatch';

import { CheckedIcon, TimeIcon } from 'Utils/Icon';

export enum WorkerStatus {
  Assigned,
  EnRoute,
  OnLocation,
  Secure,
  Review = 5,
  CanNotSecure,
  Completed,
  CrewArrived,
}
/** Worker status string type */
export enum WStatus {
  Assigned = 'assigned',
  EnRoute = 'en_route',
  OnLocation = 'on_location',
  Secure = 'secured',
  Review = 'review',
  CannotSecure = 'cannot_secure',
  Completed = 'completed',
  CrewArrived = 'crew_arrived',
}

export const WORKERS_STATUS = [
  'assigned',
  'en_route',
  'on_location',
  'secured',
  '',
  'review',
  'cannot_secure',
  'completed',
  'crew_arrived',
];

export const TABLE_HEADER = [
  { label: 'Employee #', value: 'id', sortable: true },
  { label: 'Status', value: 'status', sortable: true },
  { label: 'Worker Name', value: 'name', sortable: true },
  { label: 'Phone Number', value: 'phoneNumber', sortable: true },
  { label: 'E-mail', value: 'email', sortable: true },
  { label: 'Worker Types', value: 'workerTypes', sortable: true },
  { label: 'Subcontractor', value: 'subcontractorName', sortable: true },
  { label: 'App Version', value: 'app_versions', sortable: true },
];

export const PER_PAGES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

export const canRerouteJobWorker = (status: WStatus | WorkerStatus | ShiftStatus | null) => {
  if (typeof status === 'number') {
    return [
      WorkerStatus.Assigned,
      WorkerStatus.EnRoute,
      WorkerStatus.OnLocation,
      WorkerStatus.Secure,
      WorkerStatus.CanNotSecure,
      WorkerStatus.CrewArrived,
    ].includes(status);
  }

  if (typeof status === 'string') {
    return [
      WStatus.Assigned,
      WStatus.EnRoute,
      WStatus.OnLocation,
      WStatus.Secure,
      WStatus.CannotSecure,
      WStatus.CrewArrived,
    ].includes(status as WStatus);
  }

  return false;
};

export const getWorkerNextStatus = (workerStatus: number | string) => {
  if (typeof workerStatus !== 'number') return 0;
  switch (workerStatus) {
    case WorkerStatus.Review:
      return WorkerStatus.Completed;
    case WorkerStatus.CanNotSecure:
      return WorkerStatus.Review;
    case WorkerStatus.CrewArrived:
      return WorkerStatus.Review;
    case WorkerStatus.Secure:
      return WorkerStatus.Review;
    default:
      return workerStatus + 1;
  }
};

export enum WorkerUserStatus {
  active = 'active',
  inactive = 'inactive',
  onhold = 'onhold',
  rejected = 'rejected',
  waiting_for_approval = 'waiting_for_approval',
}

export const WorkerUserStatusLabelMap = {
  [WorkerUserStatus.active]: 'Active',
  [WorkerUserStatus.inactive]: 'Inactive',
  [WorkerUserStatus.onhold]: 'On Hold',
  [WorkerUserStatus.rejected]: 'Rejected',
  [WorkerUserStatus.waiting_for_approval]: 'Waiting Approval',
};

export const WORKER_STATUS_SELECT = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'On Hold',
    value: 'onhold',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Waiting Approval',
    value: 'waiting_for_approval',
  },
];

export enum ShiftStatus {
  pending = 'pending',
  assigned = 'assigned',
  en_route = 'en_route',
  on_location = 'on_location',
  secured = 'secured',
  cannot_secure = 'cannot_secure',
  cancelled = 'cancelled',
  crew_arrived = 'crew_arrived',
  review = 'review',
  review_finished = 'review_finished',
}

export enum ShiftUpdateSpecialStatus {
  edit = 'edit',
  revive = 'revive',
  cancel = 'cancel',
  finished = 'finished',
  finished_without_reroute = 'finished_without_reroute',
  confirmed = 'confirmed',
  reroute = 'reroute',
  reassign = 'reassign',
  re_route = 're_route',
}

export const MAX_SHIFT_TIME = 16;

export class ShiftStatusHelper {
  static #shiftStatusMap = Object.freeze({
    [WorkerStatus.Assigned]: ShiftStatus.assigned,
    [WorkerStatus.EnRoute]: ShiftStatus.en_route,
    [WorkerStatus.OnLocation]: ShiftStatus.on_location,
    [WorkerStatus.Secure]: ShiftStatus.secured,
    [WorkerStatus.CanNotSecure]: ShiftStatus.cannot_secure,
    [WorkerStatus.CrewArrived]: ShiftStatus.crew_arrived,
    [WorkerStatus.Review]: ShiftStatus.review,
    [WorkerStatus.Completed]: ShiftStatus.review_finished,
  });

  static #namesMap = Object.freeze<Record<ShiftStatus | ShiftUpdateSpecialStatus, string>>({
    [ShiftStatus.assigned]: 'Assigned',
    [ShiftStatus.en_route]: 'En Route',
    [ShiftStatus.on_location]: 'On Location',
    [ShiftStatus.secured]: 'Secured',
    [ShiftStatus.cannot_secure]: 'Cannot Secure',
    [ShiftStatus.crew_arrived]: 'Crew Arrived',
    [ShiftStatus.review]: 'Review',
    [ShiftStatus.review_finished]: 'Review Finished',
    [ShiftStatus.pending]: 'Pending',
    [ShiftStatus.cancelled]: 'Cancelled',
    [ShiftUpdateSpecialStatus.edit]: 'Edit',
    [ShiftUpdateSpecialStatus.revive]: 'Redispatch',
    [ShiftUpdateSpecialStatus.cancel]: 'Cancel',
    [ShiftUpdateSpecialStatus.finished]: 'Continue to Next Location',
    [ShiftUpdateSpecialStatus.finished_without_reroute]: 'Clock Out',
    [ShiftUpdateSpecialStatus.confirmed]: 'Confirm',
    [ShiftUpdateSpecialStatus.reroute]: 'Reroute to Unsecured location',
    [ShiftUpdateSpecialStatus.reassign]: 'Re-Assign',
    [ShiftUpdateSpecialStatus.re_route]: 'Re-Route',
  });

  static #shiftColorsMap = Object.freeze({
    [ShiftStatus.assigned]: '#b16909',
    [ShiftStatus.en_route]: '#28A745',
    [ShiftStatus.on_location]: '#0900d0',
    [ShiftStatus.secured]: '#007112',
    [ShiftStatus.cannot_secure]: '#DC3545',
    [ShiftStatus.crew_arrived]: '#28A799',
    [ShiftStatus.review]: '#525252',
    [ShiftStatus.review_finished]: '#939393',
  });

  static get ConvertMap() {
    return this.#shiftStatusMap;
  }

  static get NamesMap() {
    return this.#namesMap;
  }

  static get ColorsMap() {
    return this.#shiftColorsMap;
  }

  #status: ShiftStatus | ShiftUpdateSpecialStatus | '' = '';
  constructor(status: ShiftStatus | ShiftUpdateSpecialStatus | WorkerStatus | string | number) {
    if (typeof status === 'number') {
      this.#status = ShiftStatusHelper.#shiftStatusMap[status];
    } else if (typeof status === 'string') {
      this.#status = status as ShiftStatus;
    }
  }

  get isPending() {
    return this.#status === ShiftStatus.pending;
  }

  get isAssigned() {
    return this.#status === ShiftStatus.assigned;
  }

  get isEnRoute() {
    return this.#status === ShiftStatus.en_route;
  }

  get isOnLocation() {
    return this.#status === ShiftStatus.on_location;
  }

  get isSecure() {
    return this.#status === ShiftStatus.secured;
  }

  get isCannotSecure() {
    return this.#status === ShiftStatus.cannot_secure;
  }

  get isCrewArrived() {
    return this.#status === ShiftStatus.crew_arrived;
  }

  get isSecured() {
    return this.#status === ShiftStatus.secured;
  }

  get isReview() {
    return this.#status === ShiftStatus.review;
  }

  get isReviewFinished() {
    return this.#status === ShiftStatus.review_finished;
  }

  get isCancelled() {
    return this.#status === ShiftStatus.cancelled;
  }

  get name(): string {
    return ShiftStatusHelper.#namesMap[this.#status] || '';
  }

  get color(): string {
    return this.#status ? ShiftStatusHelper.#shiftColorsMap[this.#status] : undefined;
  }
}

export const getIsAllShiftsCompleted = (
  shifts: Array<JobShift | DispatchShift> | ImmutableArray<JobShift | DispatchShift>
) => {
  if (!shifts?.length) return false;

  return shifts.every((shift) => {
    return ![
      ShiftStatus.assigned,
      ShiftStatus.en_route,
      ShiftStatus.on_location,
      ShiftStatus.secured,
      ShiftStatus.cannot_secure,
      ShiftStatus.crew_arrived,
      ShiftStatus.review,
    ].includes('workerStatus' in shift ? shift.workerStatus : shift.status);
  });
};
