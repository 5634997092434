import React, { FC } from 'react';

import ChangeWorkerStatusModal from 'pages/workers/ui/ChangeWorkerStatusModal';

import { Box, Stack } from '@mui/material';

import { ConEdWorkerForTable } from 'types/Common/User';

import WorkerUserStatusSelect from './WorkerUserStatusSelect';
import WorkersTableMobileCard from './WorkersTableMobileCard';

type Props = {
  workers: ConEdWorkerForTable[];
  isLoading?: boolean;
};

const WorkersTableMobile: FC<Props> = ({ workers, isLoading }) => {
  const [updateWorkerStatusData, setWorkerStatusData] = React.useState<{
    id: number;
    workerName: string;
    status: string;
  }>(null);

  return (
    <Stack flexGrow={1} overflow="auto" mt={1}>
      <Stack gap={1}>
        {workers.map((worker) => (
          <WorkersTableMobileCard
            worker={worker}
            onChangeWorkerStatus={(worker, status) =>
              setWorkerStatusData({ id: worker.id, workerName: worker.name, status })
            }
          />
        ))}
      </Stack>

      {updateWorkerStatusData && (
        <ChangeWorkerStatusModal
          open={!!updateWorkerStatusData}
          onClose={() => setWorkerStatusData(null)}
          newStatus={updateWorkerStatusData.status}
          workerName={updateWorkerStatusData.workerName}
          workerId={updateWorkerStatusData.id}
        />
      )}
    </Stack>
  );
};

export default WorkersTableMobile;
