import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConEdWorkerForTable } from 'types/Common/User';

import { JobType } from 'Constants/job';

import WorkersApi from '../api/workers-api';

export const initialState = {
  workers: [] as ConEdWorkerForTable[],
  processing: false,
  requestParams: {
    workerTypes: '',
    statuses: '',
    subcontractorId: 0,
    search: '',
  },
  pagination: {
    order_by: 'id',
    order_by_type: true,
    page: 1,
    per_page: 10,
  },
};

const slice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    updateSearchOptions: (state, { payload }: PayloadAction<(typeof initialState)['requestParams']>) => {
      state.requestParams = {
        ...state.requestParams,
        ...payload,
      };
    },
  },
});

export const WorkersActions = slice.actions;
export const WorkersReducer = slice.reducer;
