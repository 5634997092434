/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';

import some from 'lodash/some';

import { Menu } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { DatasetTestIDs } from 'Constants/tests';
import DispatchDisplayConfig from 'Containers/Dispatch/components/DispatchDisplayConfig/DispatchDisplayConfig';
import { actions } from 'Services';
import useResize from 'Utils/hooks/useResize';
import Logo from 'components/Logo/Logo';
import { useAppSelector } from 'createStore';

import styles from './MenuCE.module.scss';
import ProfileMenus from './ProfileMenus/ProfileMenus';
import { hideMenuWidth } from './constants';

interface Props {}

const MenuCE: React.FC<Props> = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  // eslint-disable-next-line
  const [haveUnreadNotifications, setHaveUnreadNotifications] = useState(false);

  const permissions = useAppSelector((state) => state.app.permissions);
  const user = useAppSelector((state) => state.app.user);
  const notifications = useAppSelector((state) => state.app.notifications);

  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = useCallback(() => setShowMenu(false), []);

  const retrieveLocations = () => dispatch(actions.JobsActions.retrieveLocations());
  const retrieveWorkers = () => dispatch(actions.WorkersActions.retrieve());

  const canSeePage = useAppSelector((state) => state.userPermissions.canSeePage);

  const canSeeDispatchPage = canSeePage.dispatch;
  const canSeeWorkersPage = canSeePage.workers;
  const canSeeSubcontractorsPage = canSeePage.subcontractors;
  const canSeeSchedulingPage = canSeePage.scheduling;
  const canSeeBillingPage = canSeePage.billing;
  const canSeeInvocingPage = canSeePage.invoicing;
  const canSeeReportsPage = canSeePage.reports;

  const screenIsLow = document.body.clientWidth <= hideMenuWidth;
  const isLowWidth = useAppSelector((state) => state.app.dispatchPageConfig.smallDisplay);
  const changeDisplaySize = useCallback(
    (smallDisplay: boolean) => dispatch(actions.AppActions.changeDispatchPageConfig({ smallDisplay })),
    []
  );

  useResize(({ target }, stored) => {
    const { innerWidth = 0 } = target;
    if (innerWidth <= hideMenuWidth && !stored.current) {
      changeDisplaySize(true);
      stored.current = true;
      return;
    }

    if (innerWidth > hideMenuWidth && stored.current) {
      changeDisplaySize(false);
      stored.current = false;
      return;
    }
  }, screenIsLow);

  const updateDispatchData = () => {
    setShowMenu(false);
    retrieveLocations();
  };

  const checkUnreadNotification = () => {
    setHaveUnreadNotifications(
      some(notifications, {
        isRead: false,
      })
    );
  };

  useEffect(() => {
    checkUnreadNotification();
  }, [notifications]);

  const MenuNavLink = useCallback(
    (props: NavLinkProps) => <NavLinkButton onClick={() => setShowMenu(false)} {...props} />,
    []
  );

  const PageLinks = useMemo(
    () => (
      <>
        {canSeeDispatchPage && (
          <MenuNavLink id="dispatch" to="/dispatch" onClick={updateDispatchData}>
            Dispatch
          </MenuNavLink>
        )}
        {canSeeSchedulingPage && (
          <MenuNavLink id="scheduling" to="/scheduling">
            Scheduling
          </MenuNavLink>
        )}
        <MenuNavLink id="map" to="/map">
          Map
        </MenuNavLink>
        <MenuNavLink id="job" to="/job">
          Job List
        </MenuNavLink>
        <MenuNavLink id="job-grid" to="/job-grid">
          Job Grid
        </MenuNavLink>
        {canSeeWorkersPage && (
          <MenuNavLink id="workers" to="/workers" {...DatasetTestIDs.navBar.workers}>
            Workers
          </MenuNavLink>
        )}
        {canSeeSubcontractorsPage && (
          <MenuNavLink id="subcontractors" to="/subcontractors" {...DatasetTestIDs.navBar.subcontractors}>
            <span>Subcontractors</span>
          </MenuNavLink>
        )}
        <MenuNavLink id="timesheets" to="/timesheets">
          Timesheets
        </MenuNavLink>
        {canSeeBillingPage && (
          <MenuNavLink id="billing" to="/billing">
            Billing
          </MenuNavLink>
        )}
        {canSeeInvocingPage && (
          <MenuNavLink id="invoices" to="/invoices">
            Invoicing
          </MenuNavLink>
        )}
        {canSeeReportsPage && (
          <MenuNavLink id="reports" to="/reports">
            Reports
          </MenuNavLink>
        )}
      </>
    ),
    [permissions]
  );

  if (!user.id) return null;

  return (
    <header className={styles.app_header}>
      {isLowWidth ? (
        <IconButton onClick={() => setShowMenu(true)}>
          <Menu />
        </IconButton>
      ) : null}
      <Logo />

      {isLowWidth ? (
        <Drawer key={isLowWidth?.toString()} open={showMenu} onClose={closeMenu}>
          <div style={{ padding: 10 }}>
            <ProfileMenus onCloseProfile={closeMenu} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>{PageLinks}</div>
          </div>
        </Drawer>
      ) : (
        <div className={styles.menu_router}>{PageLinks}</div>
      )}

      {isLowWidth ? null : (
        <div className={styles.header_action}>
          <ProfileMenus onCloseProfile={closeMenu} />
        </div>
      )}
      {isLowWidth ? <DispatchDisplayConfig /> : null}
    </header>
  );
};

export default memo(MenuCE);

const NavLinkButton = ({ to = '/', ...props }: NavLinkProps) => (
  <NavLink
    activeClassName={styles.menu_link_active}
    to={to}
    {...props}
    className={[styles.menu_link, props.className].join(' ')}
  />
);
