import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BBox, FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { RiPinDistanceFill } from 'react-icons/ri';
import { Layer, LngLatBounds, Source, useMap } from 'react-map-gl';
import Supercluster from 'supercluster';

import { Box, CircularProgress, IconButton, Tooltip, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { setGeoJsonDataType, WorkerHistoryItem, WorkerPointData } from 'Containers/Dispatch/dialog/WorkerGPSPlayback';
import s from 'Containers/Dispatch/dialog/WorkerGPSPlayback/AllWorkerPoints/AllWorkerPoints.module.scss';
import MapPlayer from 'Containers/Dispatch/dialog/WorkerGPSPlayback/MapPlayer';
import { createWorkerPoints } from 'Containers/Dispatch/dialog/WorkerGPSPlayback/utils';
import { WorkersAPI } from 'Services/API';
import { GetWorkerGPSHistoryParams } from 'Services/API/WorkersAPI.service';
import { CalendarIcon } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import AppInputField from 'components/AppInputField/AppInputField';
import AppMap from 'components/AppMap/AppMap';

type Props = {
  workerID: string | number;
};

const clusterRadius = 50;
const clusterMaxZoom = 16;
const GetWorkerGPSHistoryRequestDateFormat = 'Y-MM-DD';

const calculateClusterRadius = (count: number) =>
  count > 1000 ? 100 : count > 500 ? 60 : count > 200 ? 30 : count > 100 ? 28 : 16;

const WorkerGPSHistory: FC<Props> = ({ workerID }) => {
  const { palette } = useTheme();
  const popup = useRef(new mapboxgl.Popup()).current;
  const { gpsHistoryMap } = useMap();
  const [gpsHistory, setGPSHistory] = useState<WorkerHistoryItem[]>([]);
  const [workerPoints, setWorkerPoints] = useState<WorkerPointData[]>([]);
  const [params, setParams] = useState<GetWorkerGPSHistoryParams>({
    start_at: moment().format(GetWorkerGPSHistoryRequestDateFormat),
    finish_at: moment().format(GetWorkerGPSHistoryRequestDateFormat),
  });
  const [showAllWorkerPoints, setShowAllWorkerPoints] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const { inProcess, promiseWrapper } = useProcessing();
  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [zoom, setZoom] = useState(14);
  const [bounds, setBounds] = useState<LngLatBounds>(null);

  const fetchGPSHistory = async (id: string | number, queryParams: GetWorkerGPSHistoryParams) => {
    try {
      const { gps_history } = await promiseWrapper(WorkersAPI.getWorkerGPSHistory(id, queryParams));
      setGPSHistory(gps_history);
      setWorkerPoints(createWorkerPoints(gps_history));
    } catch (error) {
      setGPSHistory([]);
      showErrorMessage(error);
    }
  };

  const setGeoJsonData = useCallback<setGeoJsonDataType>(
    (source = '', data) => {
      const mapSource = map?.getSource(source);
      if (mapSource) {
        mapSource.type === 'geojson' && mapSource.setData(data as Parameters<mapboxgl.GeoJSONSource['setData']>[0]);
      } else {
        map?.addSource(source, { type: 'geojson', data });
      }
    },
    [map]
  );

  const workerPointsIDs = useMemo(() => {
    return workerPoints.map((point, index) => index + '-point-layer');
  }, [workerPoints]);

  const workerGeoJsonFeatures = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: workerPoints.map((point, index) => ({
        type: 'Feature' as const,
        id: index + '-point',
        geometry: {
          type: 'Point' as const,
          coordinates: [point.lon, point.lat],
        },
        properties: { configs: point.configs, pointLayerId: index + '-point-layer' },
      })),
    }),
    [workerPoints]
  );

  const gpsWorkerClusters = useMemo(
    () =>
      new Supercluster({
        radius: clusterRadius,
        maxZoom: clusterMaxZoom,
      }).load(workerGeoJsonFeatures.features),
    [workerGeoJsonFeatures]
  );

  const gpsWorkerLocationClusters = useMemo(() => {
    const clusterBounds: BBox = bounds
      ? [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat]
      : [-180, -90, 180, 90];

    return gpsWorkerClusters.getClusters(clusterBounds, zoom);
  }, [gpsWorkerClusters, bounds, zoom]);

  const changeDates = (date: moment.Moment) => {
    setParams({
      start_at: date.format(GetWorkerGPSHistoryRequestDateFormat),
      finish_at: date.format(GetWorkerGPSHistoryRequestDateFormat),
    });
  };

  useEffect(() => {
    if (moment(params.start_at).isValid() && moment(params.finish_at).isValid()) {
      fetchGPSHistory(workerID, params);
    }
  }, [workerID, params]);

  useEffect(() => {
    if (gpsHistoryMap) {
      gpsHistoryMap
        .on('load', () => {
          setMapLoaded(true);
          setMap(gpsHistoryMap.getMap());
        })
        .on('zoom', () => {
          setZoom(gpsHistoryMap.getZoom());
        })
        .on('moveend', () => {
          setBounds(gpsHistoryMap.getBounds());
        });
    }
  }, [gpsHistoryMap]);

  useEffect(() => {
    return () => {
      setMap(null);
      setMapLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (showAllWorkerPoints && gpsHistory.length) {
      const allPointsBounds = new mapboxgl.LngLatBounds();

      gpsHistory.forEach(({ lon, lat }) => allPointsBounds.extend([lon, lat]));

      map?.fitBounds(allPointsBounds, { padding: 20, speed: 6 });
    }
  }, [showAllWorkerPoints, gpsHistory]);

  return (
    <Box flex={1} position="relative" maxHeight={1484} overflow="hidden" sx={{ borderRadius: '10px' }}>
      <AppMap
        key={workerID + '_gps_history'}
        interactiveLayerIds={workerPointsIDs}
        id="gpsHistoryMap"
        style={{ flex: 1 }}
        onClick={(e) => {
          popup.remove();
          if (!e.features[0]) return;

          const properties: { datetime: string; index: number }[] = JSON.parse(
            e.features[0]?.properties?.configs as string
          );

          if (!properties) return;

          popup
            // @ts-ignore
            .setLngLat(e.features[0]?.geometry?.coordinates.slice())
            .setHTML(
              `<div class="${s.popup}">
              ${properties
                .map(({ datetime, index }) => `<p key='${datetime + index}'>${index}: ${datetime}</p>`)
                .join('')}
            </div>`
            )
            .addTo(gpsHistoryMap.getMap());
        }}
      >
        {showAllWorkerPoints &&
          gpsWorkerLocationClusters.map((workerPointCluster) => {
            const isCluster = workerPointCluster.properties.cluster;
            const sourceID = String(workerPointCluster.id);
            const workerPointLayerID = workerPointCluster.properties.pointLayerId;

            if (isCluster) {
              return (
                <Source
                  key={sourceID + '_source'}
                  id={sourceID}
                  type="geojson"
                  data={workerPointCluster}
                  clusterMaxZoom={clusterMaxZoom}
                  clusterRadius={clusterRadius}
                >
                  <Layer
                    id={sourceID + '_layer'}
                    key={sourceID + '_layer'}
                    type="circle"
                    source={sourceID}
                    layout={{
                      visibility: 'visible',
                      'circle-sort-key': workerPointCluster.properties.point_count_abbreviated,
                    }}
                    paint={{
                      'circle-radius': calculateClusterRadius(workerPointCluster.properties.point_count_abbreviated),
                      'circle-color': palette.primary.main,
                      'circle-stroke-color': '#fff',
                      'circle-stroke-width': 2,
                    }}
                  />
                  <Layer
                    id={sourceID + '_text'}
                    type="symbol"
                    source={sourceID}
                    layout={{
                      'text-field': String(workerPointCluster.properties.point_count_abbreviated),
                      'text-size': 16,
                      'text-anchor': 'center',
                    }}
                    paint={{
                      'text-color': '#fff',
                    }}
                  />
                </Source>
              );
            }

            return (
              <Source key={sourceID} id={sourceID} type="geojson" data={workerPointCluster}>
                <Layer
                  id={workerPointLayerID}
                  key={sourceID + '_layer'}
                  type="circle"
                  source={sourceID}
                  paint={{
                    'circle-radius': 16,
                    'circle-color': palette.primary.main,
                    'circle-stroke-color': '#fff',
                    'circle-stroke-width': 2,
                  }}
                  interactive
                />
              </Source>
            );
          })}
      </AppMap>

      {gpsHistory && map && mapLoaded && (
        <MapPlayer
          workerHistory={gpsHistory}
          setGeoJsonData={setGeoJsonData}
          mapLoaded={mapLoaded}
          map={map}
          onMove={(lat, lon) => {
            map.flyTo({
              center: [lon, lat],
              zoom: 16,
              speed: 5,
            });
          }}
        />
      )}

      {inProcess && (
        <Box
          display="flex"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.5)"
          sx={{ backdropFilter: 'blur(10px)', zIndex: 10 }}
        >
          <CircularProgress />
        </Box>
      )}

      <DatePicker
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 11,
        }}
        slots={{
          openPickerIcon: CalendarIcon,
          textField: AppInputField,
        }}
        slotProps={{
          inputAdornment: {
            position: 'start',
            sx: {
              ml: 2,
              mr: 0,
            },
          },
          textField: {
            size: 'small',
          },
        }}
        format="MM/DD/YYYY"
        value={moment(params.start_at)}
        onChange={changeDates}
      />

      <Box position="absolute" bottom={80} right={20}>
        <Tooltip title="Show all worker points" disableInteractive>
          <IconButton
            sx={{
              color: showAllWorkerPoints ? 'primary.main' : 'white',
              bgcolor: '#000a',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#000b',
              },
            }}
            onClick={() => setShowAllWorkerPoints((prev) => !prev)}
          >
            <RiPinDistanceFill />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default WorkerGPSHistory;
