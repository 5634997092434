import React, { FC } from 'react';

import { Select, MenuItem, ListItemIcon, Icon, SelectProps } from '@mui/material';

import { ConEdWorkerForTable } from 'types/Common/User';

import { WORKER_STATUS_SELECT, WorkerUserStatus } from 'Constants/worker';

import { WORKERS_STATUS_COLORS_MAP } from '../constants';

const WorkerUserStatusSelect: FC<SelectProps<string>> = (props) => {
  return (
    <Select
      sx={{
        height: 30,
        fontSize: 13,
        color: WORKERS_STATUS_COLORS_MAP[props.value]?.color,

        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
          borderColor: WORKERS_STATUS_COLORS_MAP[props.value]?.color,
        },
        '& .MuiListItemIcon-root': {
          minWidth: 0,
          width: 14,
          aspectRatio: '1',
          color: WORKERS_STATUS_COLORS_MAP[props.value]?.color,
        },
        '& .MuiSvgIcon-root': {
          color: WORKERS_STATUS_COLORS_MAP[props.value]?.color,
        },
        '& .MuiListItemIcon-root .MuiSvgIcon-root': {
          width: 14,
          height: 14,
        },
      }}
      {...props}
    >
      {WORKER_STATUS_SELECT.map(({ value, label }) => (
        <MenuItem
          disabled={value === WorkerUserStatus.waiting_for_approval}
          key={value}
          value={value}
          sx={{
            color: WORKERS_STATUS_COLORS_MAP[value]?.color,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: 13,
            '& .MuiListItemIcon-root': {
              minWidth: 14,
              width: 14,
              aspectRatio: '1',
            },
            '& .MuiListItemIcon-root .MuiSvgIcon-root': {
              color: WORKERS_STATUS_COLORS_MAP[value]?.color,
              width: 14,
              height: 14,
            },
          }}
        >
          <ListItemIcon>
            <Icon component={WORKERS_STATUS_COLORS_MAP[value]?.icon} />
          </ListItemIcon>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default WorkerUserStatusSelect;
