import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { Box, Chip, Divider, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { JobShift } from 'types/Common/JobData';
import { DispatchShift } from 'types/Dispatch';

import { ReleaseReason } from 'Constants/job';
import { UserRoles } from 'Constants/user';
import { ShiftStatus, WorkerStatus } from 'Constants/worker';
import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import {
  ReleaseWorkersReasonSelect,
  ReleaseWorkersReasonValidation,
} from 'Containers/Job/components/ReleaseWorkersReasonSelect';
import { actions } from 'Services';
import FilesUpload from 'Utils/FilesUpload';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppInputField from 'components/AppInputField/AppInputField';
import AppPaperModal from 'components/AppPaperModal';

import styles from './ReleaseSelectedWorkers.module.scss';

export type ReleaseWorkerFormikValues = {
  release_reason: ReleaseReason;
  comment: string;
  timesheet_pdfs: string[];
};

type Props = {
  jobId: number | string;
  open: boolean;
  onClose: () => void;
  selectedWorkers: Array<JobShift | DispatchShift>;
  unselectWorker?: (worker: JobShift | DispatchShift) => void;
  onSuccess: () => void;
};

const ReleaseSelectedWorkersDialog = ({
  jobId,
  open,
  onClose,
  selectedWorkers,
  onSuccess,
  unselectWorker = () => {},
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const isConedUser = UserRoles.has.ConEdRole;
  const { inProcess: loading, promiseWrapper } = useProcessing();

  const canBeReleasedWorkers = useMemo(
    () =>
      selectedWorkers.filter((worker) => {
        if (typeof worker.status === 'number') {
          return worker.status !== WorkerStatus.Review && worker.status !== WorkerStatus.Completed;
        }

        return worker.status !== ShiftStatus.review && worker.status !== ShiftStatus.review_finished;
      }),
    [selectedWorkers]
  );

  const onSubmit = async (formikValues: ReleaseWorkerFormikValues) => {
    if (!selectedWorkers.length) return;

    const values = { ...formikValues };

    const workersIds = canBeReleasedWorkers.map((worker) => ('shift_id' in worker ? worker.shift_id : worker.id));
    if (!workersIds.length) {
      const error = canBeReleasedWorkers.length > 1 ? 'These workers cant be released' : 'This worker cant be released';
      showErrorMessage(error);
      return;
    }

    if (!formikValues.timesheet_pdfs.length) {
      delete values.timesheet_pdfs;
    } else {
      const filesUpload = new FilesUpload(formikValues.timesheet_pdfs, 'timesheet_pdfs');
      if (!filesUpload.isAllFilesUploaded) {
        await filesUpload.uploadNew((files) => dispatch(actions.TimesheetsActions.uploadImages(files)));
      }
      values.timesheet_pdfs = filesUpload.allUploaded;
    }

    try {
      await dispatch(
        actions.JobsActions.releaseSelectedWorkers({
          job_id: jobId,
          job_worker_ids: workersIds,
          ...values,
        })
      );
      showSuccessMessage(workersIds.length > 1 ? 'Workers are released' : 'Worker is released');
      onSuccess();
      onClose();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const {
    values,
    errors: { release_reason: releaseReasonError },
    touched,
    handleChange,
    setFieldValue,
    submitForm,
  } = useFormik<ReleaseWorkerFormikValues>({
    initialValues: {
      comment: '',
      timesheet_pdfs: [],
      release_reason: isConedUser ? ReleaseReason.CE_WEB_RELEASE : null,
    },
    onSubmit: (values) => promiseWrapper(onSubmit(values)),
    validationSchema: !isConedUser ? ReleaseWorkersReasonValidation : undefined,
  });

  const onCloseHandler = () => {
    if (loading) return;
    onClose();
  };

  useEffect(() => {
    if (open && !canBeReleasedWorkers.length) {
      onClose();
    }
  }, [selectedWorkers.length, open]);

  return (
    <AppPaperModal
      open={open}
      title={'Release worker' + (canBeReleasedWorkers.length > 1 ? 's' : '')}
      subtitle={(selectedWorkers.length > 1 ? 'These workers' : 'This worker') + ' will be released'}
      submitButton={{
        title: 'Release worker' + (canBeReleasedWorkers.length > 1 ? 's' : ''),
        onClick: submitForm,
        loading,
        disabled: touched && !!releaseReasonError,
      }}
      cancelButton={{
        title: 'Back',
      }}
      onClose={onCloseHandler}
    >
      <div className={loading ? styles.releaseSelectedWorkers__busy : styles.releaseSelectedWorkers}>
        <div className={styles.workersList}>
          {canBeReleasedWorkers.map((worker) => (
            <Chip
              onDelete={() => unselectWorker?.(worker)}
              label={'worker' in worker ? worker.worker.name : worker.worker_name}
              key={worker.id}
            />
          ))}
        </div>
        <Divider sx={{ my: 2 }} />
        <ReleaseWorkersReasonSelect
          value={values.release_reason}
          onChange={(reason) => setFieldValue('release_reason', reason)}
          hidden={isConedUser}
          error={touched && !!releaseReasonError}
          helperText={touched && releaseReasonError}
        />
        <AppInputField
          name="comment"
          value={values.comment}
          onChange={handleChange}
          label="Comment"
          placeholder="Leave comment..."
          fullWidth
          multiline
          sx={{ marginBlock: '14px' }}
        />
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="caption">Attach PDFs</Typography>
          <ImageUpload onChangeImage={(files) => setFieldValue('timesheet_pdfs', files)} filesMode />
        </Box>
      </div>
    </AppPaperModal>
  );
};

export default ReleaseSelectedWorkersDialog;
