import React, { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ImageWithAuthor } from 'types/Location';

import ImagePreview from 'components/ImagePreview';
import { ImagePreviewProps } from 'components/ImagePreview/types';

type BaseImage = { url: string };

type ViewerContextType<ImageType extends BaseImage = BaseImage> = {
  updateProps: React.Dispatch<React.SetStateAction<ImagePreviewProps<ImageType>>>;
  updateOpenedId: React.Dispatch<React.SetStateAction<string>>;
  openedId: string;
};

const ViewerContext = createContext<ViewerContextType>({
  updateProps: () => ({}),
  updateOpenedId: () => {},
  openedId: '',
});

export type WithViewerProps = ViewerContextType;

export const useImagePreview = <ImageType extends BaseImage = BaseImage>(uniqueViewerId?: string | number) => {
  const { updateOpenedId, updateProps, openedId } = useContext<ViewerContextType<ImageType>>(ViewerContext);
  const [viewerId] = useState(uniqueViewerId?.toString() || Math.random().toString());

  const value = useMemo(
    () => ({
      updateProps,
      openedId,
      viewerId,
      updateOpenedId,
    }),
    [updateProps, openedId, viewerId]
  );

  return value;
};

export const useAuthorImagePreview = useImagePreview<ImageWithAuthor>;

export const withImagePreview = (Component: React.ComponentType) => {
  const WithImageViewer = (props: any) => {
    const context = useImagePreview();
    return <Component {...context} {...props} />;
  };
  return WithImageViewer;
};

const ImagePreviewProvider = ({ children }: PropsWithChildren) => {
  const [openedId, setOpenedId] = useState('');
  const [viewerProps, setViewerProps] = useState<ImagePreviewProps<BaseImage>>({
    images: [],
    open: false,
    imageIndex: -1,
    onChangeImage: () => {},
    onClose: () => {},
    isPDF: false,
  });

  const updateProps: ViewerContextType['updateProps'] = setViewerProps;

  const value = useMemo(
    () => ({ updateProps, openedId, updateOpenedId: setOpenedId }),
    [updateProps, openedId, setViewerProps]
  );

  return (
    <ViewerContext.Provider value={value}>
      {children}
      {viewerProps.open && <ImagePreview {...viewerProps} />}
    </ViewerContext.Provider>
  );
};

export default ImagePreviewProvider;
