import React, { useEffect, useMemo, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, AutocompleteRenderInputParams, CircularProgress } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { ResponseSubcontractorItem } from 'types/Subcontractors';

import { actions } from 'Services';
import useProcessing from 'Utils/hooks/useProcessing';
import { ReduxState, useAppDispatch } from 'createStore';

interface Props {
  onSelectSubcontractor?: (subcontractor: ResponseSubcontractorItem) => void;
  selectedId?: number;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const SubcontractorAsyncSearch = ({
  onSelectSubcontractor,
  selectedId,
  renderInput = (params) => (
    <TextField
      {...params}
      label="Select Subcontractor"
      InputProps={{
        ...params.InputProps,
        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
      }}
    />
  ),
}: Props) => {
  const dispatch = useAppDispatch();
  const subcontractors = useSelector((state: ReduxState) => state.subcontractors.subcontractors).asMutable({
    deep: true,
  });
  const { inProcess: loading, promiseWrapper } = useProcessing();

  const getSubcontractors = () => dispatch(actions.SubcontractorsActions.retrieve());

  useEffect(() => {
    promiseWrapper(getSubcontractors());
  }, []);

  const value = useMemo(() => subcontractors.find(({ id }) => id === selectedId) || null, [subcontractors, selectedId]);

  return (
    <Autocomplete<ResponseSubcontractorItem, false>
      id="subcontractor-material-search"
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      options={subcontractors}
      value={value}
      loading={loading}
      loadingText="Loading..."
      renderInput={renderInput}
      onChange={(event, value) => onSelectSubcontractor(value)}
    />
  );
};

export default SubcontractorAsyncSearch;
