import { FC, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';

import SettingsIcon from '@mui/icons-material/Settings';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, BoxProps, CircularProgress, Container, Icon, Tab, Typography } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';
import { UserRoles } from 'Constants/user';
import PageTitle from 'Containers/Components/Typography/PageTitle';
import { actions } from 'Services';
import { AppAPI, UsersAPI } from 'Services/API';
import { LockSolidBold, UserSolidBold } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { showSuccessMessage } from 'Utils/successMessage';
import { useAppDispatch, useAppSelector } from 'createStore';

import { ProfileValidation, employeeNumberValidation } from './Profile.Validation';
import ProfileForm, { ProfileFormTabs, ProfileFormValues } from './components/ProfileForm';
import { ProfileSettingsSelect, ProfileSettingsSelectMenuItem, ProfileSettingsTabs } from './components/styled';

export const ProfileFormTabsDataMap: Record<
  ProfileFormTabs,
  {
    title: string;
    icon: JSX.Element;
    hidden?: boolean;
  }
> = {
  [ProfileFormTabs.PROFILE]: {
    title: 'Profile Information',
    icon: <UserSolidBold />,
  },
  [ProfileFormTabs.PASSWORD]: {
    title: 'Password',
    icon: <LockSolidBold />,
  },
  [ProfileFormTabs.NOTIFICATIONS]: {
    title: 'Notification Settings',
    icon: <SettingsIcon />,
  },
  [ProfileFormTabs.LOGINS]: {
    title: 'Logins',
    icon: <UserSolidBold />,
    hidden: true,
  },
};

const ProfileFormTitle: FC<{ tab: ProfileFormTabs } & BoxProps> = ({ tab, ...boxProps }) => {
  return (
    <Box display="flex" alignItems="center" gap="14px" {...boxProps}>
      <Icon
        color="primary"
        sx={{
          display: 'flex !important',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
        }}
      >
        {ProfileFormTabsDataMap[tab].icon}
      </Icon>
      <Typography fontFamily="Poppins Bold" fontSize="20px">
        {ProfileFormTabsDataMap[tab].title}
      </Typography>
    </Box>
  );
};

const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.app.user);
  const notifications = useAppSelector((state) => state.app.notifications);
  const appProcessing = useAppSelector((state) => state.app.processing);
  const [activeTab, setActiveTab] = useState(ProfileFormTabs.PROFILE);
  const canEditEmployeeNumber = UserRoles.has.ConEdRole;

  const { isDesktop } = useAppMediaQuery();

  const initialValues: ProfileFormValues = {
    avatar: null,
    croppedAvatar: null,
    avatar_url: user?.avatar || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    departments: user?.departments.asMutable({ deep: true }) || [],
    departmentGroups: user?.departmentGroups.asMutable({ deep: true }) || [],
    roles: user?.roles.asMutable({ deep: true }) || [],
    two_factor_auth_enabled: user?.two_factor_auth_enabled || 0,
    co_employee_id: user?.co_employee_id || '',
    notifications: notifications?.asMutable({ deep: true }) || {},
  };

  const updateNotifications = (notifications: ProfileFormValues['notifications']) =>
    dispatch(actions.AppActions.updateNotifications(notifications));

  const updateProfile = (values) => dispatch(actions.AppActions.updateUserProfile(values));

  const onSubmit = async (formValues: ProfileFormValues) => {
    try {
      const values = {
        ...formValues,
        departments: formValues.departments.map((department) => department.id),
        departmentGroups: formValues.departmentGroups.map((departmentGroup) => departmentGroup.id),
      };
      delete values.initial_notifications;
      delete values.avatar;
      delete values.croppedAvatar;
      delete values.currentPassword;
      delete values.repeatPassword;
      delete values.notifications;

      if (formValues.croppedAvatar) {
        const { avatar_url } = await UsersAPI.uploadAvatar(formValues.croppedAvatar);
        values.avatar_url = avatar_url;
      }

      if (!values.co_employee_id) {
        delete values.co_employee_id;
      }

      if (formValues.password) {
        delete values.password;
        await AppAPI.updateCurrentUserPassword({
          password: formValues.password,
          currentPassword: formValues.currentPassword,
        });
      }

      await updateProfile(values);
      showSuccessMessage('Profile successfully updated');

      if (!isEqual(formValues.notifications, formValues.initial_notifications)) {
        await updateNotifications(formValues.notifications);
        showSuccessMessage('Notifications successfully updated');
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const formikBag = useFormik<ProfileFormValues>({
    initialValues,
    validationSchema: canEditEmployeeNumber
      ? ProfileValidation.editWithCurrentPassword.shape({
          co_employee_id: employeeNumberValidation.required(),
        })
      : ProfileValidation.editWithCurrentPassword,
    onSubmit,
  });

  useEffect(() => {
    formikBag.setValues((prevValues) => ({
      ...prevValues,
      notifications: notifications?.asMutable({ deep: true }) || {},
      initial_notifications: notifications?.asMutable({ deep: true }) || {},
    }));
  }, [notifications]);

  return (
    <>
      <Container sx={{ overflow: 'auto', flex: 1, display: 'flex', flexDirection: 'column', pb: 4 }}>
        <PageTitle>Profile Settings</PageTitle>
        <Box
          display="flex"
          flex={1}
          flexDirection={isDesktop ? 'row' : 'column'}
          p="20px"
          bgcolor="white"
          borderRadius="10px"
          sx={{
            gap: {
              xs: '30px',
              lg: '70px',
            },
          }}
        >
          {isDesktop ? (
            <ProfileSettingsTabs
              TabIndicatorProps={{ sx: { display: 'none' } }}
              value={activeTab}
              orientation="vertical"
              onChange={(e, value) => setActiveTab(value)}
            >
              <Tab
                icon={ProfileFormTabsDataMap[ProfileFormTabs.PROFILE].icon}
                iconPosition="start"
                value={ProfileFormTabs.PROFILE}
                label={ProfileFormTabsDataMap[ProfileFormTabs.PROFILE].title}
                {...DatasetTestIDs.components.profileForm.tabs.information}
              />
              <Tab
                icon={ProfileFormTabsDataMap[ProfileFormTabs.PASSWORD].icon}
                iconPosition="start"
                value={ProfileFormTabs.PASSWORD}
                label={ProfileFormTabsDataMap[ProfileFormTabs.PASSWORD].title}
                {...DatasetTestIDs.components.profileForm.tabs.password}
              />
              <Tab
                icon={ProfileFormTabsDataMap[ProfileFormTabs.NOTIFICATIONS].icon}
                iconPosition="start"
                value={ProfileFormTabs.NOTIFICATIONS}
                label={ProfileFormTabsDataMap[ProfileFormTabs.NOTIFICATIONS].title}
                {...DatasetTestIDs.components.profileForm.tabs.notifications}
              />
            </ProfileSettingsTabs>
          ) : (
            <ProfileSettingsSelect
              sx={{ position: 'sticky', top: 0, zIndex: 2, bgcolor: '#fff' }}
              value={activeTab}
              onChange={(event) => setActiveTab(event.target.value as ProfileFormTabs)}
            >
              {Object.values(ProfileFormTabs).map((tab) => {
                const { icon, title, hidden = false } = ProfileFormTabsDataMap[tab];

                if (hidden) return null;

                return (
                  <ProfileSettingsSelectMenuItem key={tab} value={tab}>
                    {icon}
                    {title}
                  </ProfileSettingsSelectMenuItem>
                );
              })}
            </ProfileSettingsSelect>
          )}

          <Box display="flex" flexDirection="column" width="100%" maxWidth={isDesktop ? 658 : 'auto'}>
            <ProfileFormTitle
              tab={activeTab}
              mb="24px"
              bgcolor={'#fff'}
              position={'relative'}
              zIndex={1}
              width="max-content"
            />
            <ProfileForm
              formikBag={formikBag}
              activeTab={activeTab}
              showEmployeeNumberField={canEditEmployeeNumber}
              isCurrentUser
            />
            <LoadingButton
              variant="contained"
              sx={{
                p: '14px 36px',
                width: 'max-content',
                mt: 4.5,
              }}
              loading={formikBag.isSubmitting}
              onClick={formikBag.submitForm}
              {...DatasetTestIDs.components.profileForm.profileInfoTab.buttons.saveProfile}
            >
              Save Changes
            </LoadingButton>
          </Box>
        </Box>
      </Container>
      <Backdrop open={appProcessing || formikBag.isSubmitting} sx={{ zIndex: 2, backdropFilter: 'blur(4px)' }}>
        <CircularProgress sx={{ color: '#fff' }} />
      </Backdrop>
    </>
  );
};

export default Profile;
