import { DetailedHTMLProps, forwardRef, useMemo } from 'react';

import clsx from 'clsx';

import { CircularProgress, IconButton } from '@mui/material';

import PDFImage from 'Assets/pdf-icon.png';
import { CloseIcon } from 'Utils/Icon';
import picture from 'Utils/picture';

import classes from './ImageButton.module.scss';

type Props = {
  url: string;
  loading?: boolean;
  onClosePress?: () => void;
  haveCloseIcon?: boolean;
  openImage?: (url: string) => void;
  selected?: boolean;
  withoutImage?: boolean;
  imageProps?: DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  noImagesStyle?: {
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
  filesMode?: boolean;
  upload_type?: string;
  isThumbnail?: boolean;
} & Omit<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'loading'>;

const ImageButton = forwardRef<HTMLDivElement, Props>(
  (
    {
      url = '',
      loading = false,
      onClosePress = () => {},
      haveCloseIcon = false,
      openImage = () => {},
      selected = false,
      children,
      imageProps = {},
      withoutImage = false,
      isThumbnail = false,
      noImagesStyle = {
        container: {},
        content: {},
      },
      filesMode = false,
      upload_type = '',
      ...rest
    },
    ref
  ) => {
    const onImageClick = () => openImage(url);

    const isImageUploadedAsPDF = useMemo(() => {
      return filesMode || upload_type === 'application/pdf' || picture.removeDate(url).endsWith('.pdf');
    }, [filesMode, upload_type, url]);

    const previewUrl = isImageUploadedAsPDF ? PDFImage : url;

    const imageStyles = useMemo(() => {
      const styles = {
        ...imageProps?.style,
        transition: 'all 0.2s ease-in-out',
        ...(selected && { boxShadow: '0 0 0 2px white', transform: 'scale(1.05)' }),
      };

      if (isImageUploadedAsPDF && isThumbnail) {
        return {
          ...styles,
          filter: 'invert(1)',
          ...(selected && { boxShadow: '0 0 0 2px black' }),
        };
      }

      return styles;
    }, [selected, imageProps?.style, isImageUploadedAsPDF, isThumbnail]);

    if (withoutImage) {
      return (
        <div
          {...rest}
          key={url}
          ref={ref}
          className={clsx(classes.thumbWrapper, rest.className)}
          style={{ cursor: 'pointer', ...rest.style, ...noImagesStyle.container }}
        >
          <div className={classes.content} style={noImagesStyle.content}>
            {children}
          </div>
        </div>
      );
    }

    return (
      <div
        key={url}
        {...rest}
        ref={ref}
        className={clsx(classes.thumbWrapper, rest.className)}
        style={{ cursor: 'pointer', ...rest.style }}
      >
        {haveCloseIcon && (
          <IconButton className={classes.delete} size="small" color="primary" onClick={onClosePress}>
            <CloseIcon />
          </IconButton>
        )}
        {loading ? (
          <div className={classes.absolute_center}>
            <CircularProgress size={20} />
          </div>
        ) : null}

        <img style={imageStyles} alt="" onClick={onImageClick} {...imageProps} src={previewUrl} />

        <link href={url} as="image" rel="preload" />
        {children}
      </div>
    );
  }
);

ImageButton.displayName = 'ImageButton';

export default ImageButton;
