import React, { FC, useMemo } from 'react';

import ChangeWorkerStatusModal from 'pages/workers/ui/ChangeWorkerStatusModal';
import { parsePhoneNumber } from 'react-phone-number-input';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { AppVersionObject, ConEdWorkerForTable } from 'types/Common/User';

import { JobType } from 'Constants/job';
import { Poppins } from 'Utils/Fonts';
import { convertWorkerAppVersionsToString } from 'Utils/PrepareData';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import app_history from 'app_history';
import StyledAppTable, { AppTableColumn, TableSortOrder } from 'components/StyledAppTable/StyledAppTable';
import TextOverflowTooltip from 'components/TextOverflowTooltip';

import WorkersApi from '../api/workers-api';
import { initialWorkersSearchParams, WorkersSearchParams } from '../constants/initialSearchParams';
import WorkerUserStatusSelect from './WorkerUserStatusSelect';
import { StyledChip, WorkersTableContainer } from './styled';

type Props = {
  workers: ConEdWorkerForTable[];
  isLoading?: boolean;
};

const WorkersTable: FC<Props> = ({ workers, isLoading }) => {
  const [updateWorker, { isLoading: isUpdateWorkerLoading }] = WorkersApi.useUpdateWorkerMutation();
  const { params, setSearchParam } = useSearchParams<WorkersSearchParams>();
  const [updateWorkerStatusData, setWorkerStatusData] = React.useState<{
    id: number;
    workerName: string;
    status: string;
  }>(null);

  const handleSort = (property: string) => {
    const currentOrderByTypeBoolean = Boolean(Number(params.order_by_type));

    if (params.order_by === property) {
      setSearchParam('order_by_type', String(Number(!currentOrderByTypeBoolean)));
    }
    setSearchParam('order_by', property);
  };

  const WORKERS_TABLE_COLUMNS = useMemo(
    (): AppTableColumn<ConEdWorkerForTable>[] => [
      {
        key: 'id',
        label: 'Employee ID',
        sorted: true,
        width: 100,
      },
      {
        key: 'name',
        label: 'Worker Name',
        sorted: true,
        width: 200,
        renderCell: (worker) => (
          <TextOverflowTooltip title={worker.name} sx={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography fontSize={13} fontFamily={Poppins[500]}>
              {worker.name}
            </Typography>
          </TextOverflowTooltip>
        ),
      },
      {
        key: 'status',
        label: 'Status',
        sorted: true,
        width: 150,
        renderCell: (worker) => (
          <WorkerUserStatusSelect
            size="small"
            value={worker.status}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setWorkerStatusData({ id: worker.id, workerName: worker.name, status: e.target.value });
            }}
            onOpen={(e) => {
              e.stopPropagation();
            }}
            onClose={(e) => {
              e.stopPropagation();
            }}
          />
        ),
      },
      {
        key: 'phone_number',
        label: 'Phone Number',
        sorted: true,
        width: 150,
        renderCell: (worker) => (
          <TextOverflowTooltip
            title={worker.phone_number}
            sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <Typography fontSize={13} fontFamily={Poppins[500]}>
              {parsePhoneNumber(worker.phone_number)?.formatInternational() || worker.phone_number}
            </Typography>
          </TextOverflowTooltip>
        ),
      },
      {
        key: 'email',
        label: 'Email',
        sorted: true,
        width: 200,
        renderCell: (worker) => (
          <TextOverflowTooltip
            title={worker.email}
            sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <Typography fontSize={13} fontFamily={Poppins[500]}>
              {worker.email}
            </Typography>
          </TextOverflowTooltip>
        ),
      },
      {
        key: 'subcontractorName',
        label: 'Subcontractor',
        sorted: true,
        width: 200,
        renderCell: (worker) => (
          <TextOverflowTooltip
            title={worker.subcontractorName}
            sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <Typography fontSize={13} fontFamily={Poppins[500]}>
              {worker.subcontractorName}
            </Typography>
          </TextOverflowTooltip>
        ),
      },
      {
        key: 'workerTypes',
        label: 'Worker Types',
        width: 280,
        renderCell: (worker) => (
          <Box display="flex" flexWrap="wrap" gap={1} minWidth={280}>
            {worker.workerTypes.map((type) => (
              <StyledChip key={type} label={JobType[type] || 'Unavailable'} />
            ))}
          </Box>
        ),
      },
      {
        key: 'app_versions',
        label: 'App Versions',
        width: 200,
        renderCell: (worker) =>
          convertWorkerAppVersionsToString(worker.app_versions as unknown as AppVersionObject[]) || 'Unavailable',
      },
    ],
    [updateWorker, isUpdateWorkerLoading]
  );

  if (!workers.length) {
    return (
      <WorkersTableContainer display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="text.secondary" fontFamily={Poppins[500]}>
              No workers found
            </Typography>
            <Button
              onClick={() => {
                Object.entries(initialWorkersSearchParams).forEach(([key, value]) => {
                  if (key === 'per_page') return;
                  setSearchParam(key as keyof WorkersSearchParams, value);
                });
              }}
            >
              Reset Filters
            </Button>
          </>
        )}
      </WorkersTableContainer>
    );
  }

  return (
    <WorkersTableContainer>
      <StyledAppTable
        onTableRowClick={(worker) => {
          app_history.push(`/workers/${worker.id}`);
        }}
        sx={{
          '& .MuiTableRow-root': {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#F9F9F9',
            },
          },
          '& .MuiTableHead-root': {
            backgroundColor: 'white',
            '& .MuiTableRow-head': {
              '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: '1px solid #E0E0E0',

                '&:first-of-type': {
                  borderBottomLeftRadius: 0,
                },

                '&:last-of-type': {
                  borderBottomRightRadius: 0,
                },
              },
            },
          },
        }}
        onSort={handleSort}
        order_by={params.order_by}
        order={Number(params.order_by_type) ? TableSortOrder.ASC : TableSortOrder.DESC}
        stickyHeader
        data={workers}
        columns={WORKERS_TABLE_COLUMNS}
      />

      {updateWorkerStatusData && (
        <ChangeWorkerStatusModal
          open={!!updateWorkerStatusData}
          onClose={() => setWorkerStatusData(null)}
          newStatus={updateWorkerStatusData.status}
          workerName={updateWorkerStatusData.workerName}
          workerId={updateWorkerStatusData.id}
        />
      )}
    </WorkersTableContainer>
  );
};

export default WorkersTable;
