import { FC } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';

import { ComplaintsTypeTab, TabContent } from '../WorkerDetails';

type Props = {
  tab: TabContent;
  complaintsTypeTab: ComplaintsTypeTab;
  onChangeTab: (tab: TabContent) => void;
  onChangeComplaintsTypeTab: (tab: ComplaintsTypeTab) => void;
  disabled?: boolean;
};

const WorkerDetailsTabMenu: FC<Props> = ({
  tab,
  complaintsTypeTab,
  disabled = false,
  onChangeTab,
  onChangeComplaintsTypeTab,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" bgcolor="inherit" borderBottom="1px solid #00000010">
      <Tabs value={tab} onChange={(e, tab) => onChangeTab(tab)}>
        <Tab
          disabled={disabled}
          value={TabContent.TabAppointedJobs}
          label="Appointed Jobs"
          {...DatasetTestIDs.pages.workerProfile.tabs.appointedJobs}
        />
        <Tab
          disabled={disabled}
          value={TabContent.TabEdit}
          label="Profile"
          {...DatasetTestIDs.pages.workerProfile.tabs.profile}
        />
        <Tab
          disabled={disabled}
          value={TabContent.Complaints}
          label="Complaints"
          {...DatasetTestIDs.pages.workerProfile.tabs.complaints}
        />
        <Tab
          disabled={disabled}
          value={TabContent.History}
          label="History"
          {...DatasetTestIDs.pages.workerProfile.tabs.history}
        />
        <Tab
          disabled={disabled}
          value={TabContent.GPSHistory}
          label="GPS History"
          {...DatasetTestIDs.pages.workerProfile.tabs.gpsHistory}
        />
      </Tabs>

      {tab === TabContent.Complaints && (
        <Tabs value={complaintsTypeTab} onChange={(e, tab) => onChangeComplaintsTypeTab(tab)} textColor="secondary">
          <Tab disabled={disabled} color="primary" label="Job complaints" value={ComplaintsTypeTab.job} />
          <Tab disabled={disabled} color="primary" label="Worker complaints" value={ComplaintsTypeTab.worker} />
        </Tabs>
      )}
    </Box>
  );
};

export default WorkerDetailsTabMenu;
