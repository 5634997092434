import React from 'react';

import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { JobType } from 'Constants/job';
import { addTestID, TestIDs } from 'Constants/tests';
import { UserRoles } from 'Constants/user';
import { WORKER_STATUS_SELECT } from 'Constants/worker';
import SubcontractorAsyncSearch from 'Containers/Components/Controls/SubcontractorMaterialAsyncSearch';
import { StyledIconButton } from 'Containers/Job/styled';
import { WORKER_TYPE } from 'Containers/Workers/Constants';
import { PushMessagesModal } from 'Containers/Workers/PushMessagesModal';
import { ExcelIcon, FileIcon, SearchIcon, TelegramIcon, UserWorkerIcon } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import useModal from 'Utils/hooks/useModal';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import { showSuccessMessage } from 'Utils/successMessage';
import app_history from 'app_history';

import WorkersApi from '../api/workers-api';
import { initialWorkersSearchParams } from '../constants/initialSearchParams';
import { StyledFormControl, WorkersFiltersContainer } from './styled';

const WorkersFilters = () => {
  const [showFilters, setShowFilters] = React.useState(false);
  const { params, setSearchParam } = useSearchParams({
    initialParams: initialWorkersSearchParams,
  });
  const [importWorker, { isLoading: isImportWorkerLoading }] = WorkersApi.useImportWorkersMutation();
  const [exportWorkers, { isLoading: isExportWorkersLoading }] = WorkersApi.useExportWorkersMutation();
  const statuses = params.statuses?.split(',');
  const workerTypes = params.workerTypes?.split(',') || [];
  const statusesValue = statuses?.length ? statuses : [];
  const pushMessagesModal = useModal();

  const handleChangeSearchParam = (param: keyof typeof params, value: string | string[]) => {
    if (!value || (typeof value !== 'string' && value.includes(''))) {
      setSearchParam(param, '');
      setSearchParam('page', '1');
      return;
    }
    setSearchParam(param, typeof value === 'string' ? value : value.join(','));
    setSearchParam('page', '1');
  };

  const handleExportWorkers = () => {
    exportWorkers({
      search: params.search || undefined,
      statuses: params.statuses || undefined,
      workerTypes: params.workerTypes || undefined,
      subcontractorId: params.subcontractorId ? Number(params.subcontractorId) : undefined,
    })
      .unwrap()
      .catch(({ data }) => showErrorMessage(data));
  };

  const { isDesktop } = useAppMediaQuery();

  const isFiltersShown = isDesktop || showFilters;

  return (
    <Box bgcolor={'white'} width={'100%'}>
      <WorkersFiltersContainer>
        <Box
          sx={({ breakpoints }) => ({
            gridTemplateColumns: 'auto 1fr auto',
            gridTemplateAreas: '"workers-search workers-filters workers-actions"',
            gap: 2,
            display: 'grid',
            alignItems: 'center',
            width: '100%',
            padding: '18px 24px',
            backgroundColor: '#FFFFFF',
            borderRadius: 10,
            '& .MuiFormControl-root': {
              width: '100%',
            },

            [breakpoints.down('xl')]: {
              padding: '12px 14px',
              gridTemplateColumns: '1fr auto',
              gridTemplateAreas: `"workers-search workers-actions" "workers-filters workers-filters"`,
            },

            [breakpoints.down('md')]: {
              gridTemplateColumns: '1fr',
              gridTemplateAreas: showFilters
                ? `"workers-actions"  "filter-button" "workers-search" "workers-filters"`
                : `"workers-actions" "filter-button"`,
            },
          })}
        >
          {!isDesktop && (
            <Button
              variant={showFilters ? 'contained' : 'outlined'}
              onClick={() => setShowFilters(!showFilters)}
              startIcon={showFilters ? <FilterAltOff /> : <FilterAlt />}
              sx={{
                height: isDesktop ? 48 : 40,
                gridArea: 'filter-button',
              }}
            >
              Filters
            </Button>
          )}

          {isFiltersShown && (
            <>
              <Box display="flex" flexGrow={1} gap={2} gridArea={'workers-search'}>
                <StyledFormControl variant="outlined" sx={{ flexGrow: 1, minWidth: 250 }}>
                  <TextField
                    value={params.search}
                    onChange={(e) => handleChangeSearchParam('search', e.target.value)}
                    placeholder="Search by name, email, phone"
                    InputProps={{
                      startAdornment: (
                        <Box sx={{ pr: 1, color: 'text.secondary' }}>
                          <SearchIcon />
                        </Box>
                      ),
                    }}
                  />
                </StyledFormControl>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                gap={'10px'}
                gridArea={'workers-filters'}
                sx={({ palette, breakpoints }) => ({
                  [breakpoints.down('xl')]: {
                    paddingTop: '16px',
                    borderTop: `1px solid ${palette.divider}`,
                  },
                  [breakpoints.down('md')]: {
                    flexDirection: 'column',
                    paddingTop: '0',
                    borderTop: 'none',
                  },
                })}
                hidden={!showFilters && !isDesktop}
              >
                <StyledFormControl variant="outlined" sx={{ minWidth: 200, maxWidth: isDesktop ? 200 : 'auto' }}>
                  <InputLabel shrink>Statuses</InputLabel>
                  <Select
                    multiple
                    value={statusesValue}
                    displayEmpty
                    onChange={(e: SelectChangeEvent<string[]>) => handleChangeSearchParam('statuses', e.target.value)}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <Typography>Select Statuses</Typography>;
                      }
                      const selectedStatuses = selected.map((status) => {
                        const statusItem = WORKER_STATUS_SELECT.find((item) => item.value === status);
                        return statusItem?.label || '';
                      });
                      return selectedStatuses.join(', ');
                    }}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    {WORKER_STATUS_SELECT.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>

                <StyledFormControl variant="outlined" sx={{ minWidth: 150 }}>
                  <InputLabel shrink>Subcontractor</InputLabel>
                  <SubcontractorAsyncSearch
                    selectedId={Number(params.subcontractorId)}
                    onSelectSubcontractor={(subcontractor) => {
                      if (!subcontractor) {
                        handleChangeSearchParam('subcontractorId', '');
                        return;
                      }
                      handleChangeSearchParam('subcontractorId', String(subcontractor.id));
                    }}
                    renderInput={(params) => <TextField placeholder="Select Subcontractor" {...params} />}
                  />
                </StyledFormControl>

                <StyledFormControl variant="outlined" sx={{ minWidth: 280 }}>
                  <InputLabel shrink>Worker Types</InputLabel>
                  <Select
                    multiple
                    value={workerTypes}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      handleChangeSearchParam('workerTypes', e.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected: string[]) => {
                      if (selected.length === 0) {
                        return <Typography>Select Work Types</Typography>;
                      }

                      return (
                        <Box display="flex" flexWrap={'wrap'} gap={1}>
                          {selected.map((value) => (
                            <Chip key={value} label={JobType[value]} />
                          ))}
                        </Box>
                      );
                    }}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    {WORKER_TYPE.map((type) => (
                      <MenuItem key={type.value} value={String(type.value)}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Box>
            </>
          )}

          <Box
            sx={({ breakpoints }) => ({
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: isDesktop ? 48 : 40,

              [breakpoints.down('md')]: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateAreas: '"new-worker-button" "other-worker-actions"',
                height: 'auto',
              },
            })}
            gridArea={'workers-actions'}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{ minWidth: 120, height: isDesktop ? 48 : 40, gridArea: 'new-worker-button', lineHeight: 1 }}
              startIcon={<UserWorkerIcon />}
              onClick={() => app_history.push('/workers/create')}
              {...addTestID(TestIDs.pages.workers.buttons.addWorker)}
            >
              New Worker
            </Button>
            <Box display="flex" gap={'10px'} gridArea={'other-worker-actions'} sx={{ height: isDesktop ? 48 : 40 }}>
              {!UserRoles.has.SubcontractorRole && (
                <LoadingButton
                  component="label"
                  fullWidth
                  variant="translucent"
                  sx={{ minWidth: 180 }}
                  startIcon={<ExcelIcon />}
                  loading={isImportWorkerLoading}
                >
                  Import From Excel
                  <input
                    type="file"
                    accept=".xls,.xlsx"
                    hidden
                    onChange={(event) => {
                      importWorker(event.target.files[0])
                        .unwrap()
                        .then((res) => showSuccessMessage(res?.message || 'Workers import successfully!'))
                        .catch(({ data }) => showErrorMessage(data))
                        .finally(() => {
                          event.target.value = '';
                        });
                    }}
                  />
                </LoadingButton>
              )}
              <StyledIconButton
                color="primary"
                sx={{ minHeight: isDesktop ? 48 : 40, aspectRatio: 1 }}
                onClick={handleExportWorkers}
                disabled={isExportWorkersLoading}
              >
                {isExportWorkersLoading ? <CircularProgress size={14} /> : <FileIcon />}
              </StyledIconButton>
              <StyledIconButton
                color="primary"
                sx={{ minHeight: isDesktop ? 48 : 40, aspectRatio: 1 }}
                onClick={pushMessagesModal.open}
              >
                <TelegramIcon />
              </StyledIconButton>
            </Box>
          </Box>
        </Box>
      </WorkersFiltersContainer>

      <PushMessagesModal isOpen={pushMessagesModal.isOpen} closeModal={pushMessagesModal.close} />
    </Box>
  );
};

export default WorkersFilters;
