import BackHandIcon from '@mui/icons-material/BackHand';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PanToolIcon from '@mui/icons-material/PanTool';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { WorkerUserStatus } from 'Constants/worker';

export const WORKERS_STATUS_COLORS_MAP = {
  [WorkerUserStatus.active]: {
    color: '#126305',
    backgroundColor: '#1263050F',
    icon: CheckIcon,
  },

  [WorkerUserStatus.inactive]: {
    color: '#6f7780',
    backgroundColor: '#6f77801A',
    icon: StopCircleIcon,
  },

  [WorkerUserStatus.waiting_for_approval]: {
    color: '#2F83FF',
    backgroundColor: '#2F83FF1A',
    icon: HistoryToggleOffIcon,
  },

  [WorkerUserStatus.rejected]: {
    color: '#C52525',
    backgroundColor: '#FF2F2F1A',
    icon: DoDisturbAltIcon,
  },

  [WorkerUserStatus.onhold]: {
    color: '#FF6D00',
    backgroundColor: '#FF6D001A',
    icon: BackHandIcon,
  },
};
