import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Props<T> = {
  baseUrl?: string;
  initialParams?: Partial<T>;
};

export const useSearchParams = <T>({ baseUrl, initialParams }: Props<T> = { baseUrl: '/' }) => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const params = useMemo(() => Object.fromEntries(searchParams.entries()) as T, [searchParams]);

  const setSearchParam = <K extends keyof T>(key: K, value: T[K]) => {
    if (!value) {
      removeSearchParam(key);
      return;
    }
    searchParams.set(key as string, value as string);
    history.push({
      search: searchParams.toString(),
    });
  };

  const removeSearchParam = (key: keyof T) => {
    searchParams.delete(key as string);
    history.push({ search: searchParams.toString() });
  };

  const toBase = () => {
    history.push(baseUrl);
  };

  useEffect(() => {
    if (initialParams) {
      Object.keys(initialParams).forEach((key) => {
        if (!params[key as keyof T]) {
          setSearchParam(key as keyof T, initialParams[key as keyof T]);
        }
      });
    }
  }, []);

  return {
    params,
    setSearchParam,
    removeSearchParam,
    toBase,
  };
};
