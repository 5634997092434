export const sources = {
  worker: 'job-worker',
  location: 'job-location',
  workerPoints: 'job-worker-points',
  workerMovedPoint: 'job-worker-play-point',
} as const;

export const fitToBoundsOptions = {
  padding: { top: 80, left: 30, right: 30, bottom: 100 },
  speed: 4,
  maxZoom: 16,
};
