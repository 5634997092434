export const initialWorkersSearchParams = {
  page: '1',
  per_page: '25',
  /** String of worker types joined by comma */
  workerTypes: '',
  /** String of statuses joined by comma */
  statuses: '',
  subcontractorId: '',
  search: '',
  order_by: 'id',
  order_by_type: '1',
};

export type WorkersSearchParams = typeof initialWorkersSearchParams;
