import React, { FC, memo, useEffect } from 'react';

import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  MenuItem,
  Pagination,
  PaginationProps,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Poppins } from 'Utils/Fonts';

import { StyledPaginationIconButton } from './styled';

type Props = PaginationProps & {
  page: number;
  count: number;
  perPage: number;
  onChangePage: (page: number | null) => void;
  onChangePerPage: (perPage: number) => void;
  perPageOptions?: number[];
  wrapperProps?: BoxProps;
  withPageInput?: boolean;
  simplePagination?: boolean;
  nextPage?: () => void;
  prevPage?: () => void;
  nextPageDisabled?: boolean;
  prevPageDisabled?: boolean;
  totalPages?: number;
};

const AppPagination: FC<Props> = ({
  page,
  count,
  perPage,
  onChangePage,
  onChangePerPage,
  perPageOptions = [10, 25, 50, 100],
  wrapperProps,
  withPageInput,
  simplePagination,
  nextPage = () => {},
  prevPage = () => {},
  nextPageDisabled,
  prevPageDisabled,
  totalPages: total,
  ...paginationProps
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [tmpValue, setTmpValue] = React.useState<string>('');
  const totalPages = total || (count && perPage) ? Math.ceil(count / perPage) : 0;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpValue(e.target.value);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (tmpValue === '' || isNaN(Number(tmpValue))) {
      onChangePage(page);
      setTmpValue(String(page));
      return;
    }

    const numberValueTrimmed = Number(tmpValue.replace(/^0+/, '').trim());

    if (numberValueTrimmed < 1) {
      onChangePage(1);
      setTmpValue('1');
      return;
    }

    if (numberValueTrimmed > totalPages) {
      onChangePage(totalPages);
      setTmpValue(String(totalPages));
      return;
    }

    if (numberValueTrimmed < 1) {
      onChangePage(1);
      setTmpValue('1');
      return;
    }

    onChangePage(numberValueTrimmed);
  };

  useEffect(() => {
    setTmpValue(String(page));
  }, [page]);

  useEffect(() => {
    const enterKeyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && inputRef.current) {
        inputRef.current.blur();
      }
    };

    window.addEventListener('keydown', enterKeyHandler);

    return () => {
      window.removeEventListener('keydown', enterKeyHandler);
    };
  }, [inputRef]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} {...wrapperProps}>
      <Select
        size="small"
        value={perPage || 10}
        onChange={(e) => onChangePerPage(Number(e.target.value))}
        renderValue={(value) => value + ' per page'}
        disabled={paginationProps.disabled}
        sx={{
          borderRadius: '6px',
          fontSize: 12,
          lineHeight: '18px',
          '& .MuiSelect-select': {
            p: '5px 12px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            p: 0,
          },
        }}
      >
        {perPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <Stack hidden={simplePagination}>
        <Pagination
          sx={{
            '& .MuiPaginationItem-root': {
              lineHeight: 1,
              fontFamily: Poppins[500],
              fontSize: 12,
              height: 24,
              minWidth: 24,
              color: 'text.secondary',
              borderColor: '#E8E9EB',
              borderRadius: '6px',
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.main',
                },
              },

              '&:hover': {
                bgcolor: 'primary.light',
              },
            },
            '& .MuiPaginationItem-sizeSmall': {
              height: 24,
              minWidth: 24,
            },
            '& .MuiTouchRipple-root': {
              lineHeight: 1,
            },
            '& .MuiPaginationItem-ellipsis': {
              lineHeight: 1.5,
              border: '1px solid #E8E9EB',
              borderRadius: '6px',
              '&:hover': {
                bgcolor: 'transparent',
              },
            },
          }}
          color="primary"
          count={totalPages}
          page={page}
          onChange={(_, newPage) => onChangePage(newPage)}
          variant="outlined"
          shape="rounded"
          siblingCount={0}
          size="small"
          {...paginationProps}
        />
      </Stack>

      {withPageInput && !simplePagination && (
        <Box display="flex" alignItems="center" gap="14px">
          <Typography fontSize={12} fontFamily={Poppins[500]} color={'text.secondary'}>
            Enter the page
          </Typography>

          <TextField
            inputRef={inputRef}
            sx={{
              width: 'max-content',
              '& .MuiOutlinedInput-notchedOutline': { p: 0, borderRadius: '6px' },
            }}
            size="small"
            value={tmpValue}
            inputProps={{
              style: { textAlign: 'center', fontSize: 12, padding: 0, width: 24, height: 24 },
            }}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
          />
        </Box>
      )}

      {simplePagination && (
        <Box display="flex" alignItems="center" gap={1}>
          <StyledPaginationIconButton onClick={prevPage} disabled={prevPageDisabled}>
            <KeyboardArrowLeftRounded />
          </StyledPaginationIconButton>
          <StyledPaginationIconButton onClick={nextPage} disabled={nextPageDisabled}>
            <KeyboardArrowRightRounded />
          </StyledPaginationIconButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(AppPagination);
