import moment from 'moment';

import { AppThunkAction } from 'types';
import { MasterTimesheetType, Timesheet } from 'types/Timesheet';

import { TimesheetsAPI } from 'Services/API';
import { UpdateMasterTimesheetParams } from 'Services/API/TimesheetsAPI.service';
import { retrieve as retrieveBillingJobs } from 'Services/billing/actions';
import { API_BASE_ENDPOINT } from 'Services/endpoint';
import { downloadFileToPC } from 'Utils/downloadFile';
import { objectValuesFilter } from 'Utils/valueFilter';
import { ReduxState } from 'createStore';

import { refetchTimesheetsWithConf } from '../grouppedTimesheets/actions';
import { retrieveJob, retrieveLocationJob, retrieveLocations } from '../jobs/actions';
import * as actionTypes from './actionTypes';

export const FILTERS_STORAGE_KEY = 'timesheets.filters';

export function retrieve(): AppThunkAction<void> {
  return async function (dispatch, getState) {
    try {
      const processing_key = Math.random();

      dispatch({ type: actionTypes.GET_TIMESHEETS_REQUEST, processing_key });
      const search_options = getState().timesheets.search_options.asMutable();
      if (search_options.workerSelectOption) {
        search_options.worker = search_options.workerSelectOption.value.id;
      }
      const filters: Partial<typeof search_options> = objectValuesFilter(search_options, [null, '']);
      const timesheets = await fetchTimesheets(filters);
      dispatch({ type: actionTypes.GET_TIMESHEETS_SUCCESS, timesheets, processing_key });
    } catch (error) {
      dispatch({ type: actionTypes.GET_TIMESHEETS_ERROR });
    }
  };
}

export function retrieveMasterTimesheet(confirmationNumber: number): AppThunkAction<Promise<MasterTimesheetType>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_MASTER_TIMESHEET_REQUEST });
      const masterTimesheet = await TimesheetsAPI.getMasterTimesheet(confirmationNumber);
      dispatch({ type: actionTypes.GET_MASTER_TIMESHEET_SUCCESS, masterTimesheet });
      return masterTimesheet;
    } catch (error) {
      dispatch({ type: actionTypes.GET_MASTER_TIMESHEET_ERROR, error: error.error });
      throw error;
    }
  };
}

export function updateMasterTimesheet(params: {
  confirmation_id: number;
  updateMasterTimesheetParams: UpdateMasterTimesheetParams;
}): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    try {
      await TimesheetsAPI.updateMasterTimesheet(params.confirmation_id, params.updateMasterTimesheetParams);
      dispatch(retrieveMasterTimesheet(params.confirmation_id));
      dispatch(retrieveJob(params.confirmation_id));
    } catch (error) {
      throw error;
    }
  };
}

export function update(id, data): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_TIMESHEETS_REQUEST });
      const response = await TimesheetsAPI.update(id, data);
      dispatch({ type: actionTypes.UPDATE_TIMESHEETS_SUCCESS, timesheet: response });
      dispatch(refetchTimesheetsWithConf(response.confirmationNumber));
      dispatch(refetchJobs(response.confirmationNumber));
      dispatch(retrieveBillingJobs());
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_TIMESHEETS_ERROR });
      throw error;
    }
  };
}

export function create(data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.CREATE_TIMESHEET_REQUEST });
      const response = await TimesheetsAPI.create(data);
      dispatch({ type: actionTypes.CREATE_TIMESHEET_SUCCESS, timesheet: response });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_TIMESHEET_ERROR });
      throw error;
    }
  };
}

export function deleteTimesheet(id, confirmationNumber = 0): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DELETE_TIMESHEETS_REQUEST });
      const response = await TimesheetsAPI.delete(id);
      dispatch({ type: actionTypes.DELETE_TIMESHEETS_SUCCESS, timesheet: { response, id } });
      dispatch(refetchTimesheetsWithConf(confirmationNumber));
      dispatch(refetchJobs(confirmationNumber));
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.DELETE_TIMESHEETS_ERROR });
      throw error;
    }
  };
}

export function importExcel(file): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_REQUEST });
      const response = await TimesheetsAPI.importFromExcel(file);
      dispatch({ type: actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_SUCCESS, timesheets: response });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_ERROR });
      throw error;
    }
  };
}

export function updateFilters(
  search_options: Partial<ReturnType<ReduxState['timesheets']['search_options']['asMutable']>> = {}
): AppThunkAction<void> {
  return function (dispatch, getState) {
    dispatch({ type: actionTypes.UPDATE_FILTERS, filters: search_options });
    dispatch(retrieve());
  };
}

export function uploadImages(images): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPLOAD_IMAGE_REQUEST });
      const response = await TimesheetsAPI.uploadImages(images);
      dispatch({ type: actionTypes.UPLOAD_IMAGE_SUCCESS, img_data: response });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.UPLOAD_IMAGE_ERROR });
      throw error;
    }
  };
}

export function getTimesheetTotalHours(startDate, endDate, timesheetId = null) {
  const start = moment(startDate).set('second', 0);
  const end = moment(endDate).set('second', 0);
  const total = end.diff(start, 'hours', true);
  return total;
}

export function getTimesheet(id: number | string): AppThunkAction<Promise<Timesheet>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_TIMESHEET_REQUEST });
      const response = await TimesheetsAPI.getTimesheetById(id);
      dispatch({ type: actionTypes.GET_TIMESHEET_SUCCESS, timesheet: response });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.GET_TIMESHEET_ERROR });
      throw error;
    }
  };
}

export function updateMultiple(
  timesheetsIds: number[] = [],
  update: {
    verified?: number;
    worker_paid?: number;
    invoiced?: number;
    paid?: number;
    sign?: string;
    employee_number?: string;
  }
): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_CHECKED_TIMESHEETS_REQUEST });
      await TimesheetsAPI.updateMultiple({
        timesheet_ids: timesheetsIds.map(String),
        ...update,
      });

      dispatch({ type: actionTypes.UPDATE_CHECKED_TIMESHEETS_SUCCESS });
      dispatch(retrieve());
      return;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_CHECKED_TIMESHEETS_ERROR });
      throw error;
    }
  };
}

export function approveMultipleWithServiceDept(
  timesheetsIds: number[] = [],
  update: {
    signature_data: string;
    signature_type_id: number;
    employee_number: string;
  }
): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    try {
      await TimesheetsAPI.approveMultipleWithServiceDept({
        timesheet_ids: timesheetsIds.map(String),
        signatures: [update],
      });

      dispatch(retrieve());
      return;
    } catch (error) {
      throw error;
    }
  };
}

export function downloadAllConfTimesheets(confirmationNumber: number | string = 0): AppThunkAction<Promise<Blob>> {
  return async function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      const token = getState().app.token;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      fetch(`${API_BASE_ENDPOINT}/jobs/${confirmationNumber}/timesheets/pdf`, {
        headers,
        method: 'GET',
      })
        .then((res) => {
          if (res.status > 299) {
            throw res;
          }
          return res.blob();
        })
        .then(resolve)
        .catch((err) => {
          const possibleError = err?.message || err?.error;
          if (possibleError) {
            return reject({ error: possibleError });
          }
          return err.json();
        })
        .then((jsonError) => reject(jsonError));
    });
  };
}

export function downloadMultiple(timesheetsIds: number[] = []): AppThunkAction<Promise<Blob>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_REQUEST });
      const query = new URLSearchParams();
      timesheetsIds.forEach((id) => query.append('timesheet_ids[]', id.toString()));

      const file = await TimesheetsAPI.downloadMultipleAsPdf(query);
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_SUCCESS });
      return file;
    } catch (error) {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_ERROR });
      throw error;
    }
  };
}

export async function fetchTimesheets(
  filters: Partial<ReturnType<ReduxState['timesheets']['search_options']['asMutable']>>
) {
  // @ts-ignore
  return TimesheetsAPI.getAll(filters);
}

export function exportToCSV(): AppThunkAction<Promise<Blob>> {
  return async function (dispatch, getState) {
    try {
      const timesheets = getState().timesheets.timesheets.results;
      if (!timesheets.length) {
        throw new Error('No timesheets found for this query');
      }
      dispatch({ type: actionTypes.EXPORT_CSV_REQUEST });
      const search_options = getState().timesheets.search_options.asMutable();
      const filters = objectValuesFilter(search_options, [null, '']);
      filters.page = 1;
      filters.per_page = getState().timesheets.timesheets.total;
      delete filters.total;
      const query = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwn(filters, key)) {
          query.append(key, `${filters[key]}`);
        }
      }
      const file = await TimesheetsAPI.exportToCSV(query);
      dispatch({ type: actionTypes.EXPORT_CSV_SUCCESS });
      return file;
    } catch (error) {
      dispatch({ type: actionTypes.EXPORT_CSV_ERROR });
      throw error;
    }
  };
}

export function reviveTimesheet(id: number | string): AppThunkAction<Promise<Timesheet>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.REVIVE_TIMESHEETS_REQUEST });
      const response: { timesheet: Timesheet } = await TimesheetsAPI.reviveTimesheet(id);
      dispatch({ type: actionTypes.REVIVE_TIMESHEETS_SUCCESS, payload: response.timesheet });
      const job_id = response.timesheet.job_id;
      dispatch(refetchJobs(job_id));
      return response.timesheet;
    } catch (error) {
      dispatch({ type: actionTypes.REVIVE_TIMESHEETS_ERROR });
      throw error;
    }
  };
}

export async function downloadTimesheetPDF(id) {
  const response = await TimesheetsAPI.downloadPdf(id);
  const url = window.URL.createObjectURL(new Blob([response]));
  downloadFileToPC(url, 'timesheet.pdf');
}

function refetchJobs(job_id: string | number): AppThunkAction<void> {
  return function (dispatch, getState) {
    const dispatchJob = getState().jobs.location_job;
    if (dispatchJob?.id === job_id) {
      dispatch(retrieveLocationJob({ job_id: Number(job_id) }));
      dispatch(retrieveLocations());
    }
    const jobDetailsJob = getState().jobs.job;
    if (jobDetailsJob?.id === job_id) {
      dispatch(retrieveJob(job_id, ''));
    }
  };
}

export function setMobileFilters(value: boolean): AppThunkAction<void> {
  return function (dispatch, getState) {
    dispatch({ type: actionTypes.SET_MOBILE_FILTERS, payload: value });
  };
}
