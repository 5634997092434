import * as Sentry from '@sentry/react';
import ifvisible from 'ifvisible';

import { AppThunkAction } from 'types';
import { FileType } from 'types/Common/App';
import { ConedUser } from 'types/Common/User';

import { UserRoles } from 'Constants/user';
import { NotificationsObject, getNotificationsArray } from 'Containers/Profile/components/UserNotifications';
import { AppAPI } from 'Services/API';
import { APP_API, AUTH_LOGIN_RESPONSE_TYPE } from 'Services/API/AppAPI.service';
import { IfVisibleListeners } from 'Services/ifvisibleListeners';
import UserPermissions from 'Utils/PermissionsHelper';
import { Departments } from 'Utils/departments';

import FBMessaging from '../FirebaseMessaging';
import ApiService from '../HttpService';
import { initFilters as initInvoicesFilters, retrieve as retrieveInvoices } from '../invoices/actions';
import {
  initFilters as initJobsFilters,
  retrieve as retrieveJobs,
  retrieveLocationJob,
  retrieveLocations,
  updateFilters,
} from '../jobs/actions';
import { getNotificationsList } from '../notifications/actions';
import { retrieve as retrieveSubcontractors } from '../subcontractors/actions';
import { retrieve as retrieveZones } from '../zones/actions';
import {
  ACTIVATE_ACCOUNT_ERROR,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  APP_INIT,
  APP_INIT_FINISHED,
  CHANGE_DISPATCH_PAGE_CONFIG,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENT_GROUPS_ERROR,
  GET_DEPARTMENT_GROUPS_REQUEST,
  GET_DEPARTMENT_GROUPS_SUCCESS,
  GET_FILE_TYPES_ERROR,
  GET_FILE_TYPES_REQUEST,
  GET_FILE_TYPES_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PERMISSIONS_ERROR,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_ROLES_ERROR,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  TOKEN_SUCCESS,
  UPDATE_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from './actionTypes';

export function init(): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    dispatch({ type: APP_INIT });

    try {
      const token = JSON.parse(localStorage.getItem('Token'));
      if (token) {
        ApiService.setToken(token);
        // @ts-ignore
        ApiService.assignDispatch(dispatch);
        // restAPI.setToken(token);
        // dispatch({
        //   // socket.io
        //   type: 'authenticate',
        //   token: token,
        // });
        dispatch({ type: TOKEN_SUCCESS, token: token });
        const [user] = await Promise.all([dispatch(retrieve()), dispatch(getPermissions())]);
        if (user) {
          Sentry.setUser({
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
            id: user.id?.toString(),
          });
          Sentry.addBreadcrumb({
            category: 'session_token',
            message: token,
            level: 'debug',
          });
        }
        FBMessaging.init();
        dispatch(initializeDataRefetching());
        dispatch({ type: APP_INIT_FINISHED });
        dispatch(getFileTypes());
        dispatch(getRoles());
        dispatch(getDepartments());
        dispatch(getDepartmentGroups());
        dispatch(getNotifications());
        dispatch(retrieveSubcontractors());
        dispatch(retrieveZones());
        dispatch(initInvoicesFilters());
        dispatch(retrieveInvoices());
        dispatch(updateFilters({}));
        dispatch(initJobsFilters());
        dispatch(getNotificationsList());
      } else {
        dispatch(logout());
      }
    } catch (error) {
      console.debug(error);
    } finally {
      dispatch({ type: APP_INIT_FINISHED });
    }
  };
}

function initializeDataRefetching(): AppThunkAction<void> {
  return function (dispatch, getState) {
    /**** INIT ***/
    ifvisible.setIdleDuration(180);
    const every80seconds = ifvisible.onEvery(80, () => {
      dispatch(retrieveJobs());
    });
    const every30seconds = ifvisible.onEvery(30, () => {
      dispatch(retrieveLocations());
      const location_job = getState().jobs.location_job;
      const selected_location_id = getState().jobs.selected_location_id;
      if (location_job) {
        dispatch(
          retrieveLocationJob({
            job_id: location_job.id,
            id: selected_location_id,
          })
        );
      }
    });
    const every10seconds = ifvisible.onEvery(10, () => {
      dispatch(retrieveZones());
    });
    const every60seconds = ifvisible.onEvery(60, () => {
      dispatch(getNotificationsList());
    });
    const onEveryFocus = () => {
      dispatch(retrieveJobs());
      dispatch(retrieveLocations());
      dispatch(retrieveZones());
      const location_job = getState().jobs.location_job;
      const selected_location_id = getState().jobs.selected_location_id;
      if (location_job) {
        dispatch(
          retrieveLocationJob({
            job_id: location_job.id,
            id: selected_location_id,
          })
        );
      }
    };
    ifvisible.on('focus', onEveryFocus);
    IfVisibleListeners.addMultipleListeners([
      every10seconds.stop,
      every30seconds.stop,
      every60seconds.stop,
      every80seconds.stop,
      () => ifvisible.off('focus', onEveryFocus),
    ]);
  };
}

export function retrieve(): AppThunkAction<Promise<ConedUser>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_ACCOUNT_REQUEST });
      const response = await AppAPI.getCurrentUser();

      dispatch({ type: GET_ACCOUNT_SUCCESS, user: response });
      UserRoles.set(response.roles);
      return response;
    } catch (error) {
      dispatch({ type: GET_ACCOUNT_ERROR });
    }
  };
}

export function getPermissions(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_PERMISSIONS_REQUEST });
      const response = await AppAPI.getPermissions();

      UserPermissions.set(response.results);

      dispatch({ type: GET_PERMISSIONS_SUCCESS, permissions: response.results });
      return response;
    } catch (error) {
      dispatch({ type: GET_PERMISSIONS_ERROR });
      throw error;
    }
  };
}

export function getRoles(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_ROLES_REQUEST });
      const response = await AppAPI.getRoles();
      dispatch({ type: GET_ROLES_SUCCESS, roles: response });
    } catch (error) {
      dispatch({ type: GET_ROLES_ERROR });
    }
  };
}

export function activateAccount(token: any, password: any): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: ACTIVATE_ACCOUNT_REQUEST });
      const response = await AppAPI.activateAccount(token, password);
      dispatch({ type: ACTIVATE_ACCOUNT_SUCCESS, response: response });
      return response;
    } catch (error) {
      dispatch({ type: ACTIVATE_ACCOUNT_ERROR });
      throw error;
    }
  };
}

type DepartmentOptions = {
  non_invoiced?: boolean;
  order_field?: 'priority';
  order_direction?: 'asc';
};

export function getDepartments(options: DepartmentOptions = {}): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_DEPARTMENTS_REQUEST });
      const response = await AppAPI.getDepartments(options);
      dispatch({ type: GET_DEPARTMENTS_SUCCESS, departments: response });
      Departments.set(response);
    } catch (error) {
      dispatch({ type: GET_DEPARTMENTS_ERROR });
    }
  };
}

export function updateDepartment(
  id: number,
  params: APP_API.UpdateDepartmentParams
): AppThunkAction<Promise<Response>> {
  return async function (dispatch, getState) {
    try {
      const response = await AppAPI.updateDepartment(id, params);
      await dispatch(getDepartments());
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getDepartmentGroups(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_DEPARTMENT_GROUPS_REQUEST });
      const response = await AppAPI.getDepartmentGroups();
      dispatch({ type: GET_DEPARTMENT_GROUPS_SUCCESS, departmentGroups: response.department_groups });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_GROUPS_ERROR });
    }
  };
}

export function updateDepartmentGroup(
  id: number,
  params: APP_API.UpdateDepartmentGroupParams
): AppThunkAction<Promise<Response>> {
  return async function (dispatch, getState) {
    try {
      const response = await AppAPI.updateDepartmentGroup(id, params);
      await dispatch(getDepartmentGroups());
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function login(params: APP_API.LoginParams): AppThunkAction<Promise<APP_API.LoginResponse>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await AppAPI.login(params);

      switch (response.response_type) {
        case AUTH_LOGIN_RESPONSE_TYPE.two_factor_auth_enabled:
          return response;
        case AUTH_LOGIN_RESPONSE_TYPE.bearer_token:
          ApiService.setToken(response.token);
          break;
      }

      dispatch({ type: LOGIN_SUCCESS, response: response });
      dispatch(init());
      return response;
    } catch (error) {
      dispatch({ type: LOGIN_ERROR });
      throw error;
    }
  };
}

export function twoFactorAuthLogin(params: APP_API.TwoFactorAuthLoginParams): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await AppAPI.twoFactorAuthLogin(params);
      ApiService.setToken(response.token);
      dispatch({ type: LOGIN_SUCCESS, response: response });
      dispatch(init());
      return response;
    } catch (error) {
      dispatch({ type: LOGIN_ERROR });
      throw error;
    }
  };
}

//get user notifications
export function getNotifications(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_NOTIFICATIONS_REQUEST });
      const response = await AppAPI.getCurrentUserNotifications();
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        notifications: response.notifications,
      });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATIONS_ERROR });
    }
  };
}

//update notifications
export function updateNotifications(data: NotificationsObject): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_NOTIFICATIONS_REQUEST });
      const updateNotifications = getNotificationsArray(data);
      const response = await AppAPI.updateCurrentUserNotifications(updateNotifications);

      dispatch({
        type: UPDATE_NOTIFICATIONS_SUCCESS,
        notifications: data,
      });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_NOTIFICATIONS_ERROR });
      throw error;
    }
  };
}

// update user
export function updateUserProfile(data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const response = await AppAPI.updateCurrentUser(data);
      dispatch({ type: UPDATE_USER_SUCCESS, user: response });
      dispatch(getNotifications());
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_USER_ERROR });
      throw error;
    }
  };
}

export function twoFactorAuthSendCode(
  data: APP_API.TwoFactorAuthSendCodeParams
): AppThunkAction<Promise<{ message: string }>> {
  return async function (dispatch, getState) {
    try {
      const response = await AppAPI.twoFactorAuthSendCode(data);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

// get file_types
export function getFileTypes(): AppThunkAction<Promise<FileType[]>> {
  return async function (dispatch) {
    try {
      dispatch({ type: GET_FILE_TYPES_REQUEST });
      const response = await AppAPI.getFileTypes();
      const file_types = response?.file_types ? response?.file_types.filter(({ deleted }) => !deleted) : [];
      dispatch({ type: GET_FILE_TYPES_SUCCESS, file_types });
      return file_types;
    } catch (error) {
      dispatch({ type: GET_FILE_TYPES_ERROR });
      throw error;
    }
  };
}

export function changeDispatchPageConfig(config: {
  displayType?: 'list_map' | 'list';
  showAllFilters?: boolean;
  smallDisplay?: boolean;
}): AppThunkAction<void> {
  return function (dispatch, getState) {
    dispatch({
      type: CHANGE_DISPATCH_PAGE_CONFIG,
      payload: config,
    });
  };
}
export function logout(): any {
  return function (dispatch, getState) {
    ApiService.setToken(null);
    // restAPI.removeToken();
    ApiService.abortAllRequests();
    localStorage.clear();
    // if (getState().app.token) {
    dispatch({ type: LOGOUT });
    // app_history.go(0);
    // }
    IfVisibleListeners.clearListeners();
  };
}
