import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AppThunkDispatch } from 'types';

import CompleteJobImg from 'Assets/icons/complete-job.svg';
import { JobStatus, ReleaseReason } from 'Constants/job';
import { UserRoles } from 'Constants/user';
import {
  ReleaseWorkersReasonSelect,
  ReleaseWorkersReasonValidation,
} from 'Containers/Job/components/ReleaseWorkersReasonSelect';
import { actions } from 'Services';
import { Poppins } from 'Utils/Fonts';
import { showErrorMessage } from 'Utils/errorMessage';
import useGetTotalHours from 'Utils/hooks/useGetTotalHours';
import AppPaperModal from 'components/AppPaperModal';

import './CompleteJob.scss';

const CompleteJob = ({
  setComplete = () => {},
  onClose = () => {},
  open = false,
  job_id = 0,
  calculateTotalHours = false,
  isAllShiftsCompleted = true,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { calculated, displayedTotalHours } = useGetTotalHours(
    JobStatus.COMPLETED,
    job_id,
    open && calculateTotalHours
  );
  const isConedUser = UserRoles.has.ConEdRole;

  const isReleaseReasonDropdownVisible = !isAllShiftsCompleted && !isConedUser;

  const updateJobStatus = (job_id: string | number, options: { status: string; release_reason?: ReleaseReason }) =>
    dispatch(actions.JobsActions.updateJobStatus(job_id, options));

  const confirmComplete = async (release_reason: ReleaseReason) => {
    try {
      setComplete();
      await updateJobStatus(job_id, {
        status: 'complete',
        ...(!isAllShiftsCompleted && release_reason && { release_reason }),
      });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      onClose();
    }
  };

  const {
    values: { release_reason },
    errors: { release_reason: releaseReasonError },
    touched,
    setFieldValue,
    submitForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      release_reason: isConedUser ? ReleaseReason.CE_WEB_RELEASE : null,
    },
    onSubmit: (values) => {
      confirmComplete(values.release_reason);
    },
    validationSchema: isReleaseReasonDropdownVisible ? ReleaseWorkersReasonValidation : undefined,
  });

  return (
    <AppPaperModal
      onClose={onClose}
      open={open}
      title="Complete Job?"
      subtitle="Has the work been completed?"
      containerStyle={{
        width: '100%',
        maxWidth: '500px',
      }}
      submitButton={{
        title: 'Confirm',
        onClick: submitForm,
        loading: isSubmitting,
        disabled: (calculateTotalHours && calculated) || (touched && !!releaseReasonError),
      }}
    >
      <Box className={'complete-image-group'} mt={2} mb={2}>
        <img className={'complete-image'} src={CompleteJobImg} alt="" />
      </Box>
      <Box className={'complete-image-group'} mt={2} mb={2}>
        <Typography color="textSecondary">Has the work been completed?</Typography>
      </Box>
      {calculateTotalHours && (
        <Box className={'complete-image-group'} mt={2} mb={2}>
          <Typography color="textPrimary" variant="h6">
            You will be charged for {displayedTotalHours} hours
          </Typography>
        </Box>
      )}
      {isReleaseReasonDropdownVisible && (
        <Stack gap={1}>
          <Typography fontFamily={Poppins[500]} fontSize={13} sx={{ opacity: 0.5 }}>
            Some shifts are not completed. Please select the reason for releasing the workers
          </Typography>
          <ReleaseWorkersReasonSelect
            value={release_reason}
            onChange={(reason) => setFieldValue('release_reason', reason)}
            error={touched && !!releaseReasonError}
            helperText={touched && releaseReasonError}
          />
        </Stack>
      )}
    </AppPaperModal>
  );
};

export default CompleteJob;
