import { MouseEvent, useState, ReactNode } from 'react';

import { History } from 'history';
import { ImmutableObject } from 'seamless-immutable';

import { TableCellProps } from '@mui/material';

import { Timesheet } from 'types/Timesheet';
import { Comment } from 'types/Timesheet';

import { TimesheetNames } from 'Constants/timesheet';
import picture from 'Utils/picture';

import { TimesheetWithValidationProps } from './types';

export type TableColumnData<RowType> = {
  label: string | JSX.Element;
  value: string;
  sortable: boolean;
  headCellProps: (row?: RowType) => TableCellProps;
  bodyCellProps: (row?: RowType) => TableCellProps & { sticky?: 'true' | 'false' };
  key: keyof RowType;
  renderData: (row: RowType) => ReactNode;
};

class TableColumnConfig<RowType> implements TableColumnData<any> {
  label: TableColumnData<RowType>['label'] = '';
  value = '';
  sortable = false;
  key: keyof RowType;
  constructor(params: Partial<TableColumnData<RowType>>) {
    Object.entries(params).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  headCellProps: TableColumnData<RowType>['headCellProps'] = () => ({});
  bodyCellProps: TableColumnData<RowType>['bodyCellProps'] = () => ({});
  renderData: TableColumnData<RowType>['renderData'] = (cellData) => (this.key ? cellData[this.key] : '') as ReactNode;
}

export function createTableColumnsConfig<TableDataItem>(
  config: Partial<TableColumnData<TableDataItem>>[]
): TableColumnData<TableDataItem>[] {
  return config.map((value) => new TableColumnConfig(value));
}

export function useTimesheetActions<TimesheetType>({ history }: { history: History }) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);
  const [selectedRow, setSelectedRow] = useState<TimesheetType>(null);

  const open = (e: MouseEvent<HTMLButtonElement>, row: TimesheetType) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.target as HTMLDivElement);
    setSelectedRow(row);
  };

  const close = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const edit = (id: number | string) => history.push(`/timesheets/${id}/edit`);

  return { selectedRow, anchorEl, open, close, edit };
}

export const getIsTimesheetApproved = (timesheet: Timesheet | ImmutableObject<Timesheet>) => {
  return Boolean(
    timesheet.signatures.length
      ? timesheet.signatures.every((signature) => signature.signature_data)
      : timesheet.employeeNumber && timesheet.employeeNumber !== '0' && timesheet.sign && timesheet.signatureName
  );
};

export const hasAnyChanges = (
  values: TimesheetWithValidationProps,
  timesheet: Timesheet | ImmutableObject<Timesheet>
) => {
  const editableFieldKeys = Object.values(TimesheetNames);
  const isEqual = editableFieldKeys.every((key) => {
    if (!['string', 'boolean', 'number'].includes(typeof values[key])) {
      return JSON.stringify(values[key]) === JSON.stringify(timesheet[key]);
    }
    if (key === TimesheetNames.totalHours) {
      return values[key].toFixed(2) === timesheet[key].toFixed(2);
    }
    return values[key] === timesheet[key];
  });
  return !isEqual;
};

export const generateTimesheetPhotoName = ({
  shift_id = 0 as string | number,
  is_rejected = false as boolean | number,
  isPDF = false,
} = {}) =>
  picture.generateName(
    [
      [
        shift_id,
        `${isPDF ? 'timesheet_pdf' : 'timesheet_photo'} shift #${shift_id}`,
        isPDF ? 'timesheet_pdf_file' : 'timesheet_photo',
      ],
      [is_rejected, 'rejected'],
    ],
    ' - '
  );

export const parseCommentAuthorFullName = (comment: Comment) => {
  if (comment.author_first_name && comment.author_last_name) {
    return comment.author_first_name + ' ' + comment.author_last_name;
  }
  if (comment.author_first_name) {
    return comment.author_first_name;
  }
  if (comment.author_last_name) {
    return comment.author_last_name;
  }
  return 'Unknown';
};
