import Immutable from 'seamless-immutable';

import { Department } from 'types/Common/Companies';
import { ConEdRequestor, ConEdSupervisor } from 'types/Common/User';
import { MasterTimesheetType, Timesheet, TimesheetTableItemWithColor, TimesheetsSearchOption } from 'types/Timesheet';

import WorkersPaginatedAsyncSelect from 'Containers/Components/Controls/WorkersPaginatedAsyncSelect/WorkersPaginatedAsyncSelect';
import { getTimesheetsThreads } from 'Utils/timesheets';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = Immutable({
  search_options: {
    page: 1,
    per_page: 10,
    order_by: 'timesheets.start_at',
    order_by_type: true,
    confirmation: '',
    shift_id: '',
    po: '',
    subcontractorName: '',
    worker: null,
    workerSelectOption: null,
    field_supervisor: true,
    from_datetime: null,
    jobStatus: null,
    jobType: null,
    paidOnlyStatus: '',
    supervisor_verified: null,
    to_datetime: null,
    verifiedStatus: null,
    department: null,
    show_cancelled_timesheets: false,
    status: null,
    supervisor: null,
    requestor: null,
    pending: false,
  } as TimesheetsSearchOption,
  savedDepartment: null as { label: string; value: Department },
  savedRequestor: null as { label: string; value: ConEdRequestor },
  savedSupervisor: null as { label: string; value: ConEdSupervisor },
  timesheets: {
    results: [] as Array<TimesheetTableItemWithColor>,
    per_page: 10,
    count: 1,
    current_page: 1,
    has_more_pages: false,
    total: 1,
    related_to_one_conf: false,
  },
  masterTimesheet: null as MasterTimesheetType,
  masterTimesheetApiError: null as string,
  masterTimesheetRetrieving: false,
  timesheetsThreadsCount: 0,
  showMobileFilters: false,
  processing_key: '',
  processing: false,
  mark_as_paid_processing: false,
  currentPage: 1,
  timesheet: {} as Timesheet,
  downloadingChecked: false,
  exportCSVProcessing: false,
  reviveTimesheetProcessing: false,
  timesheetLoading: false,
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_TIMESHEETS_REQUEST:
      return state.merge({
        processing: true,
        processing_key: action.processing_key,
      });
    case actionTypes.GET_TIMESHEETS_SUCCESS: {
      if (action.processing_key === state.processing_key) {
        const timesheets = action.timesheets;
        let timesheetsThreadsCount = 0;

        if (
          state.search_options.confirmation &&
          state.search_options.confirmation !== '' &&
          action.timesheets.related_to_one_conf
        ) {
          const { list, threadCount } = getTimesheetsThreads(action.timesheets.results);
          timesheets.results = list;
          timesheetsThreadsCount = threadCount;
        }
        return state.merge({
          processing: false,
          timesheets,
          timesheetsThreadsCount,
        });
      } else {
        return state;
      }
    }
    case actionTypes.GET_TIMESHEETS_ERROR:
      return state.merge({
        processing: false,
      });
    case actionTypes.GET_MASTER_TIMESHEET_REQUEST:
      return state.merge({
        masterTimesheetRetrieving: true,
      });
    case actionTypes.GET_MASTER_TIMESHEET_SUCCESS:
      return state.merge({
        masterTimesheet: action.masterTimesheet,
        masterTimesheetRetrieving: false,
      });
    case actionTypes.GET_MASTER_TIMESHEET_ERROR:
      return state.merge({
        masterTimesheetApiError: action.error,
        masterTimesheetRetrieving: false,
      });
    case actionTypes.LOAD_EXACT_PAGE:
      return state.merge({
        timesheets: action.timesheets,
        processing: false,
      });

    // GET TIMESHEET
    case actionTypes.GET_TIMESHEET_REQUEST:
      return state.merge({
        timesheetLoading: true,
      });
    case actionTypes.GET_TIMESHEET_SUCCESS:
      return state.merge({
        timesheetLoading: false,
        timesheet: action.timesheet,
      });
    case actionTypes.GET_TIMESHEET_ERROR:
      return state.merge({
        timesheetLoading: false,
      });

    // UPDATE TIMESHEET
    case actionTypes.UPDATE_TIMESHEETS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_TIMESHEETS_SUCCESS:
      //console.log(state.timesheets.results);
      const index = state.timesheets.results
        ? state.timesheets.results.findIndex((i) => i.id === action.timesheet.id)
        : -1;
      if (index === -1) {
        return state;
      } else {
        const timesheets = JSON.parse(JSON.stringify(state.timesheets));
        timesheets.results[index] = {
          ...timesheets.results[index],
          ...action.timesheet,
          worker_name: action.timesheet.worker.name,
          subcontractorName: action.timesheet.worker.subcontractorName,
        };
        return state.merge({
          processing: false,
          timesheets: timesheets,
        });
      }
    case actionTypes.UPDATE_TIMESHEETS_ERROR:
      return state.merge({
        processing: false,
      });

    // DELEET TIMESHEET
    case actionTypes.DELETE_TIMESHEETS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.DELETE_TIMESHEETS_SUCCESS:
      const i = state.timesheets.results.findIndex((i) => i.id === action.timesheet.id);
      if (i === -1) {
        return state.merge({ processing: false });
      } else {
        const timesheets = JSON.parse(JSON.stringify(state.timesheets));
        timesheets.results = timesheets.results.filter((i) => i.id !== action.timesheet.id);
        return state.merge({
          processing: false,
          timesheets: timesheets,
        });
      }
    case actionTypes.DELETE_TIMESHEETS_ERROR:
      return state.merge({
        processing: false,
      });

    // MARK TIMESHEETS AS worker PAID FROM WXCEL FILE
    case actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_REQUEST:
      return state.merge({
        mark_as_paid_processing: true,
      });
    case actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_SUCCESS:
      return state.merge({
        mark_as_paid_processing: false,
        search_options: {
          per_page: 10,
          order_by: 'timesheets.start_at',
          order_by_type: false,
          confirmation: '',
          shift_id: '',
          po: '',
          subcontractorName: '',
          worker: null,
        },
        timesheets: action.timesheets,
      });
    case actionTypes.MARK_TIMESHEETS_AS_WORKER_PAID_ERROR:
      return state.merge({
        mark_as_paid_processing: false,
      });

    case actionTypes.UPDATE_FILTERS:
      const search_options = {
        ...state.search_options,
        page: 1,
        ...action.filters,
      };
      return state.merge({ search_options });
    case actionTypes.UPDATE_SAVED_DEPARTMENT:
      return state.merge({
        savedDepartment: action.payload,
      });
    case actionTypes.UPDATE_SAVED_REQUESTOR:
      return state.merge({
        savedRequestor: action.payload,
      });
    case actionTypes.UPDATE_SAVED_SUPERVISOR:
      return state.merge({
        savedSupervisor: action.payload,
      });
    case actionTypes.SET_MOBILE_FILTERS:
      return state.merge({
        showMobileFilters: action.payload,
      });
    case actionTypes.EXPORT_CSV_REQUEST:
      return state.merge({ exportCSVProcessing: true });
    case actionTypes.EXPORT_CSV_SUCCESS:
    case actionTypes.EXPORT_CSV_ERROR:
      return state.merge({ exportCSVProcessing: false });
    case actionTypes.REVIVE_TIMESHEETS_REQUEST:
      return state.merge({ reviveTimesheetProcessing: true });
    case actionTypes.REVIVE_TIMESHEETS_SUCCESS:
      return state.merge({ reviveTimesheetProcessing: false, timesheet: action.payload });
    case actionTypes.REVIVE_TIMESHEETS_ERROR:
      return state.merge({ reviveTimesheetProcessing: false });
    case actionTypes.UPDATE_CHECKED_TIMESHEETS_REQUEST:
      return state.merge({ processing: true });
    case actionTypes.UPDATE_CHECKED_TIMESHEETS_SUCCESS:
    case actionTypes.UPDATE_CHECKED_TIMESHEETS_ERROR:
      return state.merge({ processing: false });
    case actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_REQUEST:
      return state.merge({ downloadingChecked: true });
    case actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_SUCCESS:
    case actionTypes.DOWNLOAD_MULTIPLE_TIMESHEETS_ERROR:
      return state.merge({ downloadingChecked: false });
    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
