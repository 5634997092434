import moment from 'moment';

import { WorkerHistoryItem, WorkerPointConfig, WorkerPointData } from '.';

export const createWorkerPoints = (workerHistory: WorkerHistoryItem[] = []) => {
  const pointConfig = new Map<string, WorkerPointConfig[]>();
  workerHistory
    // .filter(({ lon }) => lon < 0)
    .forEach(({ lat, lon, datetime }, index) => {
      const latLon = `${lat.toFixed(6)}:${lon.toFixed(6)}`;
      if (pointConfig.has(latLon)) {
        pointConfig.set(latLon, [
          ...pointConfig.get(latLon),
          { datetime: moment(datetime).format('MMM, DD h:mm a'), index: index + 1 },
        ]);
        return;
      }
      pointConfig.set(latLon, [{ datetime: moment(datetime).format('MMM, DD h:mm a'), index: index + 1 }]);
    });

  const points: WorkerPointData[] = [];
  pointConfig.forEach((configs, latLon) => {
    const [lat = '0', lon = '0'] = latLon.split(':');
    points.push({ lat: parseFloat(lat), lon: parseFloat(lon), configs });
  });
  return points;
};

export function coordinateTransformer(workerHistory: WorkerHistoryItem[] = []) {
  const workerPlaybackData: WorkerHistoryItem[] = [];
  const minDate = moment(workerHistory[0].datetime);
  const maxDate = moment(workerHistory[workerHistory.length - 1].datetime);

  const playbackLength = maxDate.diff(minDate || new Date(), 'minutes');

  for (let i = 0; i <= playbackLength; i++) {
    const currentDate = minDate.clone().add(i, 'minute');
    const element = workerHistory.find(
      ({ datetime }, i, arr) => currentDate.isSame(datetime) || currentDate.isBetween(datetime, arr[i + 1].datetime)
    );
    workerPlaybackData.push({ ...element, datetime: currentDate.format('MMM, DD h:mm a') });
  }
  return workerPlaybackData;
}
