import React, { forwardRef, useCallback, useState } from 'react';

import { CustomScroll } from 'react-custom-scroll';
import { Document, DocumentProps, Page, PageProps } from 'react-pdf';

import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Box, BoxProps, CircularProgress, IconButton, InputBase, Typography } from '@mui/material';

import { Poppins } from 'Utils/Fonts';

type Props = BoxProps & {
  documentProps?: DocumentProps;
  pageProps?: PageProps;
};

const PDFBox = forwardRef<HTMLDivElement, Props>(({ documentProps, pageProps, ...boxProps }, ref) => {
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const nextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const prevPage = useCallback(() => {
    setPage((prevPage) => prevPage - 1);
  }, []);

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPage(1);
  }, []);

  const handleChangePDFPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setPage(1);
      return;
    }
    if (isNaN(Number(event.target.value))) {
      return;
    }
    if (Number(event.target.value) > numPages) {
      setPage(numPages);
      return;
    }
    setPage(Number(event.target.value));
  };

  return (
    <Box {...boxProps} position="relative" ref={ref}>
      <CustomScroll heightRelativeToParent="100%">
        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          {...documentProps}
          loading={
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
              <CircularProgress />
            </Box>
          }
        >
          <Page pageNumber={page} {...pageProps} />
        </Document>
      </CustomScroll>

      {/* Pagination */}
      {numPages > 1 && (
        <Box
          position="absolute"
          zIndex={2}
          display={'flex'}
          alignItems={'center'}
          bottom={10}
          borderRadius={2}
          right={'50%'}
          sx={{
            transform: 'translateX(50%)',
            opacity: 0.8,
            transition: 'opacity 0.15s ease-in-out, background-color 0.15s ease-in-out',
            backgroundColor: 'rgba(0,0,0,0.8)',
            '&:hover': {
              opacity: 1,
              backgroundColor: 'rgba(0,0,0,0.9)',
            },
          }}
          p={1}
          color="white"
        >
          <IconButton sx={{ color: 'white' }} disabled={page === 1} onClick={prevPage}>
            <KeyboardArrowLeftRounded />
          </IconButton>
          <InputBase
            value={page}
            type="tel"
            onChange={handleChangePDFPage}
            sx={{
              maxWidth: 'max-content',
              '& .MuiInputBase-input': {
                maxWidth: 20,
                maxHeight: 10,
                textAlign: 'center',
                color: 'white',
                fontSize: 14,
                fontFamily: Poppins[500],
              },
            }}
          />
          <Typography fontSize={14} fontFamily={Poppins[500]} component={'span'} sx={{ color: 'white' }}>
            /{' ' + numPages}
          </Typography>

          <IconButton sx={{ color: 'white' }} disabled={page === numPages} onClick={nextPage}>
            <KeyboardArrowRightRounded />
          </IconButton>
        </Box>
      )}
    </Box>
  );
});

export default PDFBox;
