import { FC } from 'react';

import { Stack, Typography, MenuItem, StackProps } from '@mui/material';

import { ReleaseReason, ReleaseReasonsLabels } from 'Constants/job';
import { AppSelect } from 'components/AppSelect/AppSelect';

type Props = {
  value: ReleaseReason;
  onChange: (reason: ReleaseReason) => void;
  hidden?: boolean;
  wrapperProps?: StackProps;
  error?: boolean;
  helperText?: string;
};

const ReleaseWorkersReasonSelect: FC<Props> = ({
  value,
  onChange,
  wrapperProps,
  hidden = false,
  error = false,
  helperText = '',
}) => {
  if (hidden) return null;

  return (
    <Stack gap="10px" {...wrapperProps}>
      <Typography variant="caption" gutterBottom display="block" mb={0}>
        Dispatch Release Reasons
      </Typography>
      <Stack>
        <AppSelect
          value={value || ''}
          displayEmpty
          onChange={(e) => onChange(e.target.value as ReleaseReason)}
          placeholder="Select reason"
          error={error}
        >
          {!value && (
            <MenuItem value={''} sx={{ fontStyle: 'italic' }} disabled>
              Select reason
            </MenuItem>
          )}

          {Object.entries(ReleaseReasonsLabels).map(([reason, label]) => (
            <MenuItem
              key={reason}
              value={reason}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'primary.light',
                },
                '&.Mui-selected': {
                  color: 'primary.main',
                },
              }}
            >
              {label}
            </MenuItem>
          ))}
        </AppSelect>
        <Typography variant="caption" color="error" display="block">
          {helperText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ReleaseWorkersReasonSelect;
