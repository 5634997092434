import { ChangeEvent, useCallback, useMemo } from 'react';

import moment from 'moment';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, FormControlLabel, Tooltip, Typography, styled } from '@mui/material';

import { Timesheet } from 'types/Timesheet';

import AppPermissions from 'Constants/permissions';
import { TimesheetNames, TimesheetStatus } from 'Constants/timesheet';
import { getIsTimesheetApproved } from 'Containers/Timesheets/utils';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';
import { useAppSelector } from 'createStore';

import TimesheetBox from '../../components/TimesheetBox/TimesheetBox';
import styles from './TimesheetBillingInfo.module.scss';

const BoxWithTimestamp = styled(Box)({
  position: 'relative',
});

const Timestamp = styled(Typography)({
  fontSize: '0.8rem',
  position: 'absolute',
  left: '50%',
  bottom: '0',
  transform: 'translate(-25%, 40%)',
  opacity: '0.6',
});

const parseTimestamp = (dateAt: string) =>
  dateAt && moment(dateAt).isAfter(new Date(2020, 0)) ? moment(dateAt).format('MM/DD/YY') : '';

const parseSignaturesCount = (signatures: Timesheet['signatures']) => {
  const signatureCount = signatures.filter((signature) => signature.signature_data).length;
  return signatureCount ? `${signatureCount}/${signatures.length} Verified` : '';
};

const TimesheetBillingInfo = () => {
  const initialTimesheet = useAppSelector((state) => state.timesheets.timesheet);
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const permissions = useAppSelector((state) => state.app.permissions);
  const isUserCanDoTimesheetAction = permissions?.includes(AppPermissions.can_do_timesheet_action);
  const isTimesheetClockedOut = timesheet.status === TimesheetStatus.clockedOut;
  const isTimesheetApproved = getIsTimesheetApproved(timesheet);
  const isDateChanged =
    initialTimesheet.startDate !== timesheet.startDate || initialTimesheet.finishDate !== timesheet.finishDate;

  const parseTooltipText = (name = '') => {
    if (!isTimesheetClockedOut) {
      return 'The timesheet is not clocked out';
    }
    if (isDateChanged) {
      return `Save timesheet to recalculate total hours before you mark it as ${name}`;
    }
    return '';
  };

  const changeNumericFields = useCallback(
    ({ target: { name, value, checked } }: ChangeEvent<HTMLInputElement>) => {
      formikBag.setFieldValue(name, checked ? 1 : 0);
    },
    [formikBag.setFieldValue]
  );

  const ConEdVerifiedCheckboxEnabled = isTimesheetApproved ? !formikBag.isLockedByConEdVerified : false;

  const onConEdVerifiedClick = () => {
    if (!isTimesheetApproved) return;

    if (timesheet.signatures.length) {
      formikBag.setValues({
        ...timesheet,
        signatures: timesheet.signatures.map((signature) => ({
          can_be_signed: signature.can_be_signed,
          name: null,
          signed_at: null,
          signee: null,
          signature_type_id: signature.signature_type_id,
          signature_data: null,
          employee_number: null,
        })),
      });
      return;
    }

    formikBag.setValues({
      ...timesheet,
      [TimesheetNames.signatureName]: null,
      [TimesheetNames.sign]: null,
      [TimesheetNames.employeeNumber]: null,
    });
  };

  const CESVerifiedCheckboxEnabled =
    isUserCanDoTimesheetAction && isTimesheetClockedOut && !timesheet.invoiced && !isDateChanged;

  const CEVerifiedTooltipText = useMemo(() => {
    if (isTimesheetApproved) {
      return timesheet.signatures.length ? 'All signatures are collected' : 'ConEd Verified';
    }

    if (!timesheet.signatures.length) {
      return 'Waiting for signature';
    }

    const nextSignature = timesheet.signatures.find((signature) => !signature.signature_data);

    if (nextSignature) {
      return `Waiting for ${nextSignature.name}`;
    }

    return 'No signatures are collected';
  }, [timesheet.signatures, isTimesheetApproved]);

  return (
    <TimesheetBox>
      <Box className={styles.checkboxes}>
        <Tooltip disableInteractive title={CEVerifiedTooltipText}>
          <BoxWithTimestamp>
            <FormControlLabel
              label="ConEd Verified"
              control={
                <Checkbox
                  onClick={onConEdVerifiedClick}
                  disabled={!ConEdVerifiedCheckboxEnabled}
                  checked={isTimesheetApproved}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Timestamp>
              {isTimesheetApproved
                ? parseTimestamp(timesheet.ce_verified_at)
                : `${parseSignaturesCount(timesheet.signatures)}`}
            </Timestamp>
          </BoxWithTimestamp>
        </Tooltip>

        <Tooltip
          disableInteractive
          title={
            !initialTimesheet.invoiced
              ? parseTooltipText('Verified')
              : 'You cannot change CES Verified value if timesheet is already invoiced'
          }
        >
          <BoxWithTimestamp>
            <FormControlLabel
              label="CES Verified"
              control={
                <Checkbox
                  name={TimesheetNames.isVerified}
                  checked={!!timesheet.isVerified}
                  disabled={!CESVerifiedCheckboxEnabled}
                  onChange={changeNumericFields}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Timestamp>{parseTimestamp(timesheet.ces_verified_at)}</Timestamp>
          </BoxWithTimestamp>
        </Tooltip>

        <Tooltip disableInteractive title={parseTooltipText('Worker Paid')}>
          <BoxWithTimestamp>
            <FormControlLabel
              label="Worker Paid"
              control={
                <Checkbox
                  name={TimesheetNames.workerPaid}
                  checked={!!timesheet.workerPaid}
                  disabled={!isUserCanDoTimesheetAction || !isTimesheetClockedOut || isDateChanged}
                  onChange={changeNumericFields}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Timestamp>{parseTimestamp(timesheet.worker_paid_at)}</Timestamp>
          </BoxWithTimestamp>
        </Tooltip>

        <Tooltip
          disableInteractive
          title={
            initialTimesheet.invoiced
              ? parseTooltipText('Invoiced')
              : 'Timesheet is marked as Invoiced by System when invoice is created'
          }
        >
          <BoxWithTimestamp>
            <FormControlLabel
              label="Invoiced"
              control={
                <Checkbox
                  name={TimesheetNames.invoiced}
                  checked={!!timesheet.invoiced}
                  disabled
                  onChange={changeNumericFields}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Timestamp>{parseTimestamp(timesheet.invoiced_at)}</Timestamp>
          </BoxWithTimestamp>
        </Tooltip>

        <Tooltip
          disableInteractive
          title={initialTimesheet.invoiced ? parseTooltipText('Invoice Paid') : 'The Timesheet must be Invoiced first'}
        >
          <BoxWithTimestamp>
            <FormControlLabel
              label="Invoice Paid"
              control={
                <Checkbox
                  name={TimesheetNames.paid}
                  checked={!!timesheet.paid}
                  disabled
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Timestamp>{parseTimestamp(timesheet.invoice_paid_at)}</Timestamp>
          </BoxWithTimestamp>
        </Tooltip>
      </Box>
    </TimesheetBox>
  );
};

export default TimesheetBillingInfo;
