import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { Box, Button, Stack, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { baseToastConfig } from 'Constants/app';
import { JobStatus, ReleaseReason } from 'Constants/job';
import { UserRoles } from 'Constants/user';
import {
  ReleaseWorkersReasonSelect,
  ReleaseWorkersReasonValidation,
} from 'Containers/Job/components/ReleaseWorkersReasonSelect';
import { actions } from 'Services';
import { Poppins } from 'Utils/Fonts';
import useGetTotalHours from 'Utils/hooks/useGetTotalHours';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  jobId: number;
  open: boolean;
  onClose: () => void;
};

const ReleaseAllWorkersConfirmationModal = ({ jobId, open, onClose }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { calculated, error, reCalculate, displayedTotalHours } = useGetTotalHours(JobStatus.COMPLETED, jobId, open);
  const [canReleaseWorkers, setCanReleaseWorkers] = useState(true);
  const isConedUser = UserRoles.has.ConEdRole;

  const releaseWorkers = async (reason: ReleaseReason) => {
    if (calculated && error) {
      try {
        setCanReleaseWorkers(false);
        await new Promise((res, rej) =>
          toast.error(
            <Box>
              <Typography>
                Something went wrong while calculating the total number of hours. Fees may apply if this action is
                further confirmed. Close this for retry
              </Typography>
              <Button variant="contained" color="primary" onClick={res}>
                Confirm anyway
              </Button>
            </Box>,
            { ...baseToastConfig, autoClose: false, onClose: rej, onClick: rej }
          )
        );
      } catch (error) {
        setCanReleaseWorkers(true);
        reCalculate();
        return;
      }
    }
    setCanReleaseWorkers(true);
    onClose();
    try {
      await dispatch(actions.JobsActions.releaseWorkers(jobId, reason));
      toast.success('The workers were released and the job status was set to completed', baseToastConfig);
    } catch (error) {
      toast.error(error, baseToastConfig);
    }
  };

  const {
    values: { release_reason },
    errors: { release_reason: releaseReasonError },
    touched,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: {
      release_reason: isConedUser ? ReleaseReason.CE_WEB_RELEASE : null,
    },
    onSubmit: (values) => {
      releaseWorkers(values.release_reason);
    },
    validationSchema: !isConedUser ? ReleaseWorkersReasonValidation : undefined,
  });

  return (
    <AppPaperModal
      title="Attention"
      open={open}
      onClose={onClose}
      containerStyle={{
        maxWidth: 524,
      }}
      submitButton={{
        title: 'Yes',
        onClick: submitForm,
        disabled: !canReleaseWorkers || (touched && !!releaseReasonError),
      }}
      cancelButton={{
        color: 'error',
      }}
    >
      <Stack gap="24px">
        <Typography fontSize={13} fontFamily={Poppins[500]} sx={{ opacity: 0.5 }}>
          Are you sure you want to release workers and complete the job? This job has already started and release
          workers would invoke a {displayedTotalHours} Hour
        </Typography>
        <ReleaseWorkersReasonSelect
          value={release_reason}
          onChange={(reason) => setFieldValue('release_reason', reason)}
          hidden={isConedUser}
          error={touched && !!releaseReasonError}
          helperText={touched && releaseReasonError}
        />
      </Stack>
    </AppPaperModal>
  );
};

export default ReleaseAllWorkersConfirmationModal;
