import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { Props } from 'react-select';

import { Box, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';

import { MasterTimesheetItem } from 'types/Timesheet';

import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { SortTableIcon } from 'Utils/Icon';
import useSearchSortParams from 'Utils/hooks/useSearchSortParams';
import picture from 'Utils/picture';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

import { MasterTimesheetContentBox, StyledTable } from '../../styled';

const TableColumns: {
  key: keyof MasterTimesheetItem;
  label: string;
  bodyCell: (row: MasterTimesheetItem) => string | number | JSX.Element;
  width?: string;
}[] = [
  {
    key: 'id',
    label: 'Shift ID',
    bodyCell: (row) => row.id,
  },
  {
    key: 'worker_name',
    label: 'Name',
    bodyCell: (row) => (
      <Typography fontSize={13} fontFamily={'Poppins Medium'} color={'primary.main'}>
        {row.worker_name}
      </Typography>
    ),
  },
  {
    key: 'images',
    label: 'Files',
    bodyCell: (row) => (
      <Box
        width={250}
        display="flex"
        flexWrap="wrap"
        gap={'2px'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {row.images?.length ? (
          <ImageViewer
            images={row.images}
            size="small"
            showImagesRow
            getDownloadedImageName={({ created_at }) => {
              return picture.generateName(
                [
                  'timesheet_file_shift_id',
                  [row.job_worker_id, '#' + row.job_worker_id, ''],
                  [created_at, moment(created_at).format('MM-DD-YYYY'), ''],
                ],
                '_'
              );
            }}
          />
        ) : (
          <Typography color="text.secondary">No images attached</Typography>
        )}
      </Box>
    ),
  },
  {
    key: 'pdfs',
    label: 'PDFs',
    bodyCell: (row) => (
      <Box
        display="flex"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {row.pdfs?.length ? (
          <ImageViewer
            images={row.pdfs}
            size="small"
            showImagesRow
            filesMode
            getDownloadedImageName={({ created_at }) => {
              return picture.generateName(
                [
                  'timesheet_pdf_shift_id',
                  [row.job_worker_id, '#' + row.job_worker_id, ''],
                  [created_at, moment(created_at).format('MM-DD-YYYY'), ''],
                ],
                '_'
              );
            }}
          />
        ) : (
          <Typography color="text.secondary">No files attached</Typography>
        )}
      </Box>
    ),
    width: '100%',
  },
];

const MasterTimesheetAttachments: FC<Props> = (props) => {
  const history = useHistory();
  const { values } = useMasterTimesheetContext();
  const { sortedData, order_by, order, setSort } = useSearchSortParams(values?.timesheets);

  const goToTimesheetEditPage = (id: number) => {
    history.push(`/timesheets/${id}/edit`);
  };

  return (
    <MasterTimesheetContentBox overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            {TableColumns.map((column) => (
              <TableCell key={column.key}>
                <TableSortLabel
                  key={column.key}
                  active={order_by === column.key}
                  direction={order}
                  IconComponent={SortTableIcon}
                  onClick={() => setSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: grey[50],
                },
              }}
              onClick={() => goToTimesheetEditPage(row.id)}
            >
              {TableColumns.map((column) => (
                <TableCell key={column.key} width={column.width || 'auto'}>
                  {column.bodyCell(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </MasterTimesheetContentBox>
  );
};

export default memo(MasterTimesheetAttachments);
