import React, { useCallback, useMemo, useRef } from 'react';

import { FormikProps } from 'formik';
import ReactSelect, { components } from 'react-select';

import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, styled } from '@mui/material';

import { Department } from 'types/Common/Companies';
import { ConedUser } from 'types/Common/User';

import { JobType } from 'Constants/job';
import { TestIDs, addTestID } from 'Constants/tests';
import DepartmentMaterialAsyncSearch from 'Containers/Components/Controls/DepartmentMaterialAsyncSearch';
import FileWorker from 'Utils/FileWorker';
import fileSizeFilter from 'Utils/fileSizeFilter';
import AppInputField from 'components/AppInputField/AppInputField';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';
import SwitchOnOff from 'components/SwitchOnOff/SwitchOnOff';

import SubcontractorAsyncSearch, { SubcontractorSelectItem } from '../../Components/Controls/SubcontractorAsyncSearch';
import { WORKER_TYPE } from '../Constants';
import s from './WorkerForm.module.scss';

type WorkerFormProps = FormikProps<CreateEditWorkerForm> & {
  worker?: ConedUser;
  canSeeSubcontractors: boolean;
  userIsSubcontractor: boolean;
} & {
  pageTitle?: string;
  buttonTitle?: string;
};

export interface CreateEditWorkerForm {
  email: string;
  phoneNumber: string;
  subcontractorId: number;
  subcontractor?: { id: number; name: string };
  avatar: string | File;
  firstName: string;
  lastName: string;
  workerTypes: number[] | JobType[];
  password: string;
  ces_supervisor: number;
  begin_time: string;
  end_time: string;
  address_zipcode: string;
  two_factor_auth_enabled: number;
  black_list_departments: Department[];
  black_list_department_groups: Department[];
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#f2f2f2',
  },
});

const WorkerForm: React.FC<WorkerFormProps> = ({
  canSeeSubcontractors,
  userIsSubcontractor,
  handleSubmit,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  values,
  errors,
  touched,
  pageTitle = 'Add New Worker',
  buttonTitle = 'Add Worker',
}) => {
  const inputOpenFileRef = useRef<HTMLInputElement>();

  const removeImage = () => {
    setFieldValue('avatar', undefined);
  };

  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const blob = fileSizeFilter(event.target.files)[0];
    if (!blob) {
      inputOpenFileRef.current.value = '';
      return;
    }
    setFieldValue('avatar', blob);
  };

  const onSubcontractorSelected = (subcontractor: SubcontractorSelectItem) => {
    if (!subcontractor) {
      setFieldValue('subcontractorId', '');
      setFieldValue('subcontractor', null);
      return;
    }
    setFieldValue('subcontractorId', subcontractor.value.id);
    setFieldValue('subcontractor', subcontractor.value);
  };

  const onTypeSelect = useCallback((items) => {
    if (items && items.length > 0) {
      setFieldValue(
        'workerTypes',
        items.map((item) => Number(item.value))
      );
    } else {
      setFieldValue('workerTypes', []);
    }
  }, []);

  const imageUrl = useMemo(() => {
    if (values?.avatar === 'null') return null;
    return new FileWorker(values.avatar).url;
  }, [values?.avatar]);

  const workerTypes = useMemo(() => {
    return WORKER_TYPE.filter((type) => values.workerTypes.includes(type.value));
  }, [values.workerTypes]);

  const WorkerTypesSelect = useMemo(
    () => (
      <ReactSelect
        value={workerTypes}
        isMulti
        name="colors"
        options={WORKER_TYPE}
        inputId={TestIDs.components.workerForm.fields.workerTypes}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onTypeSelect}
        isDisabled={userIsSubcontractor}
        components={{
          Input: (props) => (
            <components.Input {...props} {...addTestID(TestIDs.components.workerForm.fields.workerTypes)} />
          ),
          MenuList: (props) => (
            <components.MenuList
              innerProps={{ ...props.innerProps, ...addTestID(TestIDs.components.workerForm.options.workerTypes) }}
              {...props}
            />
          ),
        }}
      />
    ),
    [values.workerTypes, userIsSubcontractor]
  );

  const SubcontractorSelect = useMemo(
    () =>
      canSeeSubcontractors && (
        <div className="form-group col-sm-4">
          <label className="d-block">Subcontractor</label>
          <SubcontractorAsyncSearch
            isClearable
            selectedId={values?.subcontractorId}
            onSelect={onSubcontractorSelected}
            testID={TestIDs.components.workerForm.fields.subcontractor}
            menuListTestID={TestIDs.components.workerForm.options.subcontractor}
          />
          <p className="error">{errors.subcontractorId}</p>
        </div>
      ),
    [canSeeSubcontractors, values.subcontractorId, errors.subcontractorId]
  );

  return (
    <form role="presentation" className={s.container} autoComplete="off" onSubmit={handleSubmit}>
      <input type="text" name="email" style={{ display: 'none' }} />
      <input type="password" name="password" style={{ display: 'none' }} />
      <div className="container worker-create-page" style={{ minHeight: 'unset' }}>
        <div className="page-header">
          <div className="page-title">{pageTitle}</div>
        </div>
        <div className="box-item-body">
          <input
            type="file"
            id="file"
            accept="image/*"
            ref={inputOpenFileRef}
            style={{ display: 'none' }}
            onChange={onChangeFile}
            disabled={userIsSubcontractor}
            {...addTestID(TestIDs.components.workerForm.fields.avatar)}
          />
          <div className={s.avatar} {...(imageUrl && { style: { backgroundImage: `url(${imageUrl})` } })}>
            <div className={s.addAvatar} onClick={showOpenFileDlg}>
              <PhotoCameraIcon />
            </div>
            {imageUrl && <CloseIcon className={s.removeAvatar} onClick={removeImage} />}
          </div>
          <p className="error">{errors.avatar}</p>
          <div className="row mt-3">
            <div className="form-group col-sm-4">
              <label className="d-block">First Name</label>
              <input
                className={s.input}
                autoComplete="off"
                role="presentation"
                type="text"
                placeholder="First name"
                name="firstName"
                defaultValue={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={userIsSubcontractor}
                {...addTestID(TestIDs.components.workerForm.fields.firstName)}
              />
              {touched.firstName && errors.firstName && <p className="error">{errors.firstName}</p>}
            </div>
            <div className="form-group col-sm-4">
              <label className="d-block">Last Name</label>
              <input
                className={s.input}
                autoComplete="off"
                role="presentation"
                type="text"
                placeholder="Last Name"
                name="lastName"
                defaultValue={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={userIsSubcontractor}
                {...addTestID(TestIDs.components.workerForm.fields.lastName)}
              />
              {touched.lastName && errors.lastName && <p className="error">{errors.lastName}</p>}
            </div>
            <div className="form-group col-sm-4">
              <label className="d-block">Email</label>
              <input
                className={s.input}
                autoComplete="off"
                role="presentation"
                type="text"
                placeholder="Email"
                name="email"
                defaultValue={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={userIsSubcontractor}
                {...addTestID(TestIDs.components.workerForm.fields.email)}
              />
              {touched.email && errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group col-sm-4">
              <label className="d-block">Phone Number</label>
              <PhoneNumberInput
                value={values.phoneNumber}
                onChange={(value) => setFieldValue('phoneNumber', value)}
                error={errors.phoneNumber}
                numberInputProps={{
                  ...addTestID(TestIDs.components.workerForm.fields.phoneNumber),
                }}
              />
              {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
            </div>
            <div className="form-group col-sm-8">
              <label className="d-block">Blacklisted By Departments</label>
              <DepartmentMaterialAsyncSearch
                noLabel
                departments={values.black_list_departments}
                departmentGroups={values.black_list_department_groups}
                onSelect={(departments, departmentsGroup) => {
                  setFieldValue('black_list_departments', departments);
                  setFieldValue('black_list_department_groups', departmentsGroup);
                }}
                renderInput={(params) => (
                  <AppInputField
                    {...params}
                    placeholder={
                      !values.black_list_departments.length &&
                      !values.black_list_department_groups.length &&
                      'Select Department(s)'
                    }
                    size="medium"
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-4">
              <label className="d-block">Worker Types</label>
              {WorkerTypesSelect}
              {touched.workerTypes && errors.workerTypes && <p className="error">{errors.workerTypes}</p>}
            </div>
            {SubcontractorSelect}
            <div className="form-group col-sm-3">
              <label className="d-block">Password</label>
              <input
                autoComplete="new-password"
                type="password"
                className={s.input}
                name="password"
                placeholder="Password"
                defaultValue={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={userIsSubcontractor}
                {...addTestID(TestIDs.components.workerForm.fields.password)}
              />
              {touched.password && errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div className="form-group col-sm-1">
              <label className={s.two_factor_auth_label}>
                2FA
                <span className={s.two_factor_auth_switch}>
                  <SwitchOnOff
                    checked={Boolean(values.two_factor_auth_enabled)}
                    onChange={(event) => {
                      setFieldValue('two_factor_auth_enabled', Number(event.target.checked));
                    }}
                  />
                </span>
              </label>
            </div>
          </div>
          {canSeeSubcontractors && (
            <div className="row">
              <div className="form-group col-sm-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(values.ces_supervisor)}
                      onChange={(_, checked) => setFieldValue('ces_supervisor', Number(checked))}
                      name="ces_supervisor"
                      {...addTestID(TestIDs.components.workerForm.fields.isSupervisor)}
                    />
                  }
                  label="UFS Supervisor"
                  style={{ marginTop: 24 }}
                />
              </div>
              <div className="form-group col-sm-3" style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="begin_time">Begin Time</label>
                <StyledTextField
                  id="begin_time"
                  type="time"
                  name="begin_time"
                  value={values.begin_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  style={{ borderColor: '#f2f2f2' }}
                  size="small"
                  {...addTestID(TestIDs.components.workerForm.fields.beginTime)}
                />
                {touched.begin_time && errors.begin_time && <p className="error">{errors.begin_time}</p>}
              </div>
              <div className="form-group col-sm-3" style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="end_time">End Time</label>
                <StyledTextField
                  id="end_time"
                  type="time"
                  name="end_time"
                  value={values.end_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  {...addTestID(TestIDs.components.workerForm.fields.endTime)}
                />
                {touched.begin_time && errors.end_time && <p className="error">{errors.end_time}</p>}
              </div>
              <div className="form-group col-sm-3" style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="zip_code">Zipcode Address</label>
                <input
                  id="zip_code"
                  name="address_zipcode"
                  className={s.input}
                  value={values.address_zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...addTestID(TestIDs.components.workerForm.fields.zipCode)}
                />
                {Boolean(touched.ces_supervisor || values.ces_supervisor || values.address_zipcode) &&
                  errors.address_zipcode && <p className="error">{errors.address_zipcode}</p>}
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 d-flex justify-content-end page-action-bottom">
          <button
            type="submit"
            className={s.submitButton}
            disabled={isSubmitting}
            {...addTestID(TestIDs.components.workerForm.buttons.submit)}
          >
            {buttonTitle}
            {isSubmitting && (
              <CircularProgress
                size={16}
                style={{
                  color: '#fff',
                  position: 'absolute',
                  right: 24,
                }}
              />
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default WorkerForm;

export function getTime(begin_hour?: number, begin_minute?: number) {
  if (begin_hour !== undefined && begin_hour !== null && begin_minute !== undefined && begin_minute !== null) {
    return `${parseInt(`${begin_hour}`) < 10 ? '0' + begin_hour : begin_hour}:${
      parseInt(`${begin_minute}`) < 10 ? '0' + begin_minute : begin_minute
    }`;
  }
  return '';
}
