import { CESWorker } from 'types/Common/User';

import { JobType } from 'Constants/job';
import Api from 'Services/app-api';
import apiTags from 'Services/tags';
import { downloadFileToPC } from 'Utils/downloadFile';

import { ExportWorkersRequest, GetWorkersRequest, GetWorkersResponse } from './types';

const WorkersApi = Api.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getWorkers: query<GetWorkersResponse, GetWorkersRequest>({
      query: (params) => ({
        url: '/workers',
        params,
      }),
      providesTags: [apiTags.WORKERS],
    }),

    updateWorker: mutation<CESWorker, { id: string | number; data: Partial<CESWorker> }>({
      query: ({ id, data }) => ({
        url: `/workers/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [apiTags.WORKERS],
    }),

    deleteWorker: mutation<void, string | number>({
      query: (id) => ({
        url: `/workers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [apiTags.WORKERS],
    }),

    importWorkers: mutation<{ message?: string }, File>({
      query: (file) => {
        const body = new FormData();
        body.append('excel', file);

        return {
          url: '/workers/import-excel',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [apiTags.WORKERS],
    }),

    exportWorkers: mutation<Blob, ExportWorkersRequest>({
      query: (query) => ({
        url: '/workers/export',
        method: 'GET',
        params: query,
        responseHandler: (response) => {
          return response.blob();
        },
      }),

      transformResponse: (res: Blob, _, params) => {
        const filenamePrefix = 'workers_';
        const filenameBody = Object.entries(params)
          .map(([key, value]) => {
            if (!value) return;
            if (key === 'workerTypes' && typeof value === 'string') {
              const workerTypes = value.split(',');
              return `${key}=${workerTypes.map((type) => JobType[type]).join(',')}`;
            }
            return `${key}=${value}`;
          })
          .filter(Boolean)
          .join('&');

        const filename = `${filenamePrefix}${filenameBody || 'export'}`;

        downloadFileToPC(URL.createObjectURL(new Blob([res], { type: res.type })), filename);

        return res;
      },
    }),
  }),
});

export default WorkersApi;
