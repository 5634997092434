import { FC } from 'react';

import { Box, ToggleButton, ToggleButtonGroup, alpha, Tooltip } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import { SignatureIcon, Close } from 'Utils/Icon';

type Props = {
  image: ImageWithAuthor;
  onSignImage: (url: string, signed: 0 | 1) => void;
  /** Show or hide the buttons. Default is false */
  visible?: boolean;
};

const SignFileButtons: FC<Props> = ({ image, onSignImage, visible = false }) => {
  if (!visible) {
    return null;
  }

  return (
    <Box px={1}>
      <Tooltip title={image.signed ? 'Signed' : 'Unsigned'} disableInteractive>
        <ToggleButtonGroup exclusive value={image.signed} onChange={(_, value) => onSignImage(image.url, value)}>
          <ToggleButton
            value={1}
            color="success"
            sx={{
              borderRadius: '10px',
              '&.MuiToggleButton-root': {
                bgcolor: 'transparent',
                color: 'text.secondary',
                borderColor: '#E6EAEF',
                p: '5px 6px',
                '&.Mui-selected': {
                  borderColor: 'success.main',
                  color: 'success.main',
                  pointerEvents: 'none',
                },
                '&:hover': {
                  bgcolor: ({ palette }) => alpha(palette.success.light, 0.1),
                },
              },
            }}
          >
            <SignatureIcon width={16} height={16} />
          </ToggleButton>

          <ToggleButton
            value={0}
            color="error"
            sx={{
              borderRadius: '10px',
              '&.MuiToggleButton-root': {
                bgcolor: 'transparent',
                color: 'text.secondary',
                borderColor: '#E6EAEF',
                p: '5px 6px',
                '&.Mui-selected': {
                  borderColor: 'error.main',
                  color: 'error.main',
                  pointerEvents: 'none',
                },
                '&:hover': {
                  bgcolor: ({ palette }) => alpha(palette.error.light, 0.1),
                },
              },
            }}
          >
            <Close width={16} height={16} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  );
};

export default SignFileButtons;
