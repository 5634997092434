import React, { FC } from 'react';

import { Chip } from '@mui/material';

import { Poppins } from 'Utils/Fonts';

import { WorkerAsyncPaginatedSelectOption } from './WorkersPaginatedAsyncSelect';

type Props = {
  worker: WorkerAsyncPaginatedSelectOption;
  onDelete?: () => void;
};
const WorkerChip: FC<Props> = ({ worker, onDelete }) => (
  <Chip
    label={worker.label}
    sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F2F3F7',
      color: '#667085',
      borderRadius: '9999px',
      padding: '0 6px',
      lineHeight: '12px',
      fontFamily: Poppins[500],
      fontSize: '12px',
      mr: '2px',
      my: '2px',
      '& .MuiSvgIcon-root': {
        color: '#2F83FF',
        '&:hover': {
          color: '#2F83DD',
        },
        svg: {
          width: '14px',
          height: '14px',
        },
      },
    }}
    onDelete={onDelete}
  />
);

export default WorkerChip;
